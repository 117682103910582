// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "Rafraîchir",
  "dashboard.refresh.desc": "Chaque {{time}} {{timeUnit}}",
  "All rights reserved": "Tous droits réservés.",
  Dashboard: "Tableau de bord de performance",
  Planner: "Planificateur",
  "Resource Planner": "Planificateur",
  "Gestion d'entreprise": "Gestion commerciale",
  Orders: "Commandes",
  Invoices: "Factures",
  Regulations: "Règlements",
  Suppliers: "Fournisseurs",
  Rapports: "Rapports",
  Search: "Rechercher",
  "Add your Invoice": "Déposez votre Facture ",
  browse: "parcourir",
  "Show all": "Afficher tout",
  "restriction.form.to.error": "Date doit être supérieur à ",
  "restriction.form.allDays": "Tous les jours",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Jeu",
  Fri: "Ven",
  Sat: "Sam",
  Sun: "Dim",
  "priority.error": "La valeur existe déjà, veuillez choisir une autre valeur.",
  "priority.error.unhide":
    "La valeur de priorité de cette automatisation existe déjà. Votre automatisation sera affiché sans aucune priorité définie.",
  "restriction.form.estimationExecutaion.required.error":
    "Veuillez saisir un temps d'exécution",
  "restriction.form.numberInteger.error":
    "Veuillez saisir un numéro entier valide",
  "restriction.form.minValue.error":
    "La valeur doit être supérieure ou égale à 0.",
  "restriction.form.maxValue.error":
    "Le nombre des chiffres doit être inférieur ou égal à 9.",
  "restriction.form.frequency": "Fréquence",
  "restriction.form.occurence": "Occurrence",
  "restriction.form.priority.required.error": "Veuillez saisir un nombre",
  "restriction.form.priority.minValue.error":
    "Veuillez saisir une valeur supérieur ou égale à 1",
  "restriction.form.priority.maxValue.error":
    "Veuillez saisir une valeur inférieur ou égale à 100",
  Supplier: "Fournisseur",
  "Invoice No.": "N° Facture",
  "Release date": "Date de lancement",
  "Due date": "Date d'échéance",
  "Price WT": "Montant HT",
  "Taxe Rate": "TVA",
  Price: "Montant total",
  "Purchase order": "Bon de commande",
  "Last actions": "Dernières actions",
  Today: "Aujourd'hui",
  Yesterday: "Hier",
  "Last week": "Semaine dernière",
  "Update using historical data": "Mise à jour à l'aide de données historiques",
  "no execution in this period": "Aucune exécution trouvée.",
  "Estimated Execution":
    "Sur la base des critères de sélection, le temps d'exécution estimé pour cette automatisation est ",
  "Use Historical data": "Utiliser les données historiques?",
  "Choose the period":
    "Choisissez la période à prendre en compte lors du calcul du temps d'exécution estimé pour cette automatisation :",
  Status: "Statut",
  "Execution.time": "Date d'exécution",
  "planned.executions": "Exécutions planifiées",
  resource: "Ressource",
  "planner.fullScreenExit": "Sortir en mode plein écran",
  "planner.fullScreen": "Ouvrir le planner en plein écran",
  "Risk management": "Marge de risque",
  "planner.risktime": "Temps du risque",
  "Latest Exceptions": "Dernières exceptions",
  Date: "Date",
  "My tasks": "Mes tâches",
  "Invoices to verify": "Factures à vérifier",
  "Invoices to be checked": "Factures à contrôler",
  "Invoices to Payers": "Factures aux payeurs",
  "Paid invoices": "Factures payées",
  "Unpaid invoices": "Factures impayées",
  "Total amount of invoices": "Montant total des factures",
  "Total VAT amount": "Montant total de TVA",
  "Statistical overview of purchases": "Aperçu statistique des achats",
  "Statistical overview of product sales":
    "Aperçu statistique des ventes de produits",
  "Total Purchases": "Total des achats",
  "Total of expenses": "Total des dépenses",
  Late: "En retard",
  Administrator: "Administrateur",
  "administration.status": "Statut",
  "administration.company": "Entreprise",
  "administration.userId": "Identification",
  "administration.name": "Nom d'utilisateur",
  "administration.creationDate": "Date de création",
  "administration.role": "Rôle",
  "administration.email": "E-mail",
  "administration.phone": "Téléphone",
  "administration.departement": "Département",
  "administration.subscription": "Abonnement",
  "administration.activate.deactivate.user.message":
    "Voulez-vous vraiment activer/désactiver cet utilisateur ?",
  "administration.orchestrator.resource.list.not.available":
    "Il n'y a pas de ressources disponibles",
  "administration.orchestrator.process.list.not.available":
    "Il n'y a pas des processes disponibles",
  "user.add.civility": "Civilité",
  "user.add.lastName": "Nom",
  "user.add.firstName": "Prénom",
  "user.add.creationDate": "Date de création",
  "user.add.entreprise": "Entreprise",
  "user.add.subscription": "Engagement",
  "user.add.email": "Email",
  "user.add.pass1": "Mot de passe",
  "user.add.pass2": "Confirmer le mot de passe",
  "user.add.comm.lang": "Langage de communication",
  "user.add.comm.lang.helperText": "Toutes les communications et e-mails envoyés par SmartRoby seront dans la langue sélectionnée",
  "user.add.process": "Automatisation",
  "user.add.availableProcess": "Automatisation disponibles",
  "user.add.affectedProcess": "Automatisation affecté",
  "user.add.button": "AJOUTER UTILISATEUR",
  "user.add.mfa": "OTP requis",
  "user.add.fleet.admin": "Administrateur de groupe",
  "user.status.active": "Actif",
  "user.status.disable": "Inactif",
  "user.modify": "Modifier",
  "process.modify": "Modifier",
  "process.hidden": "Masqué",
  "user.add": "Ajouter",
  "user.title.add": "Ajouter un utilisateur",
  "user.title.details": "Détails de l'utilisateur",
  "user.button.save": "Ajouter utilisateur",
  "user.button.update": "Mettre à jour",
  "user.button.cancel": "Annuler",
  "user.save.confirmMsg": "Êtes-vous sûr de vouloir ajouter cet utilisateur ?",
  "user.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les détails de cet utilisateur ? ",
  "user.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
  "user.delete.discard": "Êtes-vous sûr de vouloir ignorer ces modifications?",
  "orchestrator.button.save": "Ajouter un orchestrator",
  "orchestrator.save.confirmMsg":
    "Êtes-vous sûr de vouloir ajouter cet orchestrateur ?",
  "orchestrator.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les détails de cet orchestrateur ?",
  "orchestrator.management.add.button": "Connecter un orchestrateur",
  "orchestrator.management.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer l'orchestrateur ? Toutes les données seront définitivement perdues.",
  "invalid.value": "Valeur invalide",
  Required: "Requis",
  "exception.type": "Type d'exception",
  "exception.time": "Date d'exception",
  Mr: "M",
  Mrs: "Madame",
  Ms: "Mlle",
  "tooltip.action.delete": "Supprimer",
  "Visualise Scheduler": "Visualiser le programme ",
  "tooltip.action.edit": "Modifier",
  "tooltip.action.view": "Voir",
  "tooltip.action.add": "Ajouter",
  firstName: "Prénom",
  lastName: "Nom",
  companyName: "Entreprise",
  "720 hours/year": "720 heure/an",
  "1800 hours/year": "1800 heure/an",
  "60 days/year": "60 jour/an",
  "120 days/year": "120 jour/an",
  "3 months/year": "3 mois/an",
  "5 months/year": "5 mois/an",
  "user.roles.admin": "Administrateur",
  "user.roles.user": "Utilisateur",
  "New suppliers": "Nouveaux fournisseurs",
  "sorted descending": "Trié par ordre décroissant",
  "sorted ascending": "Trié par ordre croissant",
  Invoicing: "Facturation",
  Alerts: "Notifications",
  "User Management": "Gestion des utilisateurs",
  "Audit Logs": "Journaux d'audits",
  Documentation: "Documentation",
  Services: "Services",
  "National identity card": "Carte nationale d'identité",
  "Driving license": "Permis de conduire",
  Passport: "Passeport",
  "Green card": "Attestation d’assurance",
  "Car registration papers": "Carte grise",
  Facture: "Facture",
  Invoice: "Facture",
  RIB: "RIB",
  "Order form": "Bon de commande",
  payslip: "Fiche de paie",
  "Payroll statemenet": "Fiche de paie",
  "Notice of assessment": "Avis de cotisation",
  "Tax notice": "Avis d'imposition",
  "My Services": "Mes services",
  Tutorials: "Tutoriels",
  "Statistical overview of success and exception rates":
    "Aperçu statistique des taux de réussite et d'exception",
  "Executed Processes": "Automatisation Exécutés",
  "Insurance Validation and Entry": "Saisie et validation d'assurance",
  "Resource Leveling": "Nivellement des ressources",
  "Least Executed Processes": "Automatisation les moins exécutés",
  "Most Executed Processes": "Automatisation les plus exécutés",
  "Number of executions": "Nombre d'exécutions",
  "My Active Orchestrators": "Mes Orchestrateurs Actifs",
  "Last Transcriptions": "Dernières transcriptions",
  "Total API Calls": "Total des appels d'API",
  "Remaining Balance": "Solde Restant",
  "Success rate": "Taux de réussite",
  "Items success rate": "Taux de réussite des éléments",
  "Exception rate": "Taux d'exceptions",
  "Use of APIs": "Consommation des Services",
  "My Active Services": "Mes Services Actifs",
  "Process overview": "Temps d'exécution par automatisation",
  "of the total": "du total",
  "Resource overview": "Temps d'exécution par ressource",
  "Execution time / Time saved": "Temps d'exécution / Temps économisé",
  "Latest Transcriptions": "Dernières Transcriptions",
  "Break-even": "Point d'amortissement",
  "Return on Investment": "Taux d'amortissement",

  // smart robots -> My Processes
  "Invoice Management": "Gestion des factures",
  "Client Onboarding": "Intégration du client",
  "Bank Reconciliation": "Rapprochement bancaire",
  "Expense Report Management": "Gestion des notes de frais",
  "Salary Statement Monitoring": "Suivi des relevés de salaire",
  "Virtual Assistant for agents": "Assistant virtuel pour les agents",
  "Trigger Details": "Details du déclencheur",
  "Start Process": "Démarrer",
  "Stop Process": "Arrêter l' automatisation",
  Schedule: "Programmer",
  Prioritize: "prioriser",
  Sheet: "Feuille",
  Parameters: "paramètres",
  "no entity currently": "Aucune entité n'existe.",
  Validate: "Valider",
  validate: "Valider",
  "The document was updated successfully":
    "Le document a été mis à jour avec succès",
  Upload: "Télécharger",
  upload: "Télécharger",

  // smart ocr cards

  "Sage Interface": "Interface Sage",
  "SAP Interface": "Interface SAP",
  "Central Banking Data Integration":
    "Intégration des données de la banque centrale",
  "Salesforce Interaction": "Interaction Salesforce",

  "Drop process": "Poser ici pour démarrer l' automatisation",
  "under construction":
    "Cette section est en construction, notre équipe y travaille. Restez connecté...",
  // smart ocr services
  "Analyze a new file": "Analyser un nouveau fichier",
  Receipt: "Reçu",
  "EDF proof": "Justificatif EDF",
  "Click here or drop a file to download":
    "Cliquez ici ou déposez un fichier à télécharger",
  "Loaded File": "Fichier chargé",
  "File preview": "Aperçu du fichier",
  Informations: "Informations",
  "Document name": "Nom du document",
  "File content type": "Type de contenu de fichier",
  userId: "Identification",
  "First name": "Prénom",
  "Last name": "Nom",
  CIN: "CNI",
  "Date of Birth": "Date de naissance",
  "Place of birth": "Lieu de naissance",
  "Expiration date": "Date d'expiration",
  Address: "Adresse",
  Gender: "Sexe",
  CivilStatusNo: "Situation",
  Overview: "Aperçu",
  "restriction.form.resources": "Ressources",
  "restriction.form.priority": "Priorité",
  "restriction.form.priority.label": "Priorité de votre automatisation",
  "restriction.form.executionTimeLine": "Calendrier d’exécution",
  "restriction.form.executionDay": "Jour d'éxecution",
  "restriction.form.executionTime": "Temps d'éxecution",
  "restriction.form.from": "De",
  "restriction.form.to": "A",
  "restriction.form.estimatedExecutionDuration": "Durée d'exécution estimée",
  "restriction.form.allowedSession":
    "Nombre d'exécutions parallèles autorisées",
  "restriction.form.estimatedTime": "Temps estimé",
  "restriction.userGuide.resources":
    "Indique sur quelles machines (Ressources) cette automatisation peut être exécuté. Pour ajouter des ressources éligibles, veuillez contacter votre équipe RPA",
  "restriction.userGuide.priority":
    "Lorsque tous les SLA sont identiques, l' automatisation avec la priorité la plus élevée (le numéro le plus bas) sera exécuté en premier.",
  "sla.update.success": "Informations SLA mises à jour avec succès",
  "restriction.userGuide.executionTimeLine":
    "Choisissez les jours et les heures où cette automatisation est autorisé à s'exécuter",
  "restriction.userGuide.estimatedExecutionTime":
    "Le temps moyen nécessaire pour terminer cette automatisation",
  "restriction.userGuide.allowedSession":
    "Le nombre d'exécutions parallèles autorisées pour cette automatisation, si suffisamment de licences et de machines sont disponibles pour les exécuter",
  "restriction.userguide.frequencyOccurence":
    "La fréquence d'exécution minimale que l' automatisation doit exécuter",
"sla.update.error": "Échec de la mise à jour des SLAs.",
  Historical: "Historique",
  "General Information": "Informations Générales",
  "Schedule Information": "Informations sur le programme",
  Description: "Description",
  processOriginalName: "Nom original du automatisation",
  Priority: "Priorité",
  "Start date": "Date de début",
  "End date": "Date de fin",
  "End Date": "Date de fin",
  Cron: "Cron",
  "Unit cost": "Coût à l'unité",
  "Number of units": "Nombre d'unités",
  "Process cost": "Coût du automatisation",
  "add.schedule": " Créer un programme",
  "add.process": "Ajouter une automatisation",
  Reset: "Réinitialiser",
  Day: "Jour",
  Week: "Semaine",
  "3 DAYS": "3 jours",
  Delete: "Supprimer",
  Update: "Mettre à jour",
  Save: "Sauvegarder",
  Cancel: "Annuler",
  Confirm: "Confirmer",
  Add: "Ajouter",
  "Are you sure delete this row ?":
    "Êtes-vous sûr de vouloir supprimer cette ligne ?",
  "This process was started successfully":
    "Cette automatisation a été démarré avec succès",
  "The process has been added to the SR Queues.": "L'exécution demandée a été ajoutée à la file SR.",
  "An execution trigerred manually on the same resource has already been requested for this process": "Une exécution déclenchée manuellement sur le même ressource a déjà été crée pour cette automatisation",
  "The process start failed! Please try again later":
    "Le démarrage d'automatisation a échoué ! Veuillez réessayer plus tard",
  ERRORS_EMPTY_FIELD: "Champ requis",
  ERRORS_LESS_THAN_MIN_FIELD: "Merci d’entrer une valeur supérieure à {{ limit }}",
  ERRORS_INVALID_DATA: "Merci d’entrer des données valides.",
  ERRORS_NO_DATA: "Aucune donnée trouvée!",
  WEIGHTS_SUM_MUST_BE_100: "La somme des poids doit être de 100%",
  "Start date must be less than the end date":
    "La date de début doit être antérieure à la date de fin",
  "End date must be greater than the start date":
    "La date  de fin doit être postérieure à la date de début",
  "Start time": "Heure de début",
  "End time": "Heure de fin",
  "How much time does it take an employee to execute this process?":
    "Combien de temps faut-il à un employé pour exécuter cette automatisation ?",
  "How much time does it take an employee to execute an execution in this process? (in minutes)":
    "Combien de temps (en minutes) faut-il à un employé pour compléter une execution de cette automatisation ?",
  "How much does the hour cost for an employee executing this task?":
    "Combien coûte l'heure pour un employé exécutant cette tâche ?",
  "How much does this process cost per month ?":
    "Combien coûte cette automatisation par mois ?",
  Recurrence: "Récurrence",
  Previous: "Précédent",
  // authentication page
  "Enter your mail": "Renseignez votre e-mail",
  "Confirm your mail": "Confirmez votre e-mail",
  "Enter your password": "Entrez votre mot de passe",
  Login: "Se connecter",
  "Sign up to SmartRoby": "Inscription à SmartRoby",
  Register: "S'inscrire",
  "You are already signed up": "Vous êtes déjà inscrit",
  "Go to SmartRoby": "Aller à SmartRoby",
  "Registration was successfull, you will be redirected":
    "Inscription réussie, vous allez être redirigé",
  "Registration failed": "Inscription échouée",
  Close: "Fermer",
  "Aws registration token missing": "Jeton d'enregistrement Aws manquant",
  "Email not matching": "L'email ne correspond pas",
  "Forgot your password ?": "Mot de passe oublié ?",
  "Login or password incorrect !": "Email ou mot de passe incorrect !",
  "Copyright © Smart-Roby  All rights reserved.":
    "Copyright © Smart-Roby  Tous droits réservés.",
  English: "Anglais",
  French: "Français",
  Spanish: "Espagnol",
  Polish: "Polonais",
  Germany: "Allemand",
  Arabic: "Arabe",
  "Process executions": "Exécutions",
  Ready: "Prêt",
  "Executed by": "Exécuté par",
  "you want to modify this data ?":
    "Êtes-vous sûr de vouloir modifier ces données ?",
  Yes: "Oui",
  No: "Non",
  "Data saved with success": "Données sauvegardées avec succès",
  "Data not saved": "Données non enregistrées ",
  "Hour(s)": "Heure(s)",
  "Day(s)": "Jour(s)",
  "Week(s)": "Semaine(s)",
  "Month(s)": "Mois",
  "Year(s)": "Année(s)",
  Year: "Année",

  Every: "Chaque",
  "Choose period": "Choisir une période",
  "Your input is required": "Votre intervention est requise",
  "The process": "La automatisation",
  "is running, try again when it's completed.":
    "est en cours d'exécution, réessayez une fois l'opération terminée.",
  "Are you sure you want to execute this process ?":
    "Êtes-vous sûr de vouloir exécuter cette automatisation ?",
  Domain: "Domaine",
  "Process name": "Nom d'automatisation",
  "Process reference": "Référence d'automatisation",
  "Number used of systems and applications":
    "Nombre de systèmes et d'applications utilisés",
  "SAP environments (if applicable)": "Environnements SAP (si appliqué)",
  Frequency: "Fréquence",
  Volume: "Volume",
  "Rule-based": "Basé sur des règles",
  Standardization: "Standardisation",
  "Structured data": "Données structurées",
  "IT environment": "Environnement IT",
  "Test enabled": "Testable",
  "Documentation Readiness": "Préparation de la documentation",
  "Authentication mode": "Mode d'authentification",
  "Process UoM": "Unité de mesure d'automatisation",
  "Time spent for one execution": "Temps passé pour une exécution",
  "Frequency of occurence": "Fréquence d'occurrence",
  "Time Spent": "Temps Passé",
  "Time granularity": "Unité de temps d’exécution",
  "Occurence time granularity": "Unité de temps d'occurrence",
  Occurrence: "Occurrence",
  "Time period": "Période",
  "Occurrence driver": "Conducteur d'occurrence",
  "Total time spent in hours per year": "Temps (en heures) redonné à l'entreprise par an",
  "Re-usability": "Réutilisabilité",
  "Expected qualitative benefits": "Bénéfices qualitatifs attendus",
  "Process Stability": "Stabilité d'automatisation",
  "Number of steps": "Nombre d'étapes",
  "Number of windows": "Nombre de fenêtres",
  "Business Rules": "Règles métier",
  "Business Applications": "Applications métier",
  "Complex functions - Unstructured data / OCR":
    "Fonctions complexes - Données non structurées / OCR",
  "Complex functions - DataBase": "Fonctions complexes - DataBase",
  "Complex functions - Powershell": "Fonctions complexes - Powershell",
  "Complex functions - Active Directory":
    "Fonctions complexes - Active Directory",
  "Complex functions - VB Script": "Fonctions complexes - VB Script",
  "Complex functions - Web Services": "Fonctions complexes - Web Services",
  "Type of applications - Web application":
    "Type d'applications - Application web",
  "Type of applications - Windows application":
    "Type d'applications - Application Windows",
  "Type of applications - Mainframe application":
    "Type d'applications -Application Mainframe",
  "Type of applications - Citrix application":
    "Type d'applications - application Citrix ",
  "Automation without RPA": "Automatisation sans RPA",

  Accounting: "Comptabilité",
  Controlling: "Contrôle de gestion",
  Other: "Autre",
  Others: "Autres",
  "assessment.Yes - Based on objective criteria":
    "Oui - Basé sur des critères objectifs",
  "assessment.No - Judgment based": "Non - Basé sur le jugement",
  "assessment.Completely optimized process, managed at Group level":
    "Automatisation complètement optimisé, géré au niveau du groupe",
  "assessment.Largely standardized process, mostly managed at group level":
    "Automatisation largement standardisé, géré pour l'essentiel au niveau du groupe",
  "assessment.Good level of standardisation, managed regionaly":
    "Bon niveau de normalisation, géré au niveau régional",
  "assessment.Low degree of standardization and split responsibilities":
    "Faible degré de normalisation et partage des responsabilités",
  "assessment.Disaggregated process and split responsibilities":
    "Automatisation désagrégé et partage des responsabilités",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "Oui - Entièrement structuré (par exemple sous forme de formulaire/tableau/feuille de calcul)",
  "assessment.No - Completely un-structured e.g. non standardized email":
    "Non - Complètement non structuré, par exemple courrier électronique non standardisé",
  "assessment.Few simple applications (Excel, Outlook, …)":
    "Peu d'applications simples (Excel, Outlook, ...)",
  "assessment.Few simple applications with a unique SAP instance or another / custom application":
    "Peu d'applications simples avec une instance SAP unique ou une autre / application personnalisée",
  "assessment.Few complex / custom applications or ERP instances":
    "Peu d'applications complexes / personnalisées ou d'instances ERP",
  "assessment.Several complex / custom applications, and a cloud application":
    "Plusieurs applications complexes / personnalisées, et une application en Cloud",
  "assessment.Large number of applications, (i.e. several ERP instances)":
    "Nombre important d'applications, (c'est-à-dire plusieurs instances ERP)",
  "assessment.Yes - Data-sets fully available to conduct multiple tests":
    "Oui - Séries de données entièrement disponibles pour effectuer des tests multiples",
  "assessment.No - No Data-sets available to conduct multiple tests":
    "Non - Pas de séries de données  disponibles pour effectuer des tests multiples",
  "assessment.A detailed documentation is available":
    "Une documentation détaillée est disponible",
  "assessment.A high level documentation is available":
    "Une documentation de haut niveau est disponible",
  "assessment.No documentation": "Pas de documentation",
  "assessment.Standard authentication mode (ID and password)":
    "Mode d'authentification standard (ID et mot de passe)",
  "assessment.SSO only authentication mode":
    "Mode d'authentification SSO uniquement",
  "assessment.Additional authentication required (e.g. USB key)":
    "Authentification supplémentaire requise (par exemple, clé USB)",
  "assessment.Basic documentation is available":
    "Une documentation basique est disponible",
  "assessment.Few simple applications with a unique ERP instance custom application":
    "Peu d'applications simples avec une application personnalisée d'instance d'ERP unique",
  Seconds: "Secondes",
  Minutes: "Minutes",
  Minute: "Minute",
  Hours: "Heures",
  Days: "Jours",
  "unit.manday": "j/h",
  "assessment.Fully Core process compliant":
    "Entièrement conforme au automatisation de base",
  "assessment.Partially Core process compliant":
    "Partiellement conforme au automatisation de base",
  "assessment.Local (zone or country specific)":
    "Local (zone ou pays spécifique)",
  "assessment.Major": "Majeur",
  "assessment.High": "Haut",
  "assessment.Medium": "Moyen",
  "assessment.Low": "Faible",
  "assessment.Minor": "Mineur",
  "assessment.No change is planned": "Aucun changement n'est prévu",
  "assessment.No change is planned in the next 2 years":
    "Aucun changement n'est prévu dans les 2 prochaines années",
  "assessment.Process/system may change in next 2 years":
    "La automatisation/système pourrait changer dans les 2 prochaines années",
  "assessment.Process/system update planned in next 2 years":
    "Mise à jour d'automatisation/système prévue dans les deux prochaines années",
  "assessment.Frequent changes occur in the process":
    "Des changements fréquents interviennent au cours d'automatisation",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "Plus de 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "Plus de 20",
  "assessment.Between 2 and 3": "Entre 2 et 3",
  "assessment.Between 4 and 10": "Entre 4 et 10",
  "assessment.More than 10": "Plus de 10",
  "assessment.Between 4 and 5": "Entre 4 et 5",
  "assessment.More than 5": "Plus de 5",
  "assessment.1": "1",
  true: "vrai",
  false: "faux",

  "Time saved": "Temps économisé",
  "Total time saved": "Temps total économisé",
  "Process ": "Automatisation",
  Executing: "En cours d'exécution",
  Planned: "Planifié",
  Paused: "En pause",
  "Rows Per page": "Lignes par page",
  "pagination.of": "sur",
  Completed: "Terminé",
  "How long will it take an employee to complete this process?":
    "Combien de temps faut-il à un employé pour exécuter cette automatisation?",

  "How much does it cost per hour for an employee to perform this task?":
    "Combien coûte l'heure pour un employé pour exécuter cette tâche ?",

  unknown: "inconnu",
  Unknown: "Inconnu",
  "This Week": "7 derniers jours",
  "This Month": "30 derniers jours",
  "This Year": "365 derniers jours",
  "All time": "Toute la période",
  "FTE Time to Process": "Temps de traitement ETP",
  "FTE Cost": "Coût ETP",
  Discard: "Abandonner",
  "Exception Reason": "Raison de l'exception",
  "Exception Workflow Status": "Statut",
  "Exception Assignee": "Affecter à",
  More: "Plus",
  Less: "Moins",
  "Hide filters": "Masquer les filtres",
  READY: "Non traitée",
  TAKEN: "Assigné",
  // process asseessment
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems": "Considérez les exceptions métier comme des éléments en succès dans les KPI de surveillance",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip": "Toutes les exceptions commerciales seront considérées comme réussies",
  createdBy: "Crée par",
  updatedBy: "Dernière modification par",
  "Last Update ": "Dernière actualisation",
  Impact: "Impact",
  "header.kpi.exceptions": "Exceptions à traiter",
  "Schedule Name": "Nom du programme",
  Period: "Période",
  Exceptions: "Exceptions",
  Actions: "Actions",
  Reason: "Raison",
  "Exception Time": "Temps d'exception",
  Process: "Automatisation",
  "users deleted successfully": "Utilisateur(s) supprimé(s) avec succès.",
  "fleet deleted successfully": "Groupe supprimé avec succès.",
  "orchestrator deleted successfully": "Orchestrateur supprimé avec succès.",
  "queue deleted successfully": "File SR supprimée avec succès",
  "resource.hidden.success": "Ressource {{status}} avec succès",
  hidden: "caché",
  unhidden: "non caché",
  "user has exception item":
    "Ce compte d'utilisateur est associé à des exceptions.",
  "Unexpected Error": "Erreur Inattendue.",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
    "Combien de temps (en minutes) faut-il à un employé pour exécuter un élément de cette automatisation ?",
  "How much does this Process cost to maintain per Year?":
    "Combien coûte cette automatisation en maintenance par an?",
  "Add tag": "Ajouter un tag",
  "Edit tag": "Modifier un tag",
  "Delete tag": "Supprimer un tag",
  "Tags Module": "Tags",
  Type: "Type",
  "Delete Item": "Supprimer l'élément",
  "Are you sure you want to delete this cost ?": "Êtes-vous sûr de vouloir supprimer ce coût ?",
  "This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing paramteres. If you are sure you want to delete this cost from all processes, proceed with the delete.":
  "Ce coût est partagé avec plusieurs automatisation. Si vous souhaitez supprimer le coût attribué à cette automatisation individuellement, veuillez modifier le coût et supprimer les automatisations des paramètres de partage. Si vous êtes sûr de vouloir supprimer ce coût de tous les automatisations, procédez à la suppression",
  "Cost Configuration": "Configuration des coûts",
  "Cost Name": "Nom du coût",
  "Cost Category": "Catégorie de coût",
  Category: "Catégorie",
  recurring: "Récurrent",
  "one-shot": "Un seul coup",
  exclusive: "Exclusif",
  "Cost Type": "Type de coût",
  Weight: "Poids",
  "Add cost": "Ajouter un coût",
  "Cost Allocation": "Répartition des coûts",
  "Delete cost": "Supprimer le coût",
  "Edit cost": "Modifier le coût",
  "Allocation Amount": "Montant de l'allocation",
  shared: "Partagé",
  "FTE hourly cost": "Coût horaire de l'ETP",
  "Save ": "Sauvegarder",
  "Cancel ": "Annuler",
  "Time Saved per Item": "Temps économisé par élément",
  "Time saved for each completed item of this process (in minutes)":
  "Temps économisé pour chaque élément achevé de cette automatisation (en minutes)",
  "Time Saved per Execution": "Temps économisé par exécution",
  " Time saved for each execution of this process excluding the time saved from every item (in minutes)":
  " Temps économisé pour chaque exécution de cette automatisation, à l'exclusion du temps économisé pour chaque élément (en minutes)",
  "Cost Shared": "Coûts partagés",
  "Cost Amount": "Coût Montant",
  "Are you sure you want to update the parameters ?":
    "Voulez-vous vraiment modifier ces paramètres ?",
  "Are you sure you want to create this schedule ?":
    "Êtes-vous sûr de vouloir créer ce programme?",
  "Are you sure you want to update this schedule ?":
    "Êtes-vous sûr de vouloir mettre a jour ce programme?",
  "The schedule was created successfully":
      "Votre demande a été envoyée à BP et le programme sera bientôt créé",
  "Failed to create the schedule! Please try again later":
    "Échec de la création du programme ! Veuillez réessayer plus tard",
  "Failed to update parameters": "Échec de la mise à jour des paramètres",
  "Are you sure you want to disable this schedule ?":
       "Êtes-vous sûr de vouloir désactiver ce programme ?",
  "Are you sure you want to enable this schedule ?":
       "Êtes-vous sûr de vouloir activer ce programme ?",
  "button.deactivate.confirm":
       "Désactiver",
    "button.activate.confirm":
       "Activer",
  hours: "heures",
  Once: "Une fois",
  Hourly: "Par Heure",
  Daily: "Quotidien",
  Weekly: "Hebdomadaire",
  Monthly: "Mensuel",
  Yearly: "Annuel",
  Trimestrial: "Trimestriel",
  Quarterly: "Quadrimestriel",
  Name: "Nom",
  "Start Date": "Date de début",
  "Are you sure you want to save the changes ?":
    "Voulez-vous vraiment enregistrer les modifications ?",
  "Failed to modify schedule": "Échec de la modification du programme",
  "Are you sure you want to disregard the changes ?":
    "Voulez-vous vraiment ignorer les modifications ?",
  "Changes disregarded": "Modifications ignorées",
  "Are you sure you want to delete this exception ?":
    "Voulez-vous vraiment masquer cette exception ?",
  "Are you sure you want to show this exception ?":
    "Voulez-vous vraiment afficher cette exception ?",
  "Are you sure you want to delete these exception ?":
    "Voulez-vous vraiment supprimer ces exceptions ?",
  "Exception Deleted Successfully": "Exception masquée avec succès!",
  "Exception Restored Successfully": "Exception restaurée avec succès!",
  "Exceptions Deleted Successfully": "Exceptions masquées avec succès",
  "Are you sure you want to delete": "Êtes-vous sûr de vouloir masquer",
  "Are you sure you want to show": "Êtes-vous sûr de vouloir afficher",
  "Are you sure you want to show X exceptions?":
    "Êtes-vous sûr de vouloir afficher {{count}} exception(s) ?",
  exceptions: "exception(s)",
  "exceptions successfully deleted": "exceptions masqués avec succès",
  "X exceptions successfully restored":
    "{{count}} exception(s) restaurées avec succès",

  // rpa qual
  Accepted: "Acceptée",
  accepted: "Acceptée",
  Rejected: "Rejetée",
  rejected: "Rejetée",
  Submitted: "Envoyée",
  submitted: "envoyée",
  Pending: "En attente",
  pending: "en attente",
  PENDING: "En attente",
  DELETED: "Supprimée",
  MISSED: "Manquée",
  MISSED_P: "En attente (Manquée)",
  PENDING_SR: "En attente SR",
  Draft: "Brouillon",
  "Not submitted": "Non envoyée",
  "Are you sure?": "Êtes-vous sûr ?",
  "You won't be able to revert this!":
    "Vous ne pourrez plus revenir en arrière !",
  warning: "Avertissement",
  "Yes, submit it!": "Oui, envoyer !",
  "Yes, save it!": "Oui, sauvegarder !",
  "Submit later": "Valider plus tard",
  Back: "Retour",
  Result: "Résultat",
  result: "Résultat",
  "Impact/Feasibility Matrix": "Matrice de Faisabilité/Impact",
  Feasibility: "Faisabilité",
  next: "Suivant",
  "Red zone": "Zone rouge",
  "Impact and feasibility are limited": "La automatisation est complexe à automatiser pour un impact minime",
  "The process is likely to be reprioritized":
    "Il est recommandé de déprioriser cette automatisation sauf si d'autres critères sont pris en compte lors de l'arbitrage",
  "Yellow zone": "Zone jaune",
  "Here either the impact or the feasibility is relevant":
    "L'impact de l'automatisation ou sa faisabilité montre un fort potentiel",
  "Depending on the case and arbitration, the process could be deprioritized":
    "La priorisation de cette automatisation est à arbitrer avec d'autres critères",
  "Green zone": "Zone verte",
  "Both impact and feasibility show some great signs":
    "L'impact de l'automatisation et sa faisabilité montrent un fort potentiel",
  "The process is likely to be prioritized":
    "Il est recommandé de prioriser cette automatisation",
  Roi: "Taux d'amortissement",
  "Saving (per year)": "Estimation des gains (par an)",
  "FTE Gains": "Gains ETP",
  "FTE Costs": "Coûts ETP",
  "Total in $": "Total en $",
  "Developement costs": "Estimation des couts de développement (en j/h)",
  Developer: "Développeur",
  "Business analyst": "Business analyst",
  "Running costs (per year)": "Coûts de fonctionnement (par an)",
  "Running costs in $": "Coûts de fonctionnement en $",
  "Payback period (year)": "Période de remboursement (année)",
  "Yes, accept it": "Oui, accepter",
  "Yes, reject it": "Oui, rejeter",
  "Yes, delete it": "Oui, supprimer",
  NAME: "NOM",
  "CREATED BY": "CRÉÉ PAR",
  "Date created": "Date de création",
  "Last update": "Date de la dernière modification",
  Edit: "Modifier",
  Exit: "Quitter",
  "Home - RPA Qualification Tool By Novelis innovation":
    "Accueil - Outil de qualification RPA par Novelis innovation",
  "No opportunities found !": "Aucune opportunité trouvée!",
  "create.opportunity": "Créer une opportunité",
  "No cases found !": "Aucun cas trouvé !",

  "Tool for discovering, evaluating and classifying business processes":
    "Outil de découverte, d'évaluation et de classification des automatisations métiers",
  "Sign in": "S'identifier",
  "Invalid email address": "Adresse électronique invalide",
  "Email Address": "Adresse électronique",
  "Password is required": "Mot de passe requis",
  "Password must contains at least 8 characters":
    "Le mot de passe doit contenir au moins 8 caractères",
  Password: "Mot de passe ",
  "No network": "Pas de réseau",
  "Please verify your internet connection and try again !":
    "Veuillez vérifier votre connexion Internet et réessayer !",
  OK: "OK",
  "New process": "Nouveau Automatisation",
  "Edit process": "Modifier l' automatisation",
  "Page not found": "Page non trouvée",
  "Sorry, the page you are looking for cannot be found !":
    "Désolé, la page que vous recherchez est introuvable !",
  Reject: "Rejeter",
  Submit: "Envoyer",
  "rpaQual.action.submit": "Valider",
  submit: "envoyer",
  "rpaqual.submit": "Valider",
  Accept: "Accepter",
  "assessment.submit": "Soumettre",
  "Select a Department": "Sélectionnez une catégorie",
  "Select a resource": "Sélectionner un ressource",
  "name your process": "Nommer votre automatisation",
  "describe briefly your process": "Décrivez brièvement votre automatisation ",

  "What is the value that would drive a round of process executions":
    "Quelle est la valeur qui motiverait une série d'exécutions",

  "Select if process is rule-based":
    "Choisissez si l' automatisation est basé sur des règles",

  "Select if process is standardized":
    "Sélectionnez si l' automatisation est standardisé",

  "Select if data used in the process is structured ":
    "Sélectionnez si les données sont structurées",

  "Select if testing will be possible": "Choisissez si le test sera possible ",

  "the documentation level that fits the most with the context of your process":
    "le niveau de documentation qui correspond le mieux au contexte de votre automatisation",

  "what authentication mode is applied for the systems in scope":
    "quel mode d'authentification est appliqué pour les systèmes concernés",

  "Time required for a single but complete process execution":
    "Temps nécessaire à l'exécution d'une automatisation unique mais complet",

  "Time granularity of the above mentionned time":
    "Granularité temporelle du temps mentionné ci-dessus",

  "eg: Weekly=52, Monthly=12, Daily=220 ":
    "ex : Hebdomadaire=52, Mensuel=12, Quotidien=220 ",

  "This field is populated automatically from the details time spent below":
    "Ce champ est rempli automatiquement à partir des détails du temps passé ci-dessous",

  "Assess re-usability to the best of your knowledge":
    "Évaluer la possibilité de réutilisation au mieux de vos connaissances",

  "Explain what are the benefits expected, e.g. better quality":
    "Expliquer quels sont les avantages attendus, par exemple une meilleure qualité",

  "Select the sentence that fits the most with the context of your process":
    "Sélectionnez la phrase qui correspond le mieux au contexte de votre automatisation",

  "Select the number of steps": "Sélectionnez le nombre d'étapes",

  "number of screens that change during the execution ":
    " nombre d'écrans qui changent pendant l'exécution",

  "number of  the business rules governing the process":
    "nombre de règles commerciales régissant l' automatisation",

  "number of business applications involved in the process":
    "nombre de demandes d'entreprises impliquées dans l' automatisation",

  "whether unstructured data or any OCR processing will be processed":
    "si des données non structurées ou tout traitement OCR seront traités",

  "communication with a database to establish or any sql commands will be executed":
    "la communication avec une base de données à établir ou toute commande sql sera exécutée",

  "if any Powershell script to install or any powershell code to execute in the command line":
    "si un script Powershell à installer ou un code PowerShell à exécuter dans la ligne de commande",

  "if the resources will have a Windows account and communicate by email or Teams":
    "si les ressources disposeront d'un compte Windows et communiqueront par e-mail ou via Teams",

  "if there is a need to create VB scripts or run macros directly on blue prism ":
    "s'il est nécessaire de créer des scripts VB ou d'exécuter des macros directement sur Blue Prism ",

  "if there is a possibility to use web services or to expose them for other processes":
    "s'il existe une possibilité d'utiliser les services web ou de les exposer pour d'autres automatisation",

  "if the process interacts with web applications via browsers":
    "si l' automatisation interagit avec des applications web via des navigateurs",

  "if the process interacts with Windows applications installed on the computer":
    "si l' automatisation interagit avec les applications Windows installées sur l'ordinateur",

  "if  there is any application that we access via an emulator ":
    "s'il existe une application à laquelle on accède via un émulateur ",

  "if  there is any application that we access via the cloud":
    "s'il existe une application à laquelle nous accédons via le cloud",

  "the number of steps": " Nombre d'étapes ",
  Comment: "Commentaire",

  // scheduler
  "Are you sure you want to disregard this schedule ?":
    "Voulez-vous vraiment ignorer ce programme ?",
  "Schedule disregarded!": "Programme annulé !",
  "Are you sure you want to execute this process?":
    "Êtes-vous sûr de vouloir exécuter cette automatisation ?",
  "Feasibility/Impact": "Faisabilité/Impact",

  // kpi
  January: "Janvier",
  February: "Février",
  March: "Mars",
  April: "Avril",
  May: "Mai",
  June: "Juin",
  July: "Juillet",
  August: "Août",
  September: "Septembre",
  October: "Octobre",
  November: "Novembre",
  December: "Decembre",

  "Weeks ago": "Semaines passées",
  "hours ago": "il y a {{hours}} heures",
  "1 hour ago": "il y a une heure",
  xHoursAgo: "il y a {{hours}} heure(s)",

  "7 Weeks ago": "7 semaines passées",
  "6 Weeks ago": "6 semaines passées",
  "5 Weeks ago": "5 semaines passées",
  "4 Weeks ago": "4 semaines passées",
  "3 Weeks ago": "3 semaines passées",
  "2 Weeks ago": "2 semaines passées",
  "Last Week": "La semaine dernière",

  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  Sunday: "Dimanche",

  "My Cases": "Mes Dossiers",
  "Process Exceptions": "Exceptions des automatisations",
  "Item Exceptions": "Eléments en exception",
  "My Processes": "Automatisations",
  "Choose frequency": "Choisissez la fréquence",
  "Default Alerts": "Notifications par défaut",
  "View default alerts": "Affichage des notifications par défaut",
  "Edit default alerts": "Modification des notifications par défaut",
  "Delete default alert": "Suppression des notifications par défaut",
  "Add default alert": "Ajouter une notification par défaut ",
  "Resource Administration": "Administration des ressources",
  "Manage resource": "Gérer le ressource",
  "Custom Alerts": "Notifications personnalisées ",
  "View custom alerts": "Affichage les notifications personnalisées",
  "Edit custom alerts": "Modification des notifications personnalisées",
  "Delete custom alert": "Suppression des notifications personnalisées",
  "Add custom alert": "Ajouter une notification personnalisée",

  Analytics: "Rapports",
  Administration: "Administration",
  "Alerts Management": "Notifications",
  "Process Assessment": "Évaluation des opportunités",
  "title.processassessment": "QUALIFICATION DES PROCESSUS",
  "Delete Schedule": "Supprimer le programme",
  Schedules: "Plannings",
  "No options": "Aucune option",
  DONE: "Traitée",
  UNKNOWN: "Inconnu",
  Loading: "Chargement",
  RUNNING: "En cours",
  Running: "En cours",
  Disregard: "Ignorer",
  EXCEPTION: "Exception",
  "Retry Exceptions": "Réessayer les exceptions",
  "Exception Type": "Type d'exception",
  "Show less": "Afficher moins",
  "Filter List": "Filtrer la liste",
  "Process Mining": "Exploration d'automatisation",
  Definition: "Définition",
  Profile: "Profil",
  "Choose your timezone": "Choisir votre fuseau horaire",
  "IT Review": "Complexité",

  "Total executions": "Total des exécutions",
  "Number of process executions": "Nombre d'exécutions",
  "Latest activities": "Dernières activités",

  "Creation date (Descending)": "Date de création (décroissant)",
  "Creation date (Ascending)": "Date de création (croissant)",
  "Exception Time (Descending)": "Date de l'exception (décroissant)",
  "Exception Time (Ascending)": "Date de l'exception  (croissant)",
  "End date (Descending)": "Date de fin (décroissant)",
  "End date (Ascending)": "Date de fin (croissant)",
  "Deferral date (Descending)": "Date de report (décroissante)",
  "Deferral date (Ascending)": "Date de report (croissante)",
  "Deferral date": "Date de report",
  "Creation date": "Date de création",
  "Exception date": "Date de l'exception",
  "Sort By": "Trier par",
  "Document Name": "Nom du document",
  "Loaded Time": "Temps Chargé",
  "Document Status": "Statut du document",
  Preview: "Aperçu",
  "The document was sent to the orchestrator":
    "Le document a été envoyé à l'orchestrateur",
  "The process has started successfully": "La automatisation a démarré avec succès",
  "Are you sure you want to delete this document ?":
    "Êtes-vous sûr de vouloir supprimer ce document ?",
  "Document Deleted Successfully": "Document supprimé avec succès",
  "Show empty fields": "Afficher les champs vides",
  "Are you sure you want to delete this Opportunity ?":
    "Voulez-vous vraiment supprimer cette opportunité ?",
  "Opportunity deleted successfully": "Opportunité supprimée avec succès",

  // Added By Zineb

  START: "DÉMARRER",
  start: "Démarrer",
  Start: "Démarrer",
  Of: "de",
  FOREVER: "TOUJOURS",
  "Are you sure you want to delete this schedule ?":
    "Êtes-vous sûr de vouloir supprimer ce programme ? ",
  "Are you sure you want to delete this frequency ?": "Êtes-vous sûr de vouloir supprimer cette fréquence ?",
  "Schedule Deleted Successfully": " Programme supprimé avec succès",
  "Failed to delete schedule": "Échec de la suppression du programme",
  "save as draft": "sauvegarder comme brouillon",
  "Save as draft": "Sauvegarder comme brouillon",
  "Save draf": "Sauvegarder le brouillon",
  calculate: "calculer",
  Calculate: "Calculer",
  "Document Details": "Détails du document",
  "document details": "détails du document",
  "document overview": "aperçu du document",
  "Document Overview": "Aperçu du document",

  // Process Assessment Help By Zineb

  "Can a defined rule be applied to each step of this process?":
    "Une règle définie peut-elle être appliquée à chaque étape de cette automatisation?",
  "To what extent is the process standard across locations? The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "Dans quelle mesure l' automatisation est-il standard dans tous les sites ? Plus l' automatisation est optimisé et centralisé, plus les tests et la mise en œuvre de l'automatisation seront faciles.",
  "Are the process inputs provided in a structured manner?":
    "Les entrées d'automatisation sont-elles fournies de manière structurée ?",
  "Is a full data set availbale to test this process ?":
    "Un ensemble de données complet est-il disponible pour tester cette automatisation ?",
  "What is the level of IT environment complexity?":
    "Quel est le niveau de complexité de l'environnement IT ?",
  "What level of documentation exists to support this process?":
    "Quel niveau de documentation existe-t-il pour appuyer cette automatisation?",
  "What is scale of the security challenge for authentication?":
    "Quelle est l'ampleur du défi que représente l'authentification en matière de sécurité ?",

  "What is the Time required for a single but complete process execution?":
    "Quel est le temps nécessaire à l'exécution d'une automatisation unique mais complet ?",
  "What does an execution represent?":
    "Que représente une exécution ?",
  "What is the frequency which this process occures?":
    "Quelle est la fréquence à laquelle cette automatisation se produit ?",
  "What is the value that would drive a round of process executions?":
    "Quelle est la valeur qui conduirait à une série d'exécutions?",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
    "Veuillez indiquer la nature du driver pour l'occurrence d'automatisation (nombre de sociétés, de comptes, d'écritures, etc.)",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
    "Hebdomadaire = 52, Mensuel = 12, Quotidien = 220, etc. Pour des fréquences spécifiques, veuillez ajouter les détails de vos calculs dans la section commentaire",

  "This field is populated automatically from the details from the Time Spent Section":
    "Ce champ est rempli automatiquement à partir des données de la section « temps passé » pour une exécution",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.":
    "Sélectionnez la sévérité des avantages et mentionnez dans la zone de commentaires les avantages attendus, par exemple une meilleure qualité, des gains de productivité, une réduction des délais, etc. ",
  "To what extent is this same process performed worldwide?":
    "Dans quelle mesure ce même automatisation est-il appliqué dans le monde entier ?",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
    "Des modifications sont-elles prévues sur les systèmes et outils, qui pourraient avoir un impact sur cette automatisation? (p. ex., élaboration de nouveaux rapports)",

  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
    "Sélectionnez le nombre d'étapes (Clics, navigation, lecture et écriture dans chaque fenêtre d'application), si nécessaire précisez en commentaires",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
    "Sélectionnez le nombre de fenêtres (écrans) qui changent pendant l'exécution d'automatisation, si nécessaire précisez dans les commentaires",
  "Select the number of  the business rules governing the process, if necessary precise in comments":
    "Sélectionnez le nombre de règles de gestion qui gouvernent l' automatisation, si nécessaire précisez dans les commentaires",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
    "Sélectionnez le nombre d'applications métier impliquées dans l' automatisation (Nombre d'objets à créer), si nécessaire précisez dans les commentaires",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
    "Choisissez si des données non structurées (PDF, Excel, e-mail, fichier Txt) ou tout traitement OCR seront traités, si nécessaire précisez dans les commentaires",
  "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments":
    "Choisissez si une communication avec une base de données à établir ou toute commande sql sera exécutée si nécessaire  précisez dans les commentaires",
  "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments":
    "Sélectionnez s'il y a un script Powershell à installer ou un code PowerShell à exécuter dans la ligne de commande, si nécessaire  précisez dans les commentaires",
  "Select if the resources will have a Windows account and communicate by email or Teams, if necessary precise in comments":
    "Choisissez si les ressources auront un compte Windows et communiqueront par courrier électronique ou par Teams, si nécessaire  précisez dans les commentaires",
  "Select if there is a need to create VB scripts or run macros directly on blue prism if necessary precise in comments":
    "Sélectionnez s'il est nécessaire de créer des scripts VB ou d'exécuter des macros directement sur BluePrism si nécessaire précisez dans les commentaires",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
    "Sélectionnez s'il existe une possibilité d'utiliser les services web ou de les exposer à d'autres automatisation, si nécessaire précisez dans les commentaires",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
    "Indiquer si l' automatisation interagit avec des applications web via les navigateurs (IE, Chrome, Firefox), si nécessaire précisez dans les commentaires",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
    "Indiquer si l' automatisation interagit avec les applications Windows installées sur l'ordinateur, si nécessaire précisez dans les commentaires",
  "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
    "Sélectionnez s'il existe une application à laquelle nous accédons via un émulateur (IBM, AS400, HUB 5250), si nécessaire précisez dans les commentaires",
  "Select if  there is any application that we access via the cloud (image), if necessary precise in comments":
    "Sélectionnez s'il existe une application à laquelle nous accédons via le Cloud (image), si nécessaire  précisez dans les commentaires",
  "Select if this process can be automated without the use of RPA technologies":
    "Sélectionnez si cette automatisation peut être automatisé sans l'utilisation de la technologie RPA",

  "Are you sure you want to save this case as a draft ?":
    "Êtes-vous sûr de vouloir sauvegarder ce dossier comme brouillon ?",
  "Case saved as a draft": "Dossier sauvegardé comme brouillon",
  "Are you sure you want to save and submit this case later ?":
    "Êtes-vous sûr de vouloir sauvegarder et soumettre ce dossier plus tard ?",
  "Case saved but not submitted": "Dossier sauvegardé mais non soumis",
  "Are you sure you want to save and submit this case ?":
    "Êtes-vous sûr de vouloir sauvegarder et soumettre ce dossier ?",
  "Case saved and submitted for approval":
    "Dossier sauvegardé et soumis pour approbation",
  "rpaQual.transition.accepted":
    "Êtes-vous sûr de vouloir accepter ce dossier comme une opportunité d'automatisation ?",
  "Case accepted as an opportunity": "Dossier accepté comme opportunité",
  "Are you sure you want to reject this case ?":
    "Êtes-vous sûr de vouloir rejeter ce dossier ?",
  "Case rejected": "Dossier rejeté",
  "This case is not yet submitted. Are you sure you want to delete it ?":
    "Ce dossier n'a pas encore été soumis. Êtes-vous sûr de vouloir le supprimer ?",
  "Case deleted successfully": "Dossier supprimé avec succès",
  "Case archived successfully": "Dossier archivé avec succès",
  "This case is submitted and awaiting approval. Are you sure you want to delete it ?":
    "Ce dossier est soumis et en attente d'approbation. Êtes-vous sûr de vouloir le supprimer ?",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it ?":
    "Ce dossier est une opportunité d'automatisation acceptée. Êtes-vous sûr de vouloir le supprimer ?",
  "Are you sure you want to delete this case ?":
    "Êtes-vous sûr de vouloir supprimer ce dossier ?",
  "Your progress will be lost, are you sure you want to exit ?":
    "Votre avancement sera perdu, êtes-vous sûr de vouloir quitter ?",
  "Total in man-days": "Total en jours-homme",
  "Running costs in man-days": "Frais de maintenance en jours-homme",
  months: "mois",
  Months: "Mois",
  Weeks: "Semaines",
  Years: "Années",
  days: "jours",
  weeks: "semaines",
  years: "années",
  left: "de plus",
  ago: "Depuis {{value}} {{unit}}",
  Logout: "Se déconnecter",
  "Execution Count": "Exécutions",
  "Exception.count": "Exceptions",
  process: "Automatisation",
  "point.amortissage.negative": "Dans {{value}} {{unit}}",
  SearchProcess: "Recherche d'automatisation",
  searchUser: "Rechercher un utilisateur",
  status: "Statut",
  sortBy: "Trier par",
  "Execution per": "Executé par",
  "Failed To create User": "Échec de la création de l'utilisateur",
  "Failed To create Scheduler": "Échec de la création du programme",
  "user.keycloak.exists": "L'utilisateur existe déjà en keycloak",
  "user.email.exists": "L'utilisateur existe déjà",
  "User with same email already exists": "Un utilisateur avec cet email exite déjà",
  "user.update.fail": "La mise à jour de l'utilisateur a échoué",
  "user.update.success": "L’utilisateur a été mis à jour avec succès!",
  deleteUsersActionMessage:
    "Êtes vous sûr de vouloir supprimer ces utilisateurs?",
  "profile.update-password.current-password-required":
    "Le mot de passe actuel est requis",
  "profile.update-password.new-password-required":
    "Un nouveau mot de passe est requis",
  "profile.update-password.password-required": "Mot de passe requis",
  "profile.update-password.password-not-matched":
    "Les mots de passe ne sont pas identiques.",
  "profile.update-password.current-password": "Mot de passe actuel",
  "profile.update-password.new-password": "Nouveau mot de passe",
  "profile.update-password.confirm-password": "Confirmer le nouveau mot de passe",
  "profile.update-password.reset-password": "Réinitialiser le mot de passe",
  "profile.update-password.password-reset": "Réinitialiser le mot de passe",
  "profile.update-password.password-reset-success":
    "Votre mot de passe à été changé avec succès",
  "profile.update-password.password-reset-done": "Terminer",
  "profile.update-password.update-password": "Modifier le mot de passe",
  "profile.update-password.email": "Votre email actuel",
  "profile.update-password.tokenError":
    "Jeton non valide, le mot de passe est déjà mis à jour!",
  "profile.update-password.password-email-send":
    "Un e-mail a été envoyé. Veuillez vérifier votre e-mail.",
  "profile.update.password.success": "Mot de passe changé avec succès",
  "profile.update.password.wrong.current.password": "Le mot de passe actuel est incorrect",
  "token.validation.noteExist":
    "Demande de changement de mot de passe déjà envoyée",

  "Are you sure you want to start this process ?":
    "Êtes-vous sur de vouloir démarrer cette automatisation ?",
  "Process CompletedItems": "Terminés",
  "Completed time": "Heure de fin",
  "traited item": "Eléments traités",
  "Items Count": "Eléments traités",
  "process.devCost.edit.cancel":
    "Vos modifications seront perdues, êtes-vous sûr de vouloir annuler?",
  "process.devCost.edit.confirm":
    "Voulez-vous vraiment enregistrer vos modifications?",
  "process.status.pending": "EN ATTENTE",
  draft: "BROUILLON",
  "not submitted": "Non envoyée",
  "no.exception.message": "Aucune exception détectée",
  "show.all.exception.button": "Afficher tout",
  manageUsers: "Gestion des utilisateurs",
  "assessment.Accounting & Finance": "Comptabilité et Finance",
  "assessment.Compliance": "Conformité",
  "assessment.Customer Service": "Service clientèle",
  "assessment.Purchasing": "Achat",
  "assessment.IT Operations": "Opérations informatiques",
  "assessment.Maintenance": "Maintenance",
  "assessment.Sales": "Ventes",
  "assessment.Marketing": "Commercialisation",
  "assessment.Research and Development": "Recherche et développement",
  "assessment.Production": "Production",
  "assessment.Human Resources Management ": "Gestion des ressources humaines ",
  "assessment.Software Development": "Développement de logiciels",
  "assessment.Other": "Autre",
  "assessment.Seconds": "Secondes",
  "assessment.Minutes": "Minutes",
  "assessment.Hours": "Heures",
  "assessment.Months": "Mois",
  "assessment.Years": "Années",
  "assessment.Days": "Journées",
  "assessment.Weeks": "Semaines",
  "orchestrator.management.menu.title": "Orchestrateurs",
  "resource.management.menu.title": "Ressources",
  "orchestrator.management.deleteOrchestratorsActionMessage":
    "Êtes-vous sûr de vouloir supprimer les orchestrateurs sélectionnés ?",
  "orchestrator.management.enaDes.confirmMsg":
    "Etes-vous sûr de vouloir activer l'orchestrateur ? La connexion à votre environnement RPA sera réinitialisée",
  "orchestrator.management.status": "Statut",
  "orchestrator.management.name": "Nom",
  "orchestrator.management.solutionProvider": "Editeur RPA",
  "orchestrator.management.isActionable": "Actionnable",
  "orchestrator.management.orchestratorIp": "IP d'orchestrateur",
  "orchestrator.management.isAcionableToggle":
    "Êtes-vous sûr que cet orchestrateur n'est pas actionnable ?",
  "orchestrator.management.editHeader": "Modifier l'orchestrateur",
  "orchestrator.management.addHeader": "Ajouter un orchestrateur",
  "orchestrator.management.displayHeader": "Détails d'orchestrateur",
  "orchestrator.management.formControl.required": "Champ obligatoire",
  "orchestrator.management.formControl.orchestratorType": "Type d'orchestrateur",
  "orchestrator.management.formControl.orchestratorId": "Identifiant",
  "orchestrator.management.formControl.orchestratorName": "Nom d'orchestrateur",
  "orchestrator.management.formControl.orchestratorName.information":
    "Le nom de l'orchestrateur",
  "orchestrator.management.formControl.description.information":
    "Toutes les informations dont vous avez besoin à ajouter ici",
  "orchestrator.management.formControl.orchestratorIp.information":
    "IP publique ou privé de la machine de l'orchestrateur",
  "orchestrator.management.formControl.sessionNumber.information":
    "Le nombre de sessions autorisées dans la licence BP active actuelle",
  "orchestrator.management.formControl.databaseServer.information":
    "Le nom d'hôte du serveur de base de données",
  "orchestrator.management.formControl.serverPort.information":
    "Cela doit correspondre au port d'écoute configuré sur le serveur SQL",
  "orchestrator.management.formControl.databaseName.information":
    "Le nom de la base de données utilisée par le BluePrism",
  "orchestrator.management.formControl.dbUsername.information":
    "Le nom d'utilisateur de la base de données à utiliser lors de la connexion.L'utilisateur doit avoir des autorisations DB_DATAREADER et DB_DATAWRITER sur la base de données BluePrism",
  "orchestrator.management.formControl.dbPassword.information":
    "Le mot de passe du nom d'utilisateur utilisé lors de la connexion",
  "orchestrator.management.formControl.apiClientId.information": "L'identifiant de l'application fourni par UiPath lors de la création",
  "orchestrator.management.formControl.apiClientSecret.information": "Le secret de l'application fourni par UiPath lors de la création",
  "orchestrator.management.formControl.organizationName.information": "Le nom de l'organisation de l'orchestrateur UiPath",
  "orchestrator.management.formControl.tenantName.information": "Le nom du locataire à partir duquel les données seront récupérées",
  "orchestrator.management.formControl.orchestratorApiName.information": "Le nom de l'orchestrateur UiPath",
  "orchestrator.management.formControl.apiClientId": "ID de l'application API",
  "orchestrator.management.formControl.apiClientSecret": "Secret de l'application API",
  "orchestrator.management.formControl.solutionProvider": "Editeur RPA",
  "orchestrator.management.formControl.orchestratorIp": "IP d'orchestrateur",
  "orchestrator.management.formControl.yes": "Oui",
  "orchestrator.management.formControl.no": "Non",
  "orchestrator.management.formControl.orchestratorId.actionnable":
    "Actionnable",
  "orchestrator.management.formControl.apiPath": "API path",
  "orchestrator.management.formControl.organizationName": "Nom de l'organisation Uipath",
  "orchestrator.management.formControl.tenantName": "Nom du locataire Uipath",
  "orchestrator.management.formControl.orchestratorApiName": "Nom de l'orchestrateur Uipath",
  "orchestrator.management.save.confirmMsg":
    "Tu es sûr de vouloir sauver cet orchestrateur ?",
  "orchestrator.management.isAcionableToggle.confirm": "Oui, je suis sûr",
  "orchestrator.button.update": "Mettre à jour l'orchestrator",
  "orchestrator.delete.discard":
    "Êtes-vous sûr de vouloir ignorer ces modifications ?",
  "orchestrator.management.isNotAcionableToggle":
    "Êtes-vous sûr de vouloir rendre cet orchestrateur actionnable ?",
  "orchestrator.management.isNotAcionableToggle.confirm": "Rendre actionnable",
  "orchestrator.management.enaDes.button.confirm": "Activer",
  "orchestrator.management.desaDes.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver l'orchestrateur ? La connexion à votre environnement RPA sera perdue.",
  "orchestrator.management.desaDes.button.confirm": "Désactiver",
  "orchestrator.management.formControl.description": "Description",
  "orchestrator.management.formControl.databaseServer":
    "Serveur de base de données",
  "orchestrator.management.databaseServer": "Serveur de base de données",
  "orchestrator.management.formControl.serverPort": "Port",
  "orchestrator.management.serverPort": "Port",
  "orchestrator.management.formControl.databaseName":
    "Nom de la base de données",
  "orchestrator.management.formControl.dbUsername": "Nom d'utilisateur",
  "orchestrator.management.formControl.dbPassword": "Mot de passe",
  "orchestrator.management.formControl.invalidPort":
    "Le numéro de port doit être compris entre 1 et 65,535",
  "orchestrator.management.testConnection": "Test connexion",
  "orchestrator.management.testConnection.successMessage":
    "Connexion a réussie",
  "orchestrator.management.testConnection.failureMessage":
    "Connexion a échouée",
  "Are you sure you want to accept this case as an opportunity for automation ?":
    "Êtes-vous sûr de vouloir accepter ce cas comme une opportunité d'automatisation ?",
  "Are you sure you want to save the changes?":
    "Êtes-vous sûr de vouloir enregistrer les modifications?",
  "Schedule saved successfully": "Fréquence enregistrée avec succès",
  "Frequency Deleted Successfully": "Fréquence supprimée avec succès",
  "Schedule modified successfully": "Votre demande a été envoyée à BP et le programme sera bientôt mis à jour",
  "Failed to update the schedule! Please try again later":
    "Impossible de mettre à jour le calendrier!Veuillez réessayer plus tard",
  "user.enaDes.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",
  "Create schedule": "Créer le programme",
  "Edit schedule": "Modifier le programme",
  "Confirm frequency": "Confirmer la fréquence",
  "Update frequency": "Mettre à jour la fréquence",
  "Disregard changes": "Ignorer",
  "Save changes": "Sauvegarder les modifications",
  apply: "APPLIQUER",
  reset: "RÉINITIALISER",
  processes: "Automatisation",
  ACTIVE: "Actif",
  DISABLE: "Inactif",
  DISABLED: "Inactif",
  Disabled: "Désactiver",
  IDLE: "Prêt",
  PRIVATE: "Privé",
  WORKING: "En cours d'exécution",
  OFFLINE: "Déconnecté",
  Idle: "Prêt",
  Working: "En cours d'exécution",
  Offline: "Déconnecté",
  Missing: "Manquant",
  "Process status": "Statut d'automatisation",
  "timeUnite.millis": " ms",
  "timeUnite.sec": " sec(s)",
  "timeUnite.min": " min(s)",
  "timeUnite.hrs": " HR(s)",
  "timeUnite.days": " jr",
  "timeUnite.manDay": "j/h",
  "timeUnite.fte": "ETP",
  "Choose icon": "Choisissez l'icône",
  "Edit Icon": "Modifier l'icone",
  "Process Occurence": "Eléments en succès",
  "Process Item": "Eléments",
  export: "Exporter",
  "user.management.menu.title": "Utilisateurs",
  "user.management.add.button": "Créer un utilisateur",
  "user.management.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
  "user.management.deleteUsersActionMessage":
    "Êtes-vous sûr de vouloir supprimer les utilisateurs sélectionnés ?",
  "user.management.enaDes.confirmMsg":
    "Êtes-vous sûr de vouloir activer cet utilisateur ?",
  "user.management.enaDes.button.confirm": "Activer",
  "user.management.desaDes.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
  "user.management.desaDes.button.confirm": "Désactiver",
  depuis: "Depuis",
  "depuis la premiere execution": "la première exécution trouvée",
  "kpi.parameter.be": "Inclure les données depuis",
  "kpi.parameter.includeException":
    "Inclure les éléments en exceptions métier dans les KPI financiers",
  "kpi.parameter.executionsIncludeException":
    "Compatibiliser les executions en exceptions dans les KPIs financiers",
  "kpi.parameter.invalidRate": "Taux non valide",
  "kpiParam.update.success": "Paramètres mis à jour avec succès",
  "kpiParam.update.error": "Échec de la mise à jour des paramètres KPI",
  "kpi.parameter.includeSince.tooltip":
    "Choisissez quand SmartRoby commencera à utiliser et à afficher les données d'automatisation dans les modules et les indicateurs de performance clés dans tout le portail.",
  "kpi.header.success.rate.tooltip":
    "Le taux de réussite est calculé pour les éléments. Les résultats sont basés sur les paramètres configurés pour chaque automatisation et le filtre de la période du tableau de bord.",
  "kpi.header.exception.rate.tooltip":
    "Le taux d'exception est calculé pour les éléments. Les résultats sont basés sur les paramètres configurés pour chaque automatisation et le filtre de la période du tableau de bord.",
  "kpi.parameter.includeExceptFinancial.tooltip":
    "Inclut un pourcentage du temps économisé pour chaque élément traité avec une exception commerciale",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
    "Inclut un pourcentage du temps économisé pour chaque exécution qui s'est terminée par une exception commerciale",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
    "La période à considérer lors de l'estimation du point de rupture pour cette automatisation",
  // ROUTES
  "routes.home": "Accueil",
  "routes.orchestrators": "orchestrateurs",
  "routes.orchestrators.processAssessment": "Évaluation des Automatisation",
  "routes.orchestrators.processAssessment.rpa": "RPA",
  "routes.orchestrators.processAssessment.rpa.view": "voir",
  "routes.orchestrators.processAssessment.rpa.create": "Ajouter",
  "routes.orchestrators.processAssessment.rpa.edit": "Modifier",
  "routes.orchestrators.smartResources": "smart Ressources",
  "routes.orchestrators.myProcesses": "Automatisation",
  "routes.orchestrators.smartResources.documents": "documents",
  "routes.orchestrators.smartResources.documents.details": "details",
  "routes.orchestrators.administration": "administration",
  "routes.orchestrators.administration.users": "Utilisateurs",
  "routes.orchestrators.administration.users.add": "Ajouter",
  "routes.orchestrators.administration.users.edit": "Modifier",
  "routes.orchestrators.administration.users.view": "Voir",
  "routes.orchestrators.protected": "privée",
  "routes.orchestrators.protected.user": "Utilisateur",
  "routes.orchestrators.protected.user.profile": "profile",
  "routes.orchestrators.administration.orchestrators": "orchestrateurs",
  "routes.orchestrators.administration.orchestrators.add": "Ajouter",
  "routes.orchestrators.administration.orchestrators.edit": "Modifier",
  "routes.orchestrators.administration.orchestrators.view": "Voir",
  "routes.orchestrators.smartocr": "smart ocr",
  "routes.orchestrators.smartocr.identity_card": "Carte d'identité",
  "routes.orchestrators.smartocr.passport": "passport",
  "routes.orchestrators.smartocr.driver_licence": "Permis de conduire",
  "routes.orchestrators.smartocr.car_registration": "immatriculation véhicule",
  "routes.orchestrators.smartocr.green_card": "green_card",
  "routes.orchestrators.smartocr.rib": "rib",
  "routes.orchestrators.smartocr.invoice": "facture",
  "routes.orchestrators.smartocr.purchase_order": "ordre d'achat",
  "routes.orchestrators.smartocr.tax_notice": "avis d'impo.",
  "routes.orchestrators.smartocr.receipt": "reçu",
  "routes.orchestrators.smartocr.edf": "edf",
  "routes.orchestrators.smartocr.payroll_statemenet": "fiche de paie",
  "routes.orchestrators.exceptions": "exceptions",
  "routes.orchestrators.exceptions.processes": "Exceptions des automatisations",
  "routes.orchestrators.process-occurrence": "Occurrences des automatisations",
  "routes.orchestrators.exceptions.items": "Eléments en exception",
  // ALERT MANAGEMENT ROUTES
  "routes.orchestrators.alertsManagement": "Notifications",
  "routes.orchestrators.alertsManagement.defaultAlerts":
    "Notifications par défaut",
  "routes.orchestrators.alertsManagement.customAlerts":
    "Notifications personnalisées",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "Ajouter",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "Modifier",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "Voir",
  "routes.orchestrators.alertsManagement.customAlerts.add": "Ajouter",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "Modifier",
  "routes.orchestrators.alertsManagement.customAlerts.view": "Voir",
  "exceptions.item.tab.title": "Exceptions",
  "exceptions.processes.tab.title": "Exceptions des automatisations",
  "routes.orchestrators.process-occurence": "occurrence d'automatisation",
  "routes.orchestrators.profile": "profile",
  "routes.orchestrators.administration.fleets": "",
  "routes.orchestrators.administration.fleets.add": "Ajouter",
  "routes.orchestrators.administration.fleets.edit": "Modifier",
  "routes.orchestrators.administration.fleets.view": "Voir",
  "save draft": "Sauvegarder",
  "user.title.editHeader": "Modifier l'utilisateur",
  "fleet.management.status": "Statut",
  "fleet.management.companyName": "Nom de groupe",
  "fleet.management.email": "Email",
  "fleet.management.clientSince": "Groupe créé depuis",
  "fleet.management.menu.title": "Groupes",
  groups: "Groupes",
  "fleet.management.add.button": "Créer un groupe",
  "fleet.management.formControl.invalidDate": "Format de date non valide",
  "fleet.management.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer ce client ?",
  "fleet.management.deleteClientsActionMessage":
    "Êtes-vous sûr de vouloir supprimer les clients sélectionnés",
  "fleet.management.enaDes.confirmMsg":
    "Êtes-vous sûr de vouloir activer ce client ?",
  "fleet.management.enaDes.button.confirm": "Activer",
  "fleet.management.desaDes.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver ce client ?",
  "fleet.management.desaDes.button.confirm": "Désactiver",
  "fleet.management.searchClient": "Rechercher un flot",
  "fleet.management.addHeader": "Ajouter un client",
  "fleet.management.editHeader": "Modifier un client",
  "fleet.management.displayHeader": "Détails du client",
  "fleet.management.formControl.required": "Champ obligatoire",
  "fleet.management.formLabel.companyName": "Nom de groupe",
  "fleet.management.formControl.email": "Mauvais format d'e-mail",
  "fleet.management.formControl.phone":
    "Le format du numéro de téléphone n'est pas conforme",
  "user.management.formControl.phone": "Mauvais format de téléphone",
  "fleet.management.formLabel.email": "Email",
  "fleet.management.formLabel.phoneNumber": "Numéro de téléphone",
  "fleet.management.formLabel.vatNumber": "Numéro de TVA",
  "fleet.management.formControl.vatNumber.notValid":
    "La valeur doit être un nombre",
  "fleet.management.formLabel.address": "Adresse complète",
  "fleet.management.formLabel.subscription": "Abonnement",
  "fleet.management.formLabel.clientSince": "Client depuis",
  "fleet.button.update": "Mettre à jour",
  "fleet.button.save": "Ajouter",
  "fleet.button.cancel": "Annuler",
  "fleet.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les informations de ce groupe ?",
  "fleet.save.confirmMsg": "Êtes-vous sûr de vouloir ajouter ce groupe ?",
  "fleet.delete.discard":
    "Êtes-vous sûr de vouloir abandonner vos modifications ?",
  "fleet.button.discard": "Abandonner",
  "fleet.formControl.updateSuccess":
    "Les informations du client ont été mises à jour avec succès",
  "fleet.formControl.updateError":
    "Erreur lors de la mise à jour des informations du le client",
  "fleet.formControl.saveError": "Erreur lors de l'ajout d'un nouveau client",
  "orchestrator.formControl.updateSuccess":
    "Les informations d'orchestrateur ont été mises à jour avec succès ",
  "user.management.formLabel.fleet": "Groupe",
  "processesSelector.availableProcess": "Automatisation disponibles",
  "processesSelector.assignedProcess": "Automatisation assignés",
  "processTagSelector.assignedProcessTags": "Automatisation & tags assignés",
  "tagSelector.availableTags": "Tags disponibles",
  "user.formControl.saveError":
    "Erreur lors de l'ajout d'un nouveau utilisateur",
  "user.formControl.updateError":
    "Erreur lors de la mise à jour des informations du l'utilisateur",
  "user.formControl.updateSuccess":
    "Les informations de l'utilisateur ont été mises à jour avec succès",
  "user.formControl.saveSuccess": "Nouvel utilisateur ajouté avec succès",
  "fleet.formControl.saveSuccess": "Nouveau client ajouté avec succès",
  "orchestrator.formControl.saveSuccess":
    "Nouveau orchestrateur ajouté avec succès",
  "user.permissions.title": "Autorisations de l'utilisateur",
  "user.permission.save": "sauvegarder",
  "user.permissions.template.createBtn": "Créer un modèle",
  "user.add.template": "Modèle",
  "user.permission.confirmSave":
    "Êtes-vous sûr de vouloir mettre à jour les autorisations des utilisateurs?",
  "user.permission.confirmDelete":
    "Êtes-vous sûr de vouloir supprimer ce modèle?",
  "user.permission.saveAsTemplace": "Sauvegarder et créer un modèle",
  "user.permission.saveAsTemplaceTitle": "Nouveau modèle",
  "user.permission.templateCreate.success": "Modèle créé avec succès",
  "user.permission.templateDelete.success": "Modèle supprimé avec succès",
  "user.permission.templateCreate.error": "Nom de modèle existe déjà",
  "permission.action.applyTemplate": "Appliquer un modèle",
  "user.permissions.template.name": "Nom de modèle",
  "user.permissions.default": "Défaut",
  "user.add.permissions": "Autorisations",
  "fleet.formControl.unassignProcessError":
    "La automatisation est déjà attribué à {{users}} utilisateurs",
  "fleet.management.delete.warning":
    "La suppression d'un client entraîne la suppression automatique de tous ses utilisateurs et la désaffectation de ses automatisation !",
  "user.administration.fleet": "Groupe",
  "user.administration.lastLogin": "Dernière connexion",
  "tooltip.action.enable": "Activer",
  "tooltip.action.disable": "Désactiver",
  "tooltip.action.permissions": "Autorisation",
  "user.management.formControl.required": "Champ obligatoire",
  "user.management.formControl.email": "Mauvais format d'e-mail",
  "fleet.management.formControl.siret.notValid": "SIRET non valide",
  "fleet.management.formControl.siren.notValid": "SIREN non valide",
  "fleet.formSection.fleetInfo": "Informations générales",
  "fleet.formSection.clientParams": "Paramètres du client",
  "fleet.formSection.subscriptionInfo": "Infos sur l'abonnement",
  "fleet.formSection.clientParameters": "Paramètres groupe",
  "fleet.management.formLabel.rate": "Taux",
  "fleet.management.formLabel.overConsumptionRate": "Taux de surconsommation",
  "fleet.management.formLabel.subscriptionPeriod": "Période d'abonnement",
  "fleet.management.formLabel.paymentTerm": "Délai de paiement",
  "fleet.formSection.processes": "Automatisation",
  "fleet.formSection.processesTags": "Automatisation et tags",
  "fleet.formSection.summary": "Résumé de l'abonnement actif",
  "fleet.management.formLabel.rateUnit": "SAR/heure",
  "fleet.management.formLabel.subscriptionPeriodUnit": "mois",
  "fleet.management.formLabel.siret": "Siret",
  "fleet.management.formLabel.siren": "Siren",
  "fleet.management.formLabel.manDayValue": "1 Jour-homme (j/h)",
  "fleet.management.formLabel.fullTime": "1 Employé à temps plein (ETP)",
  "fleet.management.formLabel.bePeriod":
    "Période de calcul du point d'amortissement",
  "fleet.management.formLabel.recommendedOccupancy": "L'occupation recommandée",
  "fleet.management.be.period.1": "Mois dernier",
  "fleet.management.be.period.2": "3 derniers mois",
  "fleet.management.be.period.3": "6 derniers mois",
  "fleet.management.be.period.4": "L'année dernière",
  "fleet.add.previous": "Précédent",
  "process.filter.client": "Client",
  "process.enabled": "Affiché",
  "process.filter.showAll": "Afficher les ({{count}}) automatisation masqués",
  Unremarkable: "< 5 Ans",
  "fleet.management.formControl.number":
    "Le format du numéro de TVA n'est pas conforme",
  "fleet.management.formControl.occupancyNumber": "L'occupation recommandée doit être un nombre",
  "fleet.management.formControl.occupancyHigherThan": "L'occupation recommandée ne peut pas être supérieure à 24",
  "fleet.management.formControl.occupancyLowerThan": "L'occupation recommandée ne peut pas être inférieure à 0",
  "fleet.management.hoursPerDay": "Heures par jour",
  "reporting.copyToClipboard": "Copier dans le presse-papier",
  "reporting.process": "Automatisation",
  "reporting.kpi": "KPI",
  "reporting.reportType": "Type de rapport",
  "reporting.date": "Date",
  "reporting.table": "Tableau",
  "reporting.lineChart": "Graphique linéaire",
  "reporting.pieChart": "Diagramme circulaire",
  "reporting.barChar": "Diagramme à bandes",
  "reporting.columnChart": "Graphique de colonne",
  "reporting.totalExecution": "Nombre d'exécutions",
  "reporting.totalException": "Nombre d'exceptions",
  "reporting.timeSaved": "Temps Homme économisé",
  "reporting.executionTime": "Temps de traitement des executions",
  "reporting.itemExecutionTime": "Temps de traitement des items",
  "reporting.breakEven": "Seuil de rentabilité",
  "reporting.roi": "Taux de rentabilité",
  "reporting.roiPercentage": "Taux d'amortissement",
  "reporting.roiShort": "Taux d'amortissement",
  "reporting.occupancyRate": "Taux d'occupation des ressources",
  "reporting.executedProcesses": "Nombre d'exécutions",
  "reporting.save": "Exporter PNG",
  "reporting.copyClipboard": "Copier dans le presse-papier",
  "reporting.exportPDF": "Exporter PDF",
  "reporting.clear": "Effacer",
  "reporting.from": "De",
  "reporting.to": "À",
  "reporting.successCount": "Nombre d'éléments traités avec succès",
  "reporting.exceptionCount": "Nombre d'éléments en exceptions",
  "reporting.reportTitle": "entre {{from}} et {{to}}",
  "reporting.and": " et",
  for: "pour",
  "Results for": "Résultats pour",
  "reporting.cumul": "Résultat cumulatif",
  "reporting.presetDeletionSuccess": "Le préréglage a été supprimé avec succès",
  "reporting.presetDeletionError": "Le préréglage n'a pas pu être supprimé",
  "reporting.presetCreationSuccess": "Le préréglage a été créé avec succès",
  "reporting.presetCreationError": "Le préréglage n'a pas pu être créé",
  "reporting.customKpiCreationOrUpdateSuccess": "L'indicateur KPI personnalisé a été enregistré avec succès",
  "reporting.customKpiCreationOrUpdateError": "L'indicateur KPI personnalisé n'a pas pu être enregistré",
  "reporting.customKpiDeletionSuccess": "L'indicateur KPI personnalisé a été supprimé avec succès",
  "reporting.customKpiDeletionError": "L'indicateur KPI personnalisé n'a pas pu être supprimé",
  "Process launcher": "Lanceur d'automatisation",
  "Execution detail": "Détails de l'exécution",
  "Business Exception": "Exception fonctionnelle",
  "System Exception": "Exception du système",
  Unspecified: "Non spécifié",
  Internal: "Interne",
  "Process.list.names.by.orchestrator": "Automatisation",
  "Resource.list.names.by.orchestrator": "Ressources",
  "fleet.management.formLabel.effectiveStartDate": "Date de début effective",
  "rpaQual.transition.delete": "Supprimer",
  "rpaQual.transition.deleteMsg":
    "Êtes-vous sûr de vouloir supprimer cette opportunité?",
  "rpaQual.button.delete":
    "Êtes-vous sûr de vouloir supprimer cette qualification?",
  "rpaQual.transition.reconsider": "Reconsidérer",
  "rpaQual.transition.reconsiderMsg":
    "Êtes-vous sûr de vouloir reconsidérer ce cas?",
  "rpaQual.transition.archive": "Archiver",
  "rpaQual.transition.archiveMsg":
    "Êtes-vous sûr de vouloir archiver cette affaire?",
  "rpaQual.process.archived.success": "Cas archivé",
  "rpaQual.process.roadMap.success": "Ajouté à la feuille de route",
  "rpaQual.process.reconsider.success": "Opportunité reconsidérée",
  "rpaQual.transition.addToRoadMap": "Ajouter à la feuille de route",
  "rpaQual.transition.addToRoadMapMsg":
    "Êtes-vous sûr de vouloir ajouter ce cas à votre feuille de route?",
  "rpaQual.filter.show": "Inclure archivée",
  "rpaQual.filter.hide": "Exclure les archivés",
  "rpaQual.display.nonArchived": "Pas archivée",
  "rpaQual.display.archived": "Archivée",
  "rpaQual.display.all": "Tout",
  "breadcrumbs.label.details": "Entrées",
  "rpaQual.opportunity.list.empty": "La liste des opportunités est vide !",
  "rpaQual.case.list.empty": "",
  "rpaQual.status.submitted": "En attente",
  "rpaQual.status.reconsider": "En attente",
  "rpaQual.status.accepted": "Acceptée",
  "rpaQual.status.draft": "Brouillon",
  "rpaQual.status.not submitted": "Non validée",
  "rpaQual.status.rejected": "Rejetée",
  "rpaQual.status.roadMap": "Feuille de route",

  "filter.graph": "Perspectives",
  "filter.export": "Exporter",

  // Analytics - help
  "help.process":
    "Veuillez choisir le(s) automatisation concerné(s) par l'analytique",
  "help.tags": "Veuillez choisir le(s) tag(s) concerné(s) par les analyses",
  "help.kpi": "Veuillez choisir le(s) KPI(s)",
  "help.reportType":
    "La liste des types de rapport affichée est basée sur le(s) KPI(s) choisis",

  "Select All": "Sélectionner tout",
  // permissions

  "User Administration": "Utilisateurs",
  "Group Administration": "Administration des groupes",
  "Tag Administration": "Administration des tags",
  "Client Administration": "Administration des clients",
  "Orchestrator Administration": "Administration des orchestrateurs",
  "Processes launcher": "Lanceur des automatisations",
  "Start process": "Démarrer une automatisation",
  "Schedule process": "Programmer une automatisation",
  "KPI parameters": "Paramètres",
  "Financial Parameters": "Paramètres financiers",
  "Edit process details": "Modifier les details d'automatisation",
  "Enable/Disable process": "Afficher / masquer les automatisations",
  "Item exceptions": "Eléments en exception",
  "Delete item exceptions": "Masquer les éléments en exception",
  "Retry item exceptions with changed data":
    "Relancer les éléments en exception avec des données modifiées",
  "Retry item exceptions": "Relancer les éléments en exception",
  Workflow: "Flux de travail",
  "Process execution exceptions": "Exceptions des automatisations",
  "Delete process execution exceptions":
    "Suppression des exceptions des automatisations",
  "View process exceptions": "Afficher les exceptions des automatisations",
  "Delete process exceptions": "Masquer les exceptions des automatisations",
  "View item exceptions": "Afficher les éléments en exception",
  "Add opportunity": "Ajouter une opportunitée",
  "Accept / Reject / Reconsider": "Accepter / Rejeter / Reconsidérer",
  "Archive / Backlog": "Archive / Backlog",
  "Delete opportunity": "Suppression des opportunitées",
  "Add client": "Ajout des clients",
  "Manage client": "Gestion des clients",
  "Delete client": "Suppression des clients",
  "Add user": "Ajout des utilisateurs",
  "Edit user": "Modification des utilisateurs",
  "Delete user": "Suppression des utilisateurs",
  "User Permissions": "Permissions des utilisateurs",
  "Add Orchestrator": "Ajout des orchestrateurs",
  "Edit Orchestrator": "Modification des orchestrateurs",
  "Delete Orchestrators": "Suppression des orchestrateurs",

  "process-occurence.cutsomdate": "Date personnalisée",
  "exception.analytics.by.process": "Exceptions par automatisation",
  "exception.analytics.by.type": "Résumé des types d'exceptions",
  "exception.analytics.by.reason": "Principales raisons d’exceptions",
  "exception.analytics.by.trend": "Tendance des exceptions",

  "Execution Time (Descending)": "Durée d'exécution (décroissant)",
  "Execution Time (Ascending)": "Durée d'exécution (croissant)",

  "no.process.message": "Aucune automatisation détecté",
  "no.queue.message": "Aucune file d'attente détectée",
  "no.completedItems.message": "Aucun élément terminé détecté",
  "no.pendingItems.message": "Aucun élément en attente détecté",
  "no.execution.message": "Aucune exécution trouvée",
  "no.scheduler.message": "Aucun programme trouvé",
  "no.type.message": "Aucun type trouvé",

  "no.orchestrator.message": "Pas d'orchestrateurs pour le moment",
  "no.resource.message": "Pas de ressources pour le moment",
  "no.fleets.message": "Pas de groupes pour le moment",
  "no.users.message": "Pas d'utilisateurs pour le moment",
  "no.resources.message": "Pas de ressources pour le moment",
  "no.items.exception.message": "Aucune élément en exception trouvée",
  "no.process.exception.message": "Aucune exception d'automatisation trouvée",
  "no.analytics.message": "Aucune automatisation détecté",
  "no.planned.execution.message": "Aucune exécution planifiée trouvée",
  "no.activities.message": "Aucune activité trouvée",
  "no.logs.message": "Aucun journal trouvé",
  "select.all": "Sélectionner tout",
  Clear: "Vider",

  "add.client.form.vat.number.email.company.name.already.exist":
    "Un client avec la même adresse e-mail, numéro de TVA et nom de la société existe déjà",
  "add.client.form.vat.number.email.already.exist":
    "Un client avec la même adresse e-mail et le même numéro de TVA existe déjà",
  "add.client.form.vat.number.company.name.already.exist":
    "Un client avec le même numéro de TVA et nom de la société existe déjà",
  "add.client.form.email.company.name.already.exist":
    "Un client avec la même adresse e-mail et nom de la société existe déjà",
  "add.client.form.email.already.exist":
    "Un client avec la même adresse e-mail existe déjà",
  "add.client.form.vat.number.already.exist":
    "Un client avec le même numéro de TVA existe déjà",
  "add.client.form.company.name.already.exist":
    "Un client avec le même nom de la société existe déjà",
  "restriction.form.MinimumExecutionFrequency":
    "La Fréquence d'exécution minimale",
  "add.client.form.vat.number.email.company.name.siren.already.exist":
    "Un client avec la même adresse e-mail, numéro de TVA, nom de la société et SIREN existe déjà",
  "add.client.form.vat.number.email.siren.already.exist":
    "Un client avec la même adresse e-mail, numéro de TVA et SIREN existe déjà",
  "add.client.form.vat.number.company.name.siren.already.exist":
    "Un client avec le même numéro de TVA, nom de la société et SIREN existe déjà",
  "add.client.form.vat.number.siren.already.exist":
    "Un client avec le même numéro de TVA et le même SIREN existe déjà",
  "add.client.form.email.company.name.siren.already.exist":
    "Un client avec la même adresse e-mail, nom de la société et SIREN existe déjà",
  "add.client.form.email.siren.already.exist":
    "Un client avec la même adresse e-mail et le même SIREN existe déjà",
  "add.client.form.company.name.siren.already.exist":
    "Un client avec le même nom de la société et le même SIREN existe déjà",
  "add.client.form.siren.already.exist":
    "Un client avec le même SIREN existe déjà",

  // Ressource Management
  "resource.management.name": "Nom",
  "resource.management.formControl.actionsRunning": "Actions en cours",
  "resource.management.formControl.processesRunning": "Automatisation en cours",
  "resource.management.formControl.resourceName": "Nom du ressource",
  "resource.management.formControl.resourceOriginalName":
    "Nom de la ressource d'origine",
  "resource.management.formControl.statusDescription": "Descriptif du statut",
  "resource.management.formControl.resourceId": "Id ressource",
  "resource.management.formControl.unitsAllocated": "Unités attribuées",
  "resource.management.solutionProvider": "Fournisseur de solution",
  "resource.management.displayStatus": "Statut",
  "resource.management.orchestratorName": "Orchestrateur",
  "resource.management.attributeName": "Attribut de la ressource",
  "resource.management.processesRunning": "Automatisation en cours",
  "resource.management.resourceId": "Id ressource",
  "resource.management.resourceName": "Nom du ressource",
  "Routes.orchestrators.administration.resources": "Ressources",
  "resource.management.displayHeader": "Details du ressource",
  "resource.management.enable.confirmMsg":
    "Êtes-vous sûr de vouloir activer cet utilisateur ?",
  "resource.management.disable.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
  "resource.management.disable.button.confirm": "Désactiver",
  "resource.management.enable.button.confirm": "Activer",
  "resource.button.update": "Mettre à jour",
  "resource.button.cancel": "Annuler",
  "resource.button.discard": "Ignorer",
  "resource.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les détails de ce ressource ?",
  "resource.update.discard":
    "Êtes-vous sûr de vouloir ignorer ces modifications ?",
  "resource.modify": "Modifier",
  "routes.orchestrators.administration.resources.view": "Voir",
  "routes.orchestrators.administration.resources.edit": "Détails",
  "routes.orchestrators.administration.resources": "Ressources",
  "administration.detail.entity.processes.title": "Automatisation",
  // ALERT MANAGEMENT
  "alerts.default.tab.title": "Notifications par défaut",
  "alerts.custom.tab.title": "Notifications personnalisées",

  // DEFAULT ALERTS TABLE HAEDER CELLS
  "alert.status": "Statut",
  "alert.alertName": "Nom",
  "default.alert.event": "Événement",
  "alert.condition": "Condition",
  "alert.type": "Type de notification",
  "report.type": "Type de rapport",

  "no.alerts.message": "Aucune notification trouvée !",
  "alerts.default.addButton": "Créer une notification",

  // CUSTOM ALERTS TABLE HAEDER CELLS
  "custom.alert.KPI": "KPI",
  "custom.alert.process": "Automatisation",

  "alerts.custom.addButton": "Ajouter Notification",
  "alert.management.delete.confirmMsg":
    "Voulez-vous vraiment supprimer cette notification?",

  // Add & Edit headers alert
  "alerts.addHeader": "Nouvelle notification",
  "alerts.editHeader": "Modifier la notification",
  "alerts.displayHeader": "Détails de la notification",

  // error messages
  "alerts.management.required.fields": "Champs requis",

  // confirmation buttons
  "alerts.management.button.update": "mettre à jour",
  "alerts.management.button.add": "ajouter",
  "alerts.management.button.cancel": "annuler",
  "alerts.management.button.save": "sauvegarder",
  "alerts.management.button.exit": "quitter",
  "alerts.management.button.discard": "ignorer",

  "alerts.management.trigger": "Déclencheur",
  "alerts.management.triggers": "Déclencheurs",
  "alerts.management.general": "Général",
  "alerts.management.checking.frequence": "Fréquence de vérification",
  "alerts.management.advanced.settings.alert.text": "SmartRoby vérifiera tous les {{days}} à partir de {{date}} à {{time}}",
  "week day": "jours de la semaine",
  "alerts.management.advanced.settings.btn": "Paramètres avancés",
  "alerts.management.simple.settings.btn": "Configuration simple",
  "alerts.management.advanced.settings.days.label": "Durant les jours suivants",
  "alerts.management.advanced.settings.time.label": "Durant les périodes suivantes",
  "alerts.management.advanced.settings.all.days.label": "Tous les jours",
  "alerts.management.advanced.settings.profile.time.zone": "Fuseau horaire du profil",
  "alerts.management.notifications": "Notifications",
  "alerts.management.subscribers": "Abonnés",
  "alerts.management.name.placeholder": "Entrez le nom de la notification",
  "alerts.management.name.label": "Nom de la notification *",
  "alerts.management.condition.label": "Valeur *",
  "alerts.management.alertDescription.placeholder":
    "Entrer la description de la notification",
  "alerts.management.alertDescription.label": "Description",
  "alerts.management.when.placeholder": "Quand *",
  "alerts.management.operator.placeholder": "Est *",
  "alerts.management.process.placeholder": "De *",
  "alerts.management.of.placeholder": "De *",

  "alerts.management.email.invalidEmail": "Format d'adresse e-mail invalide",
  "alerts.management.email.invalidEmails": "Format d'adresses e-mail invalides",
  "alerts.management.email.invalidPhoneNumber":
    "Format de numéro de téléphone invalide",
  "alerts.management.email.invalidPhoneNumbers":
    "Format des numéros de téléphone invalide",
  "alerts.management.severity": "Type de notification *",
  "alerts.management.save.confirmMsg":
    "Voulez-vous vraiment enregistrer cette notification ?",
  "alerts.management.update.confirmMsg":
    "Voulez-vous vraiment mettre à jour les informations de la notification ?",
  "alerts.management.delete.discard":
    "Voulez-vous vraiment annuler vos modifications ?",
  "Alert Frequency": "Fréquence",
  "alert.management.formControl.saveSuccess":
    "Nouvelle notification a été ajoutée avec succès",
  "alert.management.formControl.delete":
    "Notification a été supprimé",
  "alert.management.formControl.updateSuccess":
    "Les informations de la notification ont été  mises à jour avec succès",
  "alert.management.enable.email.notification":
    "Êtes-vous sûr de vouloir activer les notifications par email ?",
  "alert.management.disable.email.notification":
    "Êtes-vous sûr de vouloir désactiver les notifications par email ?",
  "alert.management.enable.sms.notification":
    "Êtes-vous sûr de vouloir activer les notifications par sms ?",
  "alert.management.disable.sms.notification":
    "Êtes-vous sûr de vouloir désactiver les notifications par sms ?",
  "alert.management.subscription.status.success": "Statut de l'abonnement mis à jour",
  "alert.management.subscription.status.error": "Échec de la mise à jour du statut de l'abonnement",
  "New opportunity created": "Opportunité créée",
  "Opportunity accepted": "Opportunité Acceptée",
  "Opportunity rejected": "Opportunité rejetée",
  "Opportunity added to the road map":
    "Opportunité ajoutée à la feuille de route",
  "Opportunity archived": "Opportunité archivée",
  "Process activated": "Automatisation activé",
  "Process disabled": "Automatisation désactivé",
  "Orchestrator enabled": "Orchestrateur activé",
  "Orchestrator disabled": "Orchestrateur désactivé",
  "Orchestrator actionable": "Orchestrateur rendu actionnable",
  "Orchestrator not actionable": "Orchestrateur n’est pas actionnable",
  "Schedule added": "Programme ajouté",
  "Execution Time": "Durée d'exécution ",
  "Process execution duration": "Durée d'exécution",
  "Item execution duration": "Durée d'exécution de l'élément",
  "Total Executions": "Nombre total d'exécutions",
  "Success Rate": "Taux de réussite",
  "Exceptions Rate": "Taux d'exception",
  "Items exception rate": "Taux d'exception des éléments",
  "Time Saved": "Temps économisé",
  "Number of completed items": "Nombre d'éléments complétés",
  "Number of process executions with exceptions":
    "Nombre d'exécutions avec exceptions",
  "Number of process executions completed successfully":
    "Nombre d'exécutions terminées avec succès",
  "create.scheduler": "Créer un programme",
  "Edit Scheduler": "Modifier le programme",
  "Choose Process": "Choisir l' automatisation",
  "Choose Resource": "Choisir le ressource",
  "First day of month": "Premier jour du mois",
  "Last day of month": "Dernier jour du mois",
  "Day number": "Numéro du jour",
  "alert.management.email.helper":
    "Vous pouvez valider l'ajout de votre e-mail en cliquant sur Entrée ou sur Espace.",
  "alert.management.sms.helper":
    "Vous pouvez valider l'ajout de votre numéro de téléphone en cliquant sur Entrée ou sur Espace.",
  "alert.management.trigger.helper":
    "En cas de déclencheurs multiples, tous les déclencheurs doivent être vrais pour que la notification soit envoyé",
  "alerts.management.trigger.exceptionReasons.not.found": "Aucune raison d'exception existante trouvée pour l' automatisation et le type d'exception sélectionnés",
  Active: "Actif",
  info: "Information",
  error: "Erreur",
  EQUAL: "ÉGALE",
  TRUE: "VRAI",
  FALSE: "FAUX",
  "Export as a Pdf": "Exporter au format PDF",
  "GREATER THAN": "Supérieure à",
  "LESS THAN": "Inférieure à",
  "GREATER OR EQUAL TO": "Supérieure ou égale à",
  "LESS OR EQUAL TO": "Inférieur ou égal à",
  "alerts.management.alertName.maxLength.error":
    "Ce champs ne doit pas dépasser 55 caractères!",
  "alerts.management.alertDescription.maxLength.error":
    "Ce champs ne doit pas dépasser 65535 caractères!",
  "no.process.planned": "Pas d'automatisation planifiés",
  orchestrator: "Orchestrateur",
  "start.process.no.resource.assigned": "Aucune ressource n'est assignée ou les ressources assignées sont hors ligne",
  Client: "Client",
  "kpi.header.breakEven.tooltip":
    "Le point d'amortissement s'estime sur la base de données historiques. Il répond à la question quand l'investissement dans les automatisations est-il pleinement acquis ? Les résultats sont basés sur les paramètres financiers configurés pour chaque automatisation et le filtre de la période du tableau de bord.",
  "kpi.header.roi.tooltip":
    "Répond à la question combien de l'investissement initial a déjà été gagnée ? Les résultats sont calculés en se basant sur les paramètres financiers configurés pour chaque automatisation et le filtre de la période du tableau de bord.",
  "kpi.header.breakEven.tooltip.nodata":
    "En attente de plus d'exécutions pour estimer le point d'amortissement.",
  "kpi.header.roi.tooltip.nodata":
    "En attente de plus d'exécutions pour calculer la rentabilité.",
  "dashboard.graph.treatedItems": "Eléments traités",
  "dashboard.graph.pendingItems": "Eléments en attente",
  "dashboard.graph.exceptionItems": " Eléments en exception",
  "dashboard.graph.totalItems": "Tous les éléments",
  "Submitting...": "Soumission...",
  "resource.management.deleteResourceActionMessage": "Les éléments sélectionnés auront leur visibilité modifiée en cachée ou visible.",
  "NOT VALIDATED": "NON VALIDÉE",
  "tooltip.action.hide": "Masquer",
  "tooltip.action.show": "Afficher",
  "tooltip.action.showHiddenItems": "Afficher les éléments masqués",
  "exception.analytics.typeNotFound": "Type d'exception non spécifié",
  "orchestrator.management.formControl.sessionNumber":
     "Nombre de sessions autorisées",
  "profile.user.general.title": "Informations générales",
  "profile.user.general.security": "Sécurité",
  "rpa.export.rpa": "RPA",
  "rpa.export.qualification": "QUALIFICATION",
  "rpa.create.businessApplication": "Listez les applications ici",
  "add.schedule.same.date.constraint": "Un programme avec la même date de début existe déjà",
  "reporting.date.errorFuture":
    "La valeur doit être supérieure à {{dateValue}}",
  "reporting.date.errorPast":
    "La valeur devrait être inférieure à {{dateValue}}",
  "user.add.admin": "Utilisateur administrateur",
  "Items status by process": "Statut des éléments par automatisation",
  "Current Period": "Période actuelle",
  "Average Handling Time": "Temps de traitement moyen",
  "Last Period": "Période précédente",
  "use intervals": "Utiliser des intervalles",
  "use cumulative": "Utilisation cumulative",
  "please enter at least one email to enable email notification":
    "Veuillez saisir au moins une adresse e-mail pour activer la notification par e-mail.",
  "please enter at least one phone number to activate the sms notification":
    "Veuillez saisir au moins un numéro de téléphone pour activer la notification par sms.",
  Exception: "Exception",
  Success: "Succès",
  "The calculation of the KPIs will be based on an interval":
    "Le calcul des KPI sera basé sur un intervalle.",
  "Calculation of the KPIs for the whole time":
    "Calcul des KPI pour l'ensemble du temps.",
  "Receive alerts from the following date":
    "Recevez des notifications à partir de la date suivante.",
  "Stop receiving alerts from the following date":
    "Ne plus recevoir de notifications à partir de la date suivante.",
  "dashboard.tooltip.timesaved":
    "Ce graphe présente la répartition du temps économisé (en heures) sur la période sélectionnée dans le filtre de la période du tableau de bord. Les résultats sont calculés en fonction des paramètres financiers configurés pour chaque automatisation et du filtre des automatisations du tableau de bord.",
  "dashboard.tooltip.processOverview":
    "Ce graphe montre la distribution du temps d'exécution de chaque automatisation par rapport à tous les automatisations. Les résultats sont basés sur le filtre de la période du tableau de bord.",
  "dashboard.tooltip.itemsProcess":
    "Ce graphe montre la répartition du statut des éléments pour les principaux automatisation. Les résultats sont basés sur le filtre de la période du tableau de bord.",
  "dashboard.tooltip.executionsProcess":
    "Ce graphe présente la répartition des exécutions sur la période sélectionnée dans le filtre de la période du tableau de bord.",
  "dashboard.tooltip.averageTime":
    "Le temps de traitement moyen (AHT) est le temps moyen qu'il a fallu pour exécuter un élément dans cette automatisation pendant la période sélectionnée dans le filtre de la période.",
  STOPPED: "Arrêté",
  COMPLETED: "Complété",
  DEBUGGING: "Débogage",
  ARCHIVED: "Archivé",
  STOPPING: "Arrêt",
  WARNING: "Avertissement",
  "status.running.with.warning": "En cours avec un avertissement",
  "process.history.duration": "Durée d'exécution",
  "process.history.completedItems": "Eléments en succès",
  "process.history.exceptionItems": "Eléments en exceptions",
  "process.history.resource": "Ressource",
  AUTOMATIC: "Automatique",
  MANUAL: "Manuel",

  "Process data mapping": "Correspondance de données",
  "Are you sure you want to update the mapping ?":
    "Êtes-vous sûr de vouloir mettre à jour le mapping ?",
  "no.data-mapping.message":
    "Le mapping ne peut être effectué qu'après le traitement du premier élément d'automatisation.",
  Data: "Donnée",
  "New name": "Nouveau nom",
  Display: "Afficher",
  "display.in.items.list": "Afficher dans la liste des éléments",
  "Orchestrator configuration": "Configuration de l'orchestrateur",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
    "SmartRoby ajoutera des colonnes et créera des vues et des déclencheurs sur la base de données de votre orchestrateur.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
    "Initialiser les connecteurs pour synchroniser les données entre votre orchestrateur et SmartRoby.",
  "sync.Execute": "Exécuter",
  "sync.Initialize": "Initialiser",
  "Database configuration": "Configuration de la base de données",
  "Orchestrator/SmartRoby synchronization":
    "Synchronisation Orchestrator/SmartRoby",
  "Orchestrator status": "Statut de l'Orchestrateur",
  "sync.Refresh": "Actualiser",
  "sync.General": "Général",
  "Database connection": "Connexion à la base de données",
  "Synchronization engine": "Moteur de synchronisation",
  "sync.Synchronization": "Synchronisation",
  "sync.process": "Automatisation",
  "sync.execution": "Exécutions",
  "sync.resource": "Ressources",
  "sync.schedule": "Plannings",
  "sync.work_queue_item": "Eléments",
  "Number of items with Exceptions":
    "Nombre d'éléments en exceptions",
  "sync.deleted_record": "Suppression des enregistrements",
  "sync.license": "Licences",
  "sr.sync.license": "Licences SmartRoby",
  "resource.management.hide.confirmMsg":
    "Êtes-vous sûr de vouloir masquer ce ressource?",
  "resource.management.show.confirmMsg":
    "Êtes-vous sûr de vouloir afficher ce ressource?",
  "filter.clear": "Videz les filtres",
  Reasons: "Raisons",
  allowedSessionsExceededForAnOrchestrator:
      "{{parallelExecutions}} tâches parallèles ont été trouvées alors que l'orchestrateur '{{OrhcestratorName}}' n'autorise que {{allowedSessions}} session(s).",
  "List of conflicts for": "Liste des conflits pour ",
  concurrentExecutionInTheSameResourceMessage:
    "Plus d'une exécution a été trouvée dans le ressource : '{{resourceName}}'. Les autres exécutions planifiées commenceront après l'achèvement de la première selon cet ordre : ",
  "Number of pending items": "Nombre d'éléments en attente",
  "kpi.parameter.allowScheduling": "Peut être programmé",
  "kpi.parameter.allowManual": "Peut être lancé manuellement",
  "kpi.parameter.retryable": "Les exceptions peuvent être relancées",
  "kpi.parameter.retryableWithData": "Les exceptions peuvent être relancées avec des données modifiées",
  "kpiParam.reset.success": "Paramètres réinitialisés avec succès",
  "kpiParam.reset.error": "Échec de la réinitialisation des paramètres de KPI",
  "kpiParam.schedule.warning":
    "Cette automatisation a déjà des programmes. Si vous poursuivez, ils seront tous supprimés.",
  "Warnings Management": "Gestion des conflits",
  "processStore.year": "an",
  "processStore.years": "ans",
  "can.be.edited.for.retry": "Peut être modifié pour un nouvel essai",
  retry: "Relancer",
  "retry-dialog.title.one-item": "Êtes-vous sûr de vouloir relancer cette exception?",
  "retry-dialog.title.multiple-items":
    "Êtes-vous sûr de vouloir relancer {{count}} exceptions?",
  "retry-dialog.text.one-item":
    "L'élément sera relancé lors de la prochaine exécution d'automatisation.",
  "retry-dialog.text.multiple-items":
    "Les éléments seront relancés lors de la prochaine exécution de leurs automatisation.",
  "retry-dialog.pending-alert":
    "Une relance en attente pour cet élément existe déjà",
  "retry-dialog.pending-alert-multiple": "Les tentatives en attente pour ces éléments existent déjà",
  "retry-dialog.message.warning.pending": " (Les éléments qui ont déjà une tentative en attente ne feront pas l'objet d'une autre tentative)",
  "retry-dialog.not-retryable": "Vous avez selectionné des exceptions qui appartiennent à des automatisations dont les exceptions ne peuvent pas être relancées",
  "retry-dialog.radio.same-data": "Relancer avec les mêmes données",
  "retry-dialog.radio.changed-data": "Relancer avec les donnés modifiées",
  "retry-dialog.table.name": "Nom",
  "retry-dialog.table.old-value": "Ancienne valeur",
  "retry-dialog.table.new-value": "Nouvelle valeur",
  "retry-dialog.message.success.one-item":
    "La relance de l'élément a été créé avec succès",
  "retry-dialog.message.success.multiple-items":
    "{{count}} relances des éléments ont étés créés avec succès",
  "retry-dialog.message.error.one-item":
    "Échec de la création de la relance de l'élément",
  "retry-dialog.message.error.multiple-items":
    "Échec de la création des relances des éléments",
  "restriction.form.hour.maxValue.error":
    "La valeur doit être inférieure ou égale à 23.",
  "restriction.form.min.sec.maxValue.error":
    "La valeur doit être inférieure ou égale à 59.",
  attempt: "Tentative",
  "attempt.chip.tooltip": "Ceci est la {{attempt}} tentative de cet élément",
  "exception-workflow.status.ready": "Non traitée",
  "exception-workflow.status.taken": "Assigné ",
  "exception-workflow.status.done": "Traitée",
  "exception-workflow.action.take": "Prendre",

  "reporting.processExecutions": "Total des exécutions",
  "reporting.itemsProcessed": "Total des éléments",
  "reporting.itemsCompleted": "Eléments complétés avec succès",
  "reporting.successRate": "Taux de succès",
  "reporting.exceptionRate": "Taux d'exceptions",
  "reporting.itemsBusinessExceptions": "Eléments en exception métier",
  "reporting.itemsSystemExceptions": "Eléments en exception système",

  "exception-workflow.action.assign": "Assigner",
  "exception-workflow.action.release": "Libérer",
  "exception-workflow.action.reopen": "Réouvrir",
  "exception-workflow.action.markAsDone": "Terminer",
  "exception-workflow.log.take":
    "Pris par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign":
    "Assigné à <b>{{targetUser.firstname}} {{targetUser.lastname}}</b> par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.markAsDone":
    "Terminé par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.release":
    "Libéré par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.reopen":
    "Réouvert par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.snackbar.success": "L'action a été effectuée avec succès",
  "exception-workflow.snackbar.error:": "Échec de l'exécution de l'action",
  "exception-workflow.confirm.take":
    "Êtes-vous sûr de vouloir prendre cette exception?",
  "exception-workflow.confirm.assign":
    "Êtes-vous sûr de vouloir assigner cette exception à {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.markAsDone":
    "Êtes-vous sûr de vouloir terminer cette exception?",
  "exception-workflow.confirm.release":
    "Êtes-vous sûr de vouloir libérer cette exception?",
  "exception-workflow.confirm.reopen":
    "Êtes-vous sûr de vouloir réouvrir cette exception?",
  "exception-workflow.open": "Flux de travail",
  "exception-workflow.open-bulk": "Flux de travail",
  "exception-workflow.no-bulk-action-possible":
    "Aucune action n'est possible pour la combinaison d'exceptions sélectionnée",
  "exception-workflow.selected-message":
    "{{count}} exceptions sont sélectionnées",
  "exception-workflow.confirm.bulk-take":
    "Êtes-vous sûr de vouloir prendre {{count}} exceptions?",
  "exception-workflow.confirm.bulk-assign":
    "Êtes-vous sûr de vouloir assigner {{count}} exceptions à {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.bulk-markAsDone":
    "Êtes-vous sûr de vouloir terminer {{count}} exceptions?",
  "exception-workflow.confirm.bulk-release":
    "Êtes-vous sûr de vouloir libérer {{count}} exceptions?",
  "exception-workflow.confirm.bulk-reopen":
    "Êtes-vous sûr de vouloir réouvrir {{count}} exceptions?",
  "exception-workflow.choose-user": "Choisir un utilisateur",
  "exception-workflow.snackbar.success.take":
    "L'exception a été prise avec succès",
  "exception-workflow.snackbar.success.assign":
    "L'exception a été assignée avec succès",
  "exception-workflow.snackbar.success.assign.notify":
    "Le destinataire a été notifié",
  "exception-workflow.snackbar.success.release":
    "L'exception a été libérée avec succès",
  "exception-workflow.snackbar.success.markAsDone":
    "L'exception a été terminée avec succès",
  "exception-workflow.snackbar.success.reopen":
    "L'exception a été réouverte avec succès",
  "exception-workflow.snackbar.success.bulkTake":
    "Les exceptions ont été prises avec succès",
  "exception-workflow.snackbar.success.bulkAssign":
    "Les exceptions ont été assignées avec succès",
  "exception-workflow.snackbar.success.bulkRelease":
    "Les exceptions ont été libérées avec succès",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
    "Les exceptions ont été terminées avec succès",
  "exception-workflow.snackbar.success.bulkReopen":
    "Les exceptions ont été réouvertes avec succès",
  Details: "Détails",
  "processe.schedules.onDay.first": " - Au {{startDay}}er",
  "processe.schedules.onDay.second": " - Au {{startDay}}ème",
  "processe.schedules.onDay.third": " - Au {{startDay}}ème",
  "processe.schedules.onDay": " - Au {{startDay}}ème",
  // error 404
  "Go to the home page":
    "Aller à la page d'accueil",
  "Sorry the page you are looking for could not be found":
    "Désolé, la page que vous recherchez n'a pas pu être trouvée",
  "orchestrator.license.originalId": "ID de licence originale",
  "orchestrator.license.starts": "Commence",
  "orchestrator.license.expires": "Expire",
  "orchestrator.license.installedOn": "Installé",
  "orchestrator.license.maxConcurrentSessions": "Nombre des sessions",
  "orchestrator.license.status": "Statut",
  "orchestrator.license.status.ACTIVE": "Active",
  "orchestrator.license.status.EXPIRED": "Expirée",
  "orchestrator.license.status.NOT_YET_ACTIVE": "Pas encore active",
  "A license is one month away from expiry": "Une licence expire dans un mois",
  "A license is two months away from expiry": "Une licence expire dans deux mois",
  "tooltip.action.license": "Licences",
  "Orchestrator licenses": "Licences d'orchestrateur",
  "process.laststage": "DERNIÈRE PHASE: {{lastStage}}",
  "analytics.preset.button.label": "Préréglages",
  "analytics.preset.load": "Charger un préréglage",
  "analytics.preset.create": "Créer un préréglage",
  "analytics.preset.noPresets": "Pas de préréglage enregistré",
  "analytics.preset.dialog.label": "Nouveau préréglage",
  "analytics.preset.dialog.field.label": "Nom du préréglage",
  "analytics.preset.dialog.field.submit": "Créer",
  "analytics.preset.dialog.delete.label": "Supprimer préréglage",
  "analytics.preset.dialog.delete.text": "Êtes vous sûr de supprimer ce préréglage ?",
  "analytics.preset.dialog.delete.submit": "Supprimer préréglage",
  "resource.tooltip.hide": "Êtes-vous sûr de vouloir masquer {{count}} ressource(s)",
  "resource.tooltip.unhide": "Êtes-vous sûr de vouloir afficher {{count}} ressource(s)",
  "export.confirmation.radio.grouped": "Exporter avec les détails de l'article regroupés.",
  "export.confirmation.radio.columns": "Exporter avec les détails de l'article dans les colonnes.",
  "export.confiramtion.warnning": "Pour activer cette option, vous devez sélectionner une automatisation.",
  FINISHED: "TERMINÉ",
  EXPORTING: "EXPORTATION",
  COLLECTING: "COLLECTE",
  "exporting.title": "Fichiers d'exportation",
  "exporting.no.data": "Aucun fichier à télécharger",
  "exporting.btn.download.all": "Télécharger tous",
  "exporting.btn.download": "Télécharger",
  "exporting.btn.delete.all": "Supprimer tous",
  Executions: "Exécutions",
  "routes.orchestrators.executions": "Exécutions",
  "no.executions.message": "Aucune exécution trouvée",
  Resource: "Ressource",
  Trigger: "Déclencheur",
  MANUALLY: "Manuellement",
  SCHEDULED: "Programmé",
  KEEP: "Garder dans la file SR",
  DELETE: "Supprimer de la file SR",
  Dates: "Dates",
  "Launching date": "Date de lancement",
  "Launching date (Descending)": "Date de lancement (décroissant)",
  "Launching date (Ascending)": "Date de lancement (croissant)",
  "SR Queues": "Files SR",
  "queue.management.choose.resource": "Certaines planifications ne sont affectées à aucun ressource, lors de leur migration vers l'orchestrateur d'origine,Elles seront affectées à :",
  "queue.management.status": "Statut",
  "queue.management.name": "Nom",
  "queue.management.creation-date": "Date de création",
  "queue.management.active-since": "Active depuis",
  "queue.management.nb-licences": "Nombre de licences",
  "queue.management.orchestrator": "Orchestrateur",
  "queue.management.search": "Chercher",
  "queue.management.new": "Créer une file SR",
  "queue.management.add": "Ajouter la file SR",
  "queue.management.delete.confirm-msg": "Voulez-vous vraiment supprimer la file SR sélectionnée ?",
  "queue.management.bulk-delete.confirm-msg": "Voulez-vous vraiment supprimer les files SR sélectionnées ?",
  "queue.management.enable.confirm-msg": "Voulez-vous vraiment activer cette file SR?",
  "queue.management.enable.confirm": "Activer la file SR",
  "queue.management.disable.confirm-msg": "Voulez-vous vraiment désactiver cette file SR?",
  "queue.management.disable.confirm": "Désactiver la file SR",
  "queue.management.section.info": "Informations générales",
  "queue.management.section.processes": "Automatisation",
  "queue.management.section.resources": "Ressources",
  "queue.management.form.save.success": "La file SR a été créée avec succès",
  "queue.management.form.update.success": "La file SR a été mise à jour avec succès",
  "queue.management.form.save.error": "Échec de la création de la file SR",
  "queue.management.form.update.error": "Échec de la mise à jour de la file SR",
  "queue.management.form.validation.required": "Ce champ est requis",
  "queue.management.form.validation.negative-nb-licences": "Le nombre de licences ne peut pas être négatif",
  "queue.management.update": "Mettre à jour la file SR",
  "queue.management.save.confirm-msg": "Voulez-vous vraiment créer cette file SR ?",
  "queue.management.update.confirm-msg": "Voulez-vous vraiment mettre à jour cette file SR ?",
  "queue.management.reset.confirm-msg": "Voulez-vous vraiment supprimer toutes les exécutions manquées de cette file SR ?",
  "restriction.form.missedExecutionsPolicy": "Politique relative aux exécutions manquées",
  "restriction.userGuide.missedExecutionsPolicy": "Spécifie si une exécution manquée doit être conservée ou supprimée de la file SR.",
  "queue.management.assign.process.part1": "Vous êtes sur le point de déléguer l'exécution de {{this_process}} aux files SR. Pour garantir les performances attendues, veuillez vous abstenir de planifier et de lancer des exécutions en dehors de SmartRoby.",
  "queue.management.assign.process.part2": "Une planification a été trouvée pour {{this_process}} {{at_its}} orchestrateur d'origine, souhaitez-vous la migrer pour l'utiliser avec les files SR ?",
  "queue.management.assign.process.part2_plural": "{{nbSchedules}} planifications ont été trouvées pour {{this_process}} {{at_its}} orchestrateur d'origine, souhaitez-vous les migrer pour les utiliser avec les files SR ?",
  "queue.management.unassign.process.part1": "Vous êtes sur le point d'annuler la délégation de l'exécution de {{this_process}} depuis les files SR. À l'avenir, toutes les exécutions et planifications peuvent provenir de SmartRoby ou de votre orchestrateur d'origine.",
  "queue.management.unassign.process.part2": "Une planification de files SR a été trouvée, souhaitez-vous la migrer vers l'orchestrateur d'origine ?",
  "queue.management.unassign.process.part2_plural": "{{nbSchedules}} planifications de files SR ont été trouvées, souhaitez-vous les migrer vers l'orchestrateur d'origine ?",
  thisProcess: "cette automatisation",
  thisProcess_plural: "ces automatisation",
  atIts: "sur son",
  atIts_plural: "sur leur",
  some: "Des",
  "resource-selector.available-resources": "Ressources disponibles",
  "resource-selector.assigned-resources": "Ressources assignés",
  "queue.management.form.validation.nb-licences-bigger-than-remaining": "Il reste {{count}} licences dans l'orchestrateur sélectionné",
  "queue.management.no-data": "Aucune file SR trouvée",
  "queue.management.open-queue": "Ouvrir dans la file SR",
  "queue.management.reset-queue": "Réinitialiser la file SR",
  "execution.manager.title": "File SR",
  "queue reseted successfully": "La file SR a été réinitialisée avec succès.",
  "error while reseting queue": "Échec de la réinitialisation de la file SR.",
  "Add SR Queue": "Ajouter une file SR",
  "Edit SR Queue": "Modifier une file SR",
  "Delete SR Queue": "Supprimer une file SR",
  "process.assigned.srqueue.tooltip": "Cette automatisation est affecté à une file SR.",
  "process.not.assigned.srqueue.tooltip": "Cette automatisation n'est assigné à aucune file d'attente SR",
  "number of runing executions singular": "Une exécution est déjà en cours",
  "number of runing executions plural": "Certaines exécutions sont déjà en cours",
  "not enough licenses":
    ", vous n'avez pas de licences disponibles. Veuillez contacter votre administrateur RPA.",
  "check.connectivity":
    "Veuillez vérifier la connectivité au serveur d'applications {{serverName}}, il semble que la connexion ait été perdue.",
  "deleted by sr policy": "Une planification est supprimée par la politique des files SR",
  "execution is deleted": "Une exécution de la file SR a été supprimée manuellement",
  "execution is missed": "Une exécution de la file SR est manquante",
  "missed raison": "Manquée à cause de",
  UNAVAILABLE_ROBOTS: "Ressources indisponibles",
  NOT_ENOUGH_LICENCES: "Pas assez de licences",
  BP_UNREACHABLE: "BP inaccessible",
  BP_UNRESPONSIVE: "BP ne répond pas",
  "cant perform multi queue migration": "Certaines planifications de files SR ont été trouvées pour les files sélectionnées. Elles ne peuvent pas être migrées vers l'orchestrateur d'origine.",
  "something went wrong": "Une erreur s'est produite lors du traitement de votre requête , veuillez réessayer.",
  Continue: "Continuer",
  "resource.formControl.succesUpdate": "Les informations du ressource ont été mises à jour avec succès",
  "Something.Wrong": "Quelque chose ne va pas",
  "export.successful.notification": "Votre fichier a été ajouté à la section des fichiers d'exportation et est maintenant prêt à être consulté et téléchargé. L'ajout a réussi.",
  revoke: "révoquer",
  "usersSelector.availableUsers": "Utilisateurs disponibles",
  "usersSelector.assignedUsers": "Utilisateurs assignés",
  "usersSelector.availableAdmins": "Administrateurs disponibles",
  "usersSelector.assignedAdmins": "Administrateurs assignés",
  "custom.kpi.item": "Eléments",
  "custom.kpi.process": "Automatisation",
  "custom.kpi.processExecution": "Exécutions",
  "custom.kpi.pending": "En attente",
  "custom.kpi.completed": "Terminés avec succès",
  "custom.kpi.completedWithException": "Terminés avec une exception",
  "custom.kpi.completedWithBusinessException": "Terminés avec une exception business",
  "custom.kpi.completedWithIntSysException": "Terminés avec une exception système/interne",
  "custom.kpi.executionTimeHours": "Temps d'exécution (heures)",
  "custom.kpi.timeSavedHours": "Temps économisé (heures)",
  "reporting.createKpi": "Créer un nouveau KPI",
  "custom.kpi.dialog.create.label": "Nouveau KPI",
  "custom.kpi.dialog.field.name": "Nom du KPI",
  "custom.kpi.dialog.field.element": "Pour chaque",
  "custom.kpi.dialog.field.detail": "Détail",
  "custom.kpi.dialog.field.allocation": "Allouer",
  "custom.kpi.dialog.field.unitofmesure": "Unité de mesure",
  "custom.kpi.formControl.required": "Champ requis",
  "custom.kpi.formControl.pattern": "Veuillez saisir un nombre",
  "custom.kpi.dialog.create.field.submit": "CRÉER LE KPI",
  "custom.kpi.dialog.delete.label": "Supprimer le KPI",
  "custom.kpi.dialog.delete.text": "Êtes-vous sûr de vouloir supprimer ce KPI ?",
  "custom.kpi.dialog.delete.submit": "Supprimer le KPI",
  "custom.kpi.dialog.create.label.edit": "Modifier le KPI",
  "custom.kpi.dialog.create.field.edit": "MODIFIER LE KPI",
  "fleets.fleet": "Groupe",
  "no.fleet.message": "Aucun groupe pour le moment",
  "instance.owner": "Propriétaire de l'instance",
  "instance.owner.confirmMsg": "Êtes-vous sûr de vouloir procéder à l'attribution d'un nouveau propriétaire de l'instance ?/nCette action entraînera la perte du statut de propriétaire de l'instance pour votre groupe.",
  "instance.owner.can.not.be.disabled": "Le propriétaire de l'instance ne peut pas être désactivé",
  "orchestrator.management.formControl.isPrivate": "Orchestrator privé",
  "orchestrator.management.formControl.automatic.tags.creation": "Création automatique de tags",
  "orchestrator.management.formControl.automatic.tags.creation.information": "L'activation de cette option génère automatiquement des tags dans Orchestrator pour chaque groupe des automatisations dans Blue Prism. Tous les automatismes au sein d'un groupe seront assignés à la balise correspondante",
  private: "Privée",
  "fleet.with.no.users": "Ce groupe ne possède aucun utilisateur, impossible de le rendre propriétaire de l'instance",
  "fleet.with.no.admins": "Ce groupe ne possède aucun administrateur, impossible de le désigner comme propriétaire de l'instance",
  "user.fleet.disabled.message": "Le groupe de cet utilisateur est désactivé, impossible d'activer cet utilisateur.",
  "instance.owner.only.active.admin.can.not.be.disabled": "Le seul administrateur actif du propriétaire de l'instance ne peut pas être désactivé",
  "search.available.processes": "rechercher les automatisations disponibles",
  "search.assigned.processes": "rechercher les automatisations attribués",
  "search.available.tags": "rechercher les tags disponibles",
  "search.available.users": "rechercher les utilisateurs disponibles",
  "search.assigned.users": "rechercher les utilisateurs attribués",
  "search.available.resources": "rechercher les ressources disponibles",
  "search.assigned.resources": "rechercher les ressources attribués",
  "drag.and.drop.here": "Glisser-déposer ici",
  Tags: "Tags",
  "tag.management.add.button": "Créer un tag",
  "tag.management.name": "Nom",
  "tag.management.formControl.desc": "description",
  "tag.status": "Statut",
  "tag.name": "Nom du Tag",
  "tag.description": "Description du Tag",
  "tag.icon": "Icone",
  "tag.management.hide.confirmMsg": "Êtes-vous sûr de vouloir supprimer le tag ? Toutes les données seront définitivement perdues.",
  "no.tags.message": "Aucun tag pour le moment",
  "tag.management.add": "ajouter",
  "tag.management.update": "modifier",
  "tag.add.previous": "Précédent",
  "tag.management.save.confirm-msg": "Voulez-vous vraiment créer ce tag ?",
  "tag.management.update.confirm-msg": "Voulez-vous vraiment mettre à jour ce tag ?",
  "tag.form.addSuccess": "tag ajouté avec succès",
  "tag.deleted.success": "Tag(s) supprimé avec succès.",
  "tag.form.next": "suivant",
  "tag.management.form.save.success": "Le tag a été créée avec succès",
  "tag.management.form.update.success": "Le tag a été mise à jour avec succès",
  "tag.management.form.save.error": "Échec de la création du tag",
  "tag.management.form.update.error": "Échec de la mise à jour du tag",
  "tag.form.name": "Nom du tag",
  "tag.form.description": "Description du tag",
  "tooltip.action.archive": "Archivé",
  "archive.confirm.msg": "Le(s) tag(s) sélectionné(s) sera/seront archivé(s)",
  "tag.management.btn.confirm": "Archiver",
  "tag.archived.success": "Tag(s) archivé avec succès",
  "tag.management.deleteTagActionMessage": "Supprimer le(s) tag(s) sélectionné(s)",
  "tag.management.archived": "Archivé",
  "wrong.file.type": "Type de fichier invalide",
  "invalid license file": "Fichier de licence invalide",
  "invalid signature": "Signature invalide",
  "empty file": "Fichier vide",
  "wrong file extension": "Extension de fichier invalide",
  "missing file": "Fichier manquant",
  "the submitted license file is invalid": "Le fichier de licence soumis est invalide",
  "the signature of the license is invalid": "La signature de la licence est invalide",
  "the submitted file is empty": "Le fichier soumis est vide",
  "we only accept .lic files": "Nous n'acceptons que les fichiers .lic",
  "please upload a license file": "Veuillez télécharger un fichier de licence",
  activation: "Activation",
  license: "Licence",
  "import your license here": "Importer votre licence ici...",
  activate: "Activer",
  "licence.id": "Licence ID",
  "licence.licensee": "Licencié",
  "licence.start.at": "Débute le",
  "licence.end.at": "Se termine le",
  "licence.validation.valid": "Votre licence est valide.",
  "licence.validation.expire": "Votre licence a expiré. Veuillez la renouveler.",
  "licence.goback.btn": "Retour à la page d'accueil",
  "productivity.hrs": "Productivité",
  Occupancy: "Occupation (%)",
  "robots.occupancy": "Occupation",
  "dashboard.monitoring.mode": "Mode de surveillance",
  "HRS Saved": "HRS Enregistrées",
  "Resource Productivity": "Productivité des ressources",
  "Process Activity": "Activité d'automatisation",
  "Working Time (HRS)": "Temps de travail (HRS)",
  "Processed Items": "Éléments traités",
  "HRS Runtime": "Temps d'exécution (HRS)",
  mins: "mins",
  "recently.used.processes": "Automatisation les plus utilisés",
  "process.total.cost": "Coût total d'automatisation",
  "scheduler.is.required": "Le programme est obligatoire",
  "filter by": "Filtre par",
  showing: "Affichage de",
  to: "Á",
  from: "De",
  entries: "entrées",
  "Data mapping": "Mapping de données",
  Temps: "Temps",
  "server Type": "Type de serveur",
  "tooltip.action.close": "Fermer",
  "try.active.alert": "La automatisation {{processName}} est masqué, pour activer cette notification veuillez l'afficher.",
  "process.start.restriction": "La automatisation que vous lancez n'est pas censé fonctionner aujourd'hui en fonction de ses SLA, êtes-vous sûr de vouloir continuer ?",
  "process.start.require.input": "Cette automatisation nécessite un démarrage avec une entrée",
  "process.inputs.button.reset": "Réinitialiser",
  "process.inputs.button.save": "Ajouter",
  "process.inputs.save.blank.values.title": "Certains paramètres avec des valeurs d'entrée vides ont été détectés.",
  "process.inputs.save.blank.values.message": "Êtes-vous sûr de vouloir démarrer ces sessions avec les valeurs fournies ?",
  Now: "Maintenant",
  "Automation Impact": "Impact des automatisations",
  ERRORS_NO_DATA_AVAILABLE: "Pas de données disponibles",
  "queue.management.resource.has.pendingExecutions": "Vous avez {{count}} exécutions en attente en utilisant ce ressource, veuillez les supprimer si vous souhaitez poursuivre cette opération.",
  "queue.management.resource.has.scheduled.pendingExecutions": "{{count}} exécutions sont programmées, veuillez vérifier les plannings attribués à ce ressource.",
  "occupancy.waiting.more": "En attente de plus d'exécutions pour calculer l'occupation du ressource",
  "select.process": "Sélectionnez une automatisation",
  "changes.saved.successfully": "Modifications enregistrées avec succès",
  "changes.saving.failed": "Échec de l'enregistrement des modifications",
  "process.owner": "Propriétaire d'automatisation",
  "end date cannot be earlier than the start date": "La date de fin ne peut pas être antérieure à la date de début",
  "end date cannot be in the future": "La date de fin ne peut pas être dans le futur",
  "start date cannot be newer than the end date": "La date de début ne peut pas être plus récente que la date de fin",
  "Start date cannot be in the future": "La date de début ne peut pas être dans le futur",
  "no.period.message": "Pas de période trouvée",
  "no.status.message": "Aucun statut trouvé",
  "pending.executions.warning": "Attention : Cette automatisation a des exécutions en attente. Les supprimer maintenant les effacera toutes.",
  "calendar.formControl.saveRiskSuccess": "Marge de risque ajoutée avec succès",
  "export.confirm.msg.delete.all": "Êtes vous sûr de vouloir supprimer tous les fichiers ?",
  "export.confirm.msg.delete.selected": "Êtes vous sûr de vouloir supprimer les fichiers sélectionnés ?",
  "export.confirm.msg.delete.one": "Êtes vous sûr de vouloir supprimer ce fichier ?",
  on: "En",
  language: "Langue",
  "Choose.the.checking.frequency": "Ajouter la fréquence de vérification",
  "resources.show.hidden": "Afficher les ressources cachés",
  "resources.hide.hidden": "Masquer les ressources cachées",
  "exceptions.show.hidden": "Voir exceptions cachées",
  "user.logo.allowed.extensions": "Nous n'acceptons que les extensions jpg, jpeg, png et bmp",
  "allowed.extensions": "Nous n'acceptons que les extensions {{extensions}}",
  "max.size.allowed": "La taille de fichier maximale autorisée est de {{size}} Mo",
  "items.pending.locked": "Cet élément est en cours de traitement",
  "items.pending.deferred": "Cet élément a été reporté au {{date}}",
  "export.limit": "Il est possible d'exporter uniquement les 100 000 premiers enregistrements, mais pour des résultats plus précis, vous avez la possibilité de préciser vos critères de recherche.",
  "queue.create.update.restriction": "Chaque file d'attente doit avoir au moins 1 licence, 1 automatisation assigné et 1 ressource assigné.",
  "custom.kpi.dialog.field.type": "Type de KPI",
  FINANCIAL_KPI: "KPI Financier",
  PRODUCTIVITY_KPI: "KPI de productivité",
  EFFICIENCY_KPI: "Efficacité KPI",
  "reporting.financialImpact": "Economies",
  "reporting.resourceProductivity": "Productivité des ressources",
  "reporting.avgHandlingTime": "Temps moyen de traitement",
  "max.datamap.reached": "Vous pouvez choisir seulement 3 champs à afficher dans la liste des éléments",
  "retry.mark.done": "Réessayer et marquer comme traitée",
  "fleet.management.formLabel.currency": "Devise",
  "tooltip.visit.link": "Visiter le lien",
  "tooltip.edit.link": " Modifier le lien ",
  "tooltip.delete.link": "Supprimer le lien",
  "no.link.message": "Aucun lien trouvé",
  "confirm.delete.link": "Êtes-vous sûr de vouloir supprimer ce lien ?",
  "add.link": "Insérer un nouveau lien",
  "create.link": "Créer lien",
  "confirm.add.link": "Êtes-vous sûr de vouloir créer ce lien ?",
  "insert.link": "Insérer",
  "link.validate.url": "Veuillez saisir une URL valide",
  "link.hypertextUrl": "Lien hypertexte du document externe",
  "link.name": "Nom",
  "link.url": "Lien",
  "link.description": "Description",
  "link.delete.success": "Lien supprimé avec succès",
  "link.add.success": "Lien créé avec succès",
  "link.update.success": "Lien modifié avec succès",
  "link.add.failure": "La création du lien a échoué ! Veuillez réessayer plus tard",
  "link.update.failure": "Échec de la mise à jour du lien ! Veuillez réessayer plus tard",
  "link.tab": "documentation externe",
  "View external documentation": "Afficher la documentation externe",
  "Manage external documentation": "Gérer la documentation externe",
  "Description (ascending)": "Description (croissant)",
  "Description (descending)": "Description (décroissant)",
  "Name (ascending)": "Nom (croissant)",
  "Name (descending)": "Nom (décroissant)",
  "disconnection.period": "Pendant plus de (minutes)",
  "help.exception.type": "Veuillez choisir un type d'exception",
  "audit.object": "Objet",
  "audit.user": "Utilisateur",
  "audit.action": "Action",
  "audit.time.filter": "Filtre temporel",
  "audit.sort.by": "Trier par",
  Audit: "Trace d'audit",
  "audit.module": "Module",
  "audit.times.stamp": "Horodatage",
  "audit.search.logs": "Rechercher des journaux",
  "no.object.message": "Aucun objet trouvé",
  "no.user.message": "Aucun utilisateur trouvé",
  "no.module.message": "Aucun module trouvé",
  "no.action.message": "Aucune action trouvée",
  "no.time.filter.message": "Aucun filtre temporel trouvé",
  "no.sort.message": "Aucun tri trouvé",
  "audit.module.descending": "Module (Décroissant)",
  "audit.module.ascending": "Module (Croissant)",
  "audit.user.descending": "Utilisateur (Décroissant)",
  "audit.user.ascending": "Utilisateur (Croissant)",
  "fleet.user.delete.current": "Vous ne pouvez pas supprimer les groupes d'utilisateurs actifs ou d'utilisateurs avec des exceptions attribuées",
  "user.delete.current": "Vous ne pouvez pas supprimer l'utilisateur actif",
  "users.delete.has.exception": "Utilisateur(s) ont des exceptions associées",
  "Split equally": "Répartir uniformément",
  deletedSuccessfullySingle: "{{objectNature}} {{ObjectName}} a été supprimé avec succès.",
  deletedSuccessfullyDouble: "{{objectNature}} {{firstObjectName}} et {{secondObjectName}} ont été supprimés avec succès.",
  deletedSuccessfullyMultiple: "{{twoFirstObjectName}} et {{remainingCount}} autre(s) {{objectNatureRemaining}} ont été supprimés avec succès.",
  userSingle: "utilisateur",
  userPlural: "utilisateurs",
  groupSingle: "groupe",
  groupPlural: "groupes",
  tagSingle: "tag",
  tagPlural: "tags",
  orchestratorSingle: "orchestrateur",
  orchestratorPlural: "orchestrateurs",
  queueSingle: "file d'attente",
  queuePlural: "files d'attente",
  "time.zone": "Fuseau horaire",
  "profile.update-password.rewrite.email": "Réécrire l'e-mail",
  "profile.update-password.wrong.password": "Le mot de passe ne correspond pas",
  Preferences: "Préférences",
  "user.information": "Informations utilisateur",
  "change.your.password": "Changez votre mot de passe",
  "Tag already exist with the same name": "Tag existe déjà avec le même nom.",
  "title.confirm.message.delete": "Supprimer un groupe",
  "items.pending": "Eléments en attente",
  configuration: "Configuration",
  "field.is.required": "{{field}} est requis",
  "Setup Automatic Assignment": "Configuration de l'attribution automatique",
  "Assignement Rules": "Règles d'assignation",
  "Automatic Exception Assignment": "Attribution automatique des exceptions",
  "Rule Configuration": "Configuration de la règle",
  "Rule Name": "Nom de la règle",
  "When an exception occurs for": "Quand une exception se produit pour",
  "logical Connector": "Connecteur logique",
  "Item Data": "Données de l'élément",
  Condition: "Condition",
  Value: "Valeur",
  "Assign to": "Assigner à",
  "User List": "Liste des utilisateurs",
  "Group List": "Liste des groupes",
  "Alert on assignment": "Alerte lors de l'attribution",
  Summary: "Résumé",
  "New Rule": "Nouvelle règle",
  "Exception Type/Reason": "Type/Raison de l'exception",
  GREATER: "Est supérieur à",
  SMALLER: "Est inférieur à",
  CONTAINS: "Contient",
  "IS.EQUAL": "Est égal à",
  "rules.already.exists.with.processes": "Une règle existe déjà avec les mêmes automatisation. Voulez-vous supprimer l'ancienne règle ?",
  "confirm.delete.rule": "Êtes-vous sûr de vouloir supprimer cette règle ?",
  "confirm.disable.rule": "Êtes-vous sûr de vouloir désactiver cette règle ?",
  "confirm.enable.rule": "Êtes-vous sûr de vouloir activer cette règle ?",
  "When an exception occurs for details": "Utilisez ce paramètre pour spécifier les exceptions qui doivent être automatiquement assignées. Sélectionnez une automatisation et définissez les détails des exceptions que vous souhaitez assigner.",
  "Assign to details": "Ici, vous pouvez choisir d'attribuer les exceptions à un utilisateur spécifique ou à un groupe en fonction des règles que vous avez définies. De plus, décidez si vous souhaitez alerter la partie assignée concernant l'exception.",
  "synchronized.processes": "Automatisation à synchroniser",
  "max.processes.reached": "Nombre maximum des automatisations atteint",
  "process.in.orchestrator": "Automatisation dans l'orchestrateur",
  "process.synchronize": "Automatisation à synchroniser",
  "orchestrator.formControl.processes.saveSuccess": "Automatisation à synchroniser ajouté avec succès",
  "orchestrator.formControl.processes.updateSuccess": "Automatisation à synchroniser mis à jour avec succès",
  "task.initial.task": "Tâche initiale",
  "task.post.completion.delay": "Délai après achèvement",
  "task.remove": "Supprimer la tâche",
  "task.name": "Nom de la tâche",
  "task.description": "Description de la tâche",
  "task.on.complete": "À l'achèvement",
  "task.on.exception": "En cas d'exception",
  "task.add.session": "Ajouter une session",
  "task.delete.session": "Supprimer la session",
  "task.name.already.exist": "Le nom de la tâche existe déjà",
  "task(s)": "Tâche(s)",
  "add.task": "Ajouter une tâche",
  task: "Tâche",
  "no.tasks.yet": "Aucune tâche pour le moment",
  "no.task.message": "Aucune tâche trouvée",
  "task.stop": "Arrêter",
  "task.abort": "Abandonner",
  "task.required.process": "Veuillez choisir une automatisation",
  "task.required.first.session.process": "Veuillez choisir l' automatisation de la première session",
  "process.migration.not.possible": "Les automatisations suivantes sont déjà attribuées à d'autres SRQ. Une automatisation ne peut être attribuée qu'à un seul SRQ.",
  "see.all": "Voir tout",
  "sync.task_session": "Sessions de tâches",
  "sync.sch_task": "Tâches",
  "unique.schedule.name.exception": "Le nom du planning existe déjà",
  "awaiting.synchro": "En attente de synchronisation",
  "pendingCount.descending": "Éléments en attente (décroissant)",
  pendingCount: "Éléments en attente",
  "completedCount.descending": "Éléments en succès (décroissant)",
  completedCount: "Éléments en succès",
  "exceptionCount.descending": "Éléments en exception (décroissant)",
  exceptionCount: "Éléments en exception",
  "productivity.descending": "Productivité (décroissante)",
  "occupancy.ascending": "Occupation",
  "occupancy.descending": "Occupation (décroissante)",
  "processRestriction.recentActivity": "Activité récente",
  "processRestriction.recentActivity.descending": "Activité récente (décroissante)",
  "average.FTEs.saved": "Temps moyen économisé",
  "success.rate": "Taux de réussite",
  "average.success.rate": "Taux de réussite moyen",
  transactions: "Transactions",
  "total.transactions": "Total Transactions",
  "average.transactions": "Moyenne Transactions",
  min: "min",
  week: "semaine",
  "newDashboard.AllStats.title": "Statistiques cumulées",
  "newDashboard.CurrentStats.title": "Statistiques actuelles",
  "transactions.header.tooltip": "Nombre total de transactions",
  "successRate.header.tooltip": "Pourcentage de réussite des transactions",
  "Completed transactions": "Transactions terminées",
  "bots.header.tooltip": "Temps total de travail des robots",
  "FTESaved.header.tooltip": "Total des équivalents temps plein (ETP) économisés",
  "transactions.header.label": "Toutes les transactions",
  "items.header.label": "Eléments",
  "successRate.header.label": "Taux de réussite",
  "transactions.cardHeader": "Transactions",
  "AvgHandlingTime.cardHeader": "Temps de traitement moyen",
  "inProgress.transactions.status": "En cours",
  "completed.transactions.status": "Terminées",
  "all.processes": "Automatisation maîtres",
  "working.processes.status": "Planifié - En service",
  "notWorking.processes.status": "Planifié - avec des exécutions manquantes",
  "Processes.activity.tooltip": "Cet indicateur de performance (KPI) suit le nombre des automatisations en service selon le planning par rapport au nombre des automatisations planifiés mais ne progressant pas selon le calendrier prévu.",
  "Schedules health": "Etat planificateurs",
  "til.now": "Jusqu'à maintenant ",
  "Monitoring Dashboard": "Tableau de bord de surveillance",
  "System exceptions": "Exceptions système",
  "Business exceptions": "Exceptions métier",
  "reporting.exportCSV": "Exporter en CSV",
  "reporting.exportCSV.all": "Exporter toutes les pages en CSV",
  migrate: "Migrer",
  "do.not.migrate": "Ne pas migrer",
  "The name of the schedule": "Le nom du programme",
  "The first task executed by the schedule": "La première tâche exécuté par le programme",
  "The date and time of the first execution of the schedule": "Date et heure de la première exécution du programme",
  "The date and time after which the schedule will no longer be executed": "Date et heure après lesquels le programme ne sera plus exécuté",
  "The frequency at which the schedule will be executed (starting from the start date)": "La fréquence d’exécution du programme (partant de la date de début",
  "Set(s) of process sessions that are executed concurrently": "Ensemble(s) de sessions d'automatisation exécutés parallèlement",
  "The task to execute if this task is completed": "La tâche à exécuter si cette tache est complété",
  "The task to execute if this task ends on an exception": "La tâche à exécuter si cette tache fini sur une exception",
  "The process to be executed in this task session": "La automatisation à exécuter dans cette session",
  "The resource (robot on prod) on which the process will be executed": "La ressource (robot en prod) sur lequel l' automatisation sera exécuté",
  GROUP_ADDED: "Groupe ajouté",
  GROUP_MODIFIED: "Informations sur le groupe modifiées",
  GROUP_DELETED: "Groupe supprimé",
  USER_ADDED: "Utilisateur ajouté",
  USER_INFO_MODIFIED: "Informations utilisateur modifiées",
  USER_DELETED: "Utilisateur supprimé",
  USER_PERMISSIONS_MODIFIED: "Autorisations utilisateur modifiées",
  PROCESS_EXCEPTION_HIDDEN: "L'exception d'automatisation a été masquée",
  PROCESS_EXCEPTION_UNHIDDEN: "L'exception d'automatisation a été affichée",
  DEFAULT_ALERT_CREATED: "Notification par défaut créée",
  DEFAULT_ALERT_MODIFIED: "Notification par défaut modifiée",
  DEFAULT_ALERT_DELETED: "Notification par défaut supprimée",
  CUSTOM_ALERT_CREATED: "Notification personnalisée créée",
  CUSTOM_ALERT_MODIFIED: "Notification personnalisée modifiée",
  CUSTOM_ALERT_DELETED: "Notification personnalisée supprimée",
  TAG_CREATED: "Tag créée",
  TAG_DELETED: "Tag supprimée",
  TAG_ASSIGNED_PROCESSES: "Automatisation attribués à le tag",
  TAG_UNASSIGNED_PROCESSES: "Automatisation retirés du tag",
  USER_ASSIGNED_PROCESSES: "Automatisation attribués à l'utilisateur",
  USER_UNASSIGNED_PROCESSES: "Automatisation retirés de l'utilisateur",
  USER_ASSIGNED_TAGS: "Tags retirées de l'utilisateur",
  USER_UNASSIGNED_TAGS: "Tags retirées de l'utilisateur",
  PROCESS_SLA_UPDATED: "Automatisation SLA mis à jour",
  PROCESS_SETTINGS_UPDATED: "Paramètres d'automatisation mis à jour",
  PROCESS_DATA_MAPPING_UPDATED: "Paramètres de mappage de données mis à jour",
  RESOURCE_ASSIGNED_PROCESSES: "Automatisation affectés à la ressource",
  RESOURCE_UNASSIGNED_PROCESSES: "Automatisation retirés de la ressource",
  RESOURCE_INFORMATION_MODIFIED: "Informations sur la ressource modifiées",
  RESOURCE_HIDDEN: "Ressource était masquée",
  RESOURCE_UNHIDDEN: "Ressource a été affichée",
  COST_ALLOCATION_ADDED: "Coût ajouté",
  COST_ALLOCATION_DELETED: "Coût supprimé",
  COST_ALLOCATION_EDITED: "Coût modifié",
  PROCESS_PARAMETERS_EDITED: "Paramètres d'automatisation modifiés",
  DB_CONNECTION_INITIATED: "Connexion à la base de données initiée",
  ORCHESTRATOR_ADDED: "Orchestrateur ajouté",
  ORCHESTRATOR_MODIFIED: "Informations sur l'orchestrateur modifiées",
  ORCHESTRATOR_ENABLED: "Orchestrateur activé",
  ORCHESTRATOR_DISABLED: "Orchestrateur désactivé",
  ORCHESTRATOR_DELETED: "Orchestrateur supprimé",
  ANALYTICS_PRESET_CREATED: "Préréglage d'analyse créé",
  ANALYTICS_PRESET_DELETED: "Préréglage d'analyse supprimé",
  CUSTOM_KPI_DELETED: "KPI personnalisé supprimé",
  CUSTOM_KPI_CREATED: "KPI personnalisé créé",
  CUSTOM_KPI_MODIFIED: "Informations KPI personnalisées modifiées",
  QUEUE_ASSIGNED_RESOURCE: "Ressource affectée à la file SR",
  QUEUE_UNASSIGNED_RESOURCE: "Ressource retirée de la file SR",
  QUEUE_ASSIGNED_PROCESS: "Automatisation affecté à la file SR",
  QUEUE_UNASSIGNED_PROCESS: "Automatisation retiré de la file SR",
  QUEUE_CREATED: "File SR créée",
  QUEUE_MODIFIED: "Informations sur la file SR modifiées",
  QUEUE_DELETED: "File SR supprimée",
  "A role with the same already exists": "Un rôle avec le même nom existe déjà",
  "Update Role": "Mettre à jour le rôle",
  "Role Name": "Nom du rôle",
  "Apply to this group": "Définir comme rôle par défaut pour les groupes suivants",
  "Select Permissions": "Sélectionner les autorisations",
  "Manage Roles": "Rôles",
  Role: "Rôle",
  "default.group.role": "Rôle par défaut du groupe",
  "group.change.role": "La modification du rôle du groupe par défaut appliquera les autorisations à tous ses utilisateurs qui sont censés hériter des autorisations de ce groupe",
  "Create Role": "Créer un rôle",
  assign: "assigner",
  unassign: "désassigner",
  "user.permission.paste.error": "Veuillez vous assurer de copier d'abord des autorisations",
  "user.permission.paste": "Coller les autorisations",
  "user.permission.copy": "Copier les autorisations",
  "copy.role.permissions.template": "Copier le modèle de permissions de rôle",
  "Role Configuration": "Configuration du rôle",
  "user.add.inherit.group.permissions": "Hériter des autorisations du groupe",
  "tooltip.kpi.time.saved": "Indique les économies de temps globales réalisées grâce à l'automatisation, souvent exprimées en heures ou en équivalents temps plein (ETP).",
  "tooltip.kpi.breakEven": "Le moment projeté lorsque les économies réalisées grâce à l'automatisation compenseront son coût initial. C'est un point de bascule financier qui indique quand les investissements en automatisation commenceront à générer des retours nets positifs.",
  "tooltip.kpi.roi": "Ce KPI reflète le pourcentage de gains financiers par rapport au coût d'investissement pour chaque automatisation. Pour le lire, comparez le ratio à 100 % - au-dessus de 100 % signifie que le profit dépasse le coût. Il est calculé en divisant les gains monétaires de l'automatisation par le coût d'investissement total.",
  "tooltip.kpi.costSaved": "Reflet du total d'économis réalisées grâce à l'automatisation en réduisant l'exécution des tâches manuelles et en augmentant l'efficacité.",
  "tooltip.kpi.totalExecutions": "Le nombre total d'Executions (lancements d'automatisation).",
  "tooltip.kpi.itemsCompleted": "Suit le nombre d'Items (tâches d'automatisation) qui ont atteint l'achèvement sans problèmes, reflétant l'efficacité et la fiabilité de l'automatisation.",
  "tooltip.kpi.executionTime": "Cumule le temps total passé sur toutes les Executions (lancements d'automatisation).",
  "tooltip.kpi.itemExecutionTime": "Agrège le temps de traitement total pour tous les Items (tâches d'automatisation).",
  "tooltip.kpi.successRate": "Mesure le pourcentage de tâches complétées sans exceptions par rapport au total tenté. Des taux de succès élevés indiquent une conception d'automatisation robuste et une automatisation efficace.",
  "tooltip.kpi.exceptionRate": "Le ratio de tâches qui ont rencontré des exceptions pendant l'automatisation. Aide à surveiller la santé de l'automatisation et à identifier les modèles pouvant conduire à un raffinement d'automatisation.",
  "tooltip.kpi.occupancyRate": "Montre combien du temps disponible d'une ressource est utilisé pour l'exécution d'automatisation. Un taux élevé suggère une bonne utilisation, tandis qu'un taux faible peut indiquer un sous-emploi et des opportunités pour une meilleure allocation.",
  "tooltip.kpi.resourceProductivity": "La quantité de temps qu'une ressource a activement engagée dans le traitement des automatisations.",
  "tooltip.kpi.avgHandlingTime": "Moyenne le temps de traitement par Item (tâche d'automatisation), offrant un repère pour la performance et l'identification des goulets d'étranglement.",
  "tooltip.kpi.itemExceptions": "Le nombre total d'Items (tâches d'automatisation) qui n'ont pas été complétés comme prévu en raison d'événements inattendus",
  "tooltip.kpi.itemsProcessed": " Indique la quantité totale d'éléments qui ont été initiés ou entrés dans le système pour traitement, indépendamment du résultat. Cette métrique fournit des informations sur le volume de travail géré par le système ou l' automatisation.",
  "reporting.itemExceptions": "Total des exceptions",
  Interfaces: "Interfaces",
  Automations: "Automatisations",
  "Opportunity assessment": "Opportunités",
  "Personal settings": "Paramètres personnels",
  "List of item Data for this process": "Liste des données d'article pour cette automatisation",
  "DASHBOARD Today": "Aujourd'hui",
  "DASHBOARD This Week": "7 derniers jours",
  "DASHBOARD This Month": "30 derniers jours",
  "DASHBOARD This Year": "365 derniers jours",
  "Last session synched": "Dernière session synchronisée",
  "The number of resources linked to this orchestrator": "Le nombre de ressources liées à cet orchestrateur",
  "The number of automations linked to this orchestrator": "Le nombre d'automatisations liées à cet orchestrateur",
  "Number of licences allowed to use on this orchestrator": "Nombre de licences autorisées à utiliser sur cet orchestrateur",
  "The date of the latest session synched by SmartRoby": "La date de la dernière session synchronisée par SmartRoby",
  "The number of users belonging to this group": "Le nombre d'utilisateurs appartenant à ce groupe",
  "The number of automations assigned to this group": "Le nombre d'automatisations attribuées à ce groupe",
  "Resource type": "Type de ressource",
  "Paired automations": "Automatisations appariées",
  "The number of automations that this resource is able to run": "Le nombre d'automatisations que cette ressource est capable d'exécuter",
  "The number of automations already scheduled with this resource": "Le nombre d'automatisations déjà planifiées avec cette ressource",
  "Created since": "Créé depuis",
  "Human-in-the-loop activated": "Activation de l'humain dans la boucle",
  "kpi.parameter.allowScheduling.tooltip": "Permet la planification de l'automatisation en utilisant SmartRoby",
  "kpi.parameter.allowManual.tooltip": "Permet de déclencher manuellement l'automatisation en utilisant SmartRoby",
  "kpi.parameter.parameter.retryable.tooltip": "Permet aux utilisateurs de réessayer les exceptions pour cette automatisation",
  "kpi.parameter.human.in.the.loop.activated.tooltip": "Permet aux utilisateurs de compléter ou de modifier les données transactionnelles avant de réessayer un élément pour cette automatisation",
  "KPI settings": "Paramètres des KPI",
  "SR properties": "Propriétés SR",
  "Assignment Rules": "Règles d'affectation",
  "You choose a process assigned to queue, all processes must be assigned to the same queue": "Vous avez choisi une automatisation attribué à la file d'attente, tous les automatisations doivent être attribués à la même file d'attente",
  "Report a Bug": "Signaler un bug",
  "executions found": "exécutions trouvées",
  "licence.max.automations": "Automatisations maximales",
  "Inherit group automations": "Hériter des automatisations de groupe",
  "master.process": "Processus principal",
  SCHEDULED_ON_COMPLETE: "Sur termination programmée",
  SCHEDULED_ON_EXCEPTION: "Sur exception programmée",
  "File exported successfully": "Fichier exporté avec succès",
  "allocated.priority": "Priorité allouée (SLA)",
  "Edit Resources": "Modifier les ressources",
  QUEUE_ASSIGNED_ROBOT: "Ressource assignée à la file d'attente",
  SCHEDULE_ADDED: "Calendrier ajouté",
  ITEM_EXCEPTION_MARKED_DONE: "Exception d'élément marquée comme terminée",
  ITEM_EXCEPTION_RETRIED: "Exception d'élément réessayée",
  PROCESS_STARTED: "Processus démarré",
  SCHEDULE_MODIFIED: "Calendrier modifié",
  SCHEDULE_DELETED: "Calendrier supprimé",
  EXCEPTION_RULE_MODIFIED: "Règle d'exception modifiée",
  EXCEPTION_RULE_ADDED: "Règle d'exception ajoutée",
  ITEM_EXCEPTION_RELEASED: "Exception d'élément libérée",
  EXCEPTION_RULE_DELETED: "Règle d'exception supprimée",
  QUEUE_UNASSIGNED_ROBOT: "Ressource retirée de la file d'attente",
  ITEM_EXCEPTION_ASSIGNED: "Exception d'élément assignée",
  EXCEPTION_RULE_DISABLED: "Règle d'exception désactivée",
  "Launched with input": "Lancé avec entrée",
  "Items processed": "Articles traités",
  "Items Exceptions can be retried with original": "Les exceptions d'éléments peuvent être réessayées avec l'original",
  "Item Exceptions can be retried with different data": "Les exceptions d'éléments peuvent être réessayées avec des données différentes",
  "Available robots": "Robots disponibles",
  "Robot assignation": "Assignation de robot",
  Time: "Temps",
  "Estimated execution time": "Temps d'exécution estimé",
  "Cost distribution": "Répartition des coûts",
  "enhanced.search.tooltip": "Améliorez votre recherche avec des opérateurs logiques : utilisez 'AND' pour trouver des projets RPA qui répondent à plusieurs critères (par exemple, 'Automation AND Finance'), 'OR' pour découvrir des projets dans l'un des deux domaines (par exemple, 'Finance OR HR'), et 'NOT' pour exclure certains sujets de vos résultats (par exemple, 'Automation NOT Manual').",
  "resources.formSection.orsInfo": "Info ORS",
  "resource.management.formControl.orsResourceVmIp": "IP VM de la ressource",
  "resource.management.formControl.orsScreenHeight": "Hauteur",
  "resource.management.formControl.orsScreenWidth": "Largeur",
  "resource.management.formControl.activateOrs": "Activer la configuration ORS",
  "resource.management.formControl.orsScreenDpi": "DPI",
  "ors.connection.lost": "Connexion non trouvée ou fermée",
  "enable.ors": "Veuillez activer ors pour surveiller votre session",
  "page.of": "Page {{page}} sur {{pages}}",
  "orchestrator.management.formControl.irisUsername": "Nom d'utilisateur Iris",
  "orchestrator.management.formControl.irisPassword": "Mot de passe Iris",
  "orchestrator.management.formControl.irisUrl": "URL Iris",
  "confirm.delete.role": "Êtes-vous sûr de vouloir supprimer ce rôle ?",
    "days.to.license.expiry": "Il vous reste {{daysToExpiry}} jours avant l'expiration de votre licence",
    "license.max.resources.reached": "Vous avez atteint le nombre maximal de ressources autorisées par votre licence, vous ne pourrez pas synchroniser d'autres ressources",
    "license.max.automations.reached": "Vous avez atteint le nombre maximal d'automatisations autorisées par votre licence, vous ne pourrez pas synchroniser d'autres automatisations",
  "Automation dependency": "Dépendance à l'automatisation",
  // resource statuses
  Null: "Nul",
  "Logged Out": "Déconnecté",
  Private: "Privé",
  // resources types
  None: "Aucun",
  Retired: "Retraité",
  Local: "Local",
  Debug: "Déboguer",
  Pool: "Groupe",
  LoginAgent: "Agent de connexion",
  DefaultInstance: "Instance par défaut",
  Desktop: "Bureau",
  RemoteDigitalWorker: "Travailleur numérique distant",
  Open: "Ouvre",
  "Automations Pending": "Automatisations en attente",
  "Automations Pending Configuration": "Automatisations en attente de configuration",
  "App Server": "Serveur d'application",
  "Do you want to configure manually or copy from an existing automation?": "Voulez-vous configurer manuellement ou copier à partir d'une automatisation existante ?",
  "Configure manually": "Configurer manuellement",
  "Copy from an existing automation": "Copier à partir d'une automatisation existante",
  "Assign Automation to Users": "Attribuer l'automatisation aux utilisateurs",
  "Define Automation Control Settings": "Définir les paramètres de contrôle de l'automatisation",
  "Set Data Mapping": "Définir le mappage des données",
  "Configure KPI Settings": "Configurer les paramètres des indicateurs clés",
  "Set SLA Configuration": "Définir la configuration SLA",
  "Configure Financial Parameters": "Configurer les paramètres financiers",
  "Set Linked Documentation": "Définir la documentation liée",
};
