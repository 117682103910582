import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {
  debounce,
} from "@material-ui/core";
import {
  fetchFleetsForFilter,
  fetchProcessesForFilters, fetchTagsForFilters,
} from "redux/actions/services";
import { useLocation, useHistory } from "react-router-dom";
import get from "lodash/get";
import { subDays, isAfter } from "date-fns";
import { format } from "date-fns-tz";
import clsx from "clsx";
import { Duration, EXPORT_LIMIT } from "util/constants";
import {
  formatDatePickerByLanguage, handleDisplayedLabel, selectDurationValue, isFleetAdministrator,
} from "util";

import { SearchFilter, useQuery } from "components/Filter";
import DateFilter from "../../../Services/components/DateFilter";
import { itemsColumns } from "../Utils";
import { languageLSKey } from "util/configs/general";
import ClearFilter from "components/ClearFilter";
import NoDataMenu from "components/NoData/NoDataMenu";

import i18n from "../../../../i18n";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import ExportButton from "../../../../components/ExportButton";
import useStyles from "../style";
import PageHeader from "components/PageHeader";
import ShowMoreFilters from "components/ShowMoreFilters";

const dateFormat = "yyyy/MM/dd HH:mm";
function Filter({
  handleFiltersChange,
  handleChangeDates,
  handleChangeSearchText,
  handleRequestSort,
  rowCount,
  exportLoading,
  fetchData,
  isPendingItemsPage = false,
  workqueueitemFilter,
  handleExport,
  initFilter,
  isFetchLoading,
  handleFilterChangeBatch,
  pageTitle,
  filteredItemsCount,
  totalCount = 0
}) {
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [searchText, setSearchText] = React.useState(null);
  const [processes, setProcesses] = React.useState([]);
  const [fromSelectedDate, setFromDate] = React.useState(
    format(subDays(new Date(), 1), dateFormat),
  );
  const [toSelectedDate, setToDate] = React.useState(
    format(new Date(), dateFormat),
  );
  const [showCustomDate, setshowCustomDate] = React.useState(false);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const { t } = useTranslation();
  const [selectedProcesses, setSelectedProcesses] = React.useState([]);
  const [selectedFleets, setSelectedFleets] = React.useState([]);
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const [userFleets, setUserFleets] = useState();
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [showAll, setShowAll] = useState(false)

  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [futureEndDate, setFutureEndDate] = useState(false)
  const [futureStartDate, setFutureStartDate] = useState(false)
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  React.useEffect(() => {
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);
  useEffect(() => {
    const fdash = query.get("fdash");
    if (fdash) {
      const filters = [
        { process: filterDashboard.process },
        { fleets: filterDashboard.fleets },
        { tags: filterDashboard.tags }
      ]
      handleFilterChangeBatch(filters)
      setSelectedProcesses(filterDashboard.process);
      setSelectedFleets(filterDashboard.fleets);
      setSelectedTags(filterDashboard.tags);
    }
  }, [processes]);
  useEffect(() => {
    getTags();
  }, [selectedFleets])
  useEffect(() => {
   getTags();
  }, [workqueueitemFilter.process]);
  useEffect(() => {
    dispatch(fetchProcessesForFilters(workqueueitemFilter.tags.map(({ id }) => id)))
        .then((res) => {
          setProcesses(res.data)
        });
  }, [workqueueitemFilter.tags]);

  useEffect(() => {
    setSearchText(workqueueitemFilter.searchText);
    setSelectedFleets(workqueueitemFilter.fleet);
  }, []);
  useEffect(() => {
    setSelectedProcesses(workqueueitemFilter.process)
  }, [processes]);
  useEffect(() => {
    setSelectedTags(workqueueitemFilter.tags)
  }, [tags]);

  React.useEffect(() => {
    if (currentUser && isFleetAdministrator(currentUser)) setIsAdmin(true);
  }, [currentUser]);

  React.useEffect(() => {
    if (processes && processes.length) return;
    setTimeout(() => {
      dispatch(fetchProcessesForFilters([], workqueueitemFilter?.fleet)).then((result) => {
        if (result?.data) {
          setProcesses(result.data);
          if (workqueueitemFilter.process.length > 0) {
            setSelectedProcesses(
              result.data
                .filter((p) => Boolean(
                  workqueueitemFilter.process.find((sp) => sp?.id === p?.id),
                ))
            );
          }
          if (!location.state?.selectedProcesses && isFetchLoading) {
            fetchData();
          } else {
            window.history.replaceState({}, {});
          }
        }
      });
    }, 0);
    if (workqueueitemFilter.completedItemsSelectedDurationValue === Duration.CUSTOM) {
      setshowCustomDate(true);
    }
  }, [dispatch]);

  const getTags = () => {
    dispatch(fetchTagsForFilters(workqueueitemFilter?.fleet, workqueueitemFilter.process.map(({ id }) => id))).then((res) => {
      setTags(res.data);
    });
  }

  const handleChangeProcessFilter = (value) => {
    setSelectedProcesses(value);
    handleFiltersChange(value, "process");
  };

  const handleChangeTagsFilter = (value) => {
    setSelectedTags(value);
    handleFiltersChange(value, "tags");
  };
  const handleChangeFleetFilter = (event) => {
    const value = event?.map(({ id }) => id);
    setSelectedFleets(value);
    handleFiltersChange(value, "fleet");
  };

  const onSelectDurationValue = (value) => {
    selectDurationValue(
      value,
      setToDate,
      setshowCustomDate,
      setFromDate,
      handleChangeDates,
      dateFormat,
    );
  };
  const onFromDateChange = (date) => {
    const fromDate = new Date(date);
    const toDate = new Date(toSelectedDate);
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureStartDate(true)
    } else if (fromDate > toDate) {
      setInvalidStartDate(true)
    } else {
      setInvalidStartDate(false)
      setFutureStartDate(false)
      setFromDate(date);
      handleChangeDates(
        date,
        toSelectedDate,
        workqueueitemFilter.completedItemsSelectedDurationValue,
      );
    }
  };
  const onToDateChange = (date) => {
    const currentDate = new Date();
    const toDate = new Date(date);
    const fromDate = new Date(fromSelectedDate);
    if (isAfter(date, currentDate)) {
      setFutureEndDate(true)
    } else if (toDate < fromDate) {
      setInvalidEndDate(true)
    } else {
      setFutureEndDate(false)
      setInvalidEndDate(false)
      setInvalidStartDate(false)
      handleChangeDates(
        fromSelectedDate,
        date,
        workqueueitemFilter.completedItemsSelectedDurationValue,
      );
    }
  };

  const handleExportItems = () => {
    localStorage.setItem(languageLSKey, i18n.language);
    handleExport();
  };

  const handleFilterValueChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncer(value, workqueueitemFilter);
  };

  const debouncer = React.useCallback(
    debounce((nextValue, currFilter) => {
      handleChangeSearchText(nextValue, currFilter);
    }, 500),
    [],
  );

  const clearFilter = () => {
    dispatch(initFilter());
    setInvalidStartDate(false)
    setInvalidEndDate(false)
    setFutureStartDate(false)
    setFutureEndDate(false)
    setSearchText("");
    setshowCustomDate(false);
    setSelectedProcesses([]);
    setSelectedTags([]);
    setSelectedFleets([])
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("processExecution")) {
      queryParams.delete("processExecution");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchFleetsForFilter()).then((res) => {
        setUserFleets(res.data);
      });
    }
  }, [isInstanceOwner])

  return (
    <Grid container>
      <Grid container item justify="space-between" alignItems="center">
        <PageHeader
            title={pageTitle}
            filteredCount={filteredItemsCount}
        />
        {rowCount > 0 && (
        <Grid container item xs={1} justify="flex-end">
          <ExportButton
                  loading={exportLoading}
                  onClick={handleExportItems}
                  withWarning={totalCount > EXPORT_LIMIT}
                  warningMessage="export.limit"
              />
        </Grid>
        )}
      </Grid>
      <Grid container item alignItems="flex-start" spacing={2}>
        <Grid item xs={2} className={classes.topPadding}>
          <SearchFilter
            callback={handleFilterValueChange}
            value={searchText}
            queryParams={workqueueitemFilter.processExecutions}
        />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
            multiple
            options={tags || []}
            optionLabel="name"
            value={tags?.filter(({ id }) => selectedTags?.map(({ id }) => id)?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
            onChange={handleChangeTagsFilter}
            label={t("Tags")}
          />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
            multiple
            options={processes || []}
            optionLabel="processName"
            value={processes?.filter(({ id }) => selectedProcesses?.map(({ id }) => id)?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
            onChange={handleChangeProcessFilter}
            label={t("processes")}
          />
          </FormControl>
        </Grid>
        {isInstanceOwner && (
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
              multiple
              options={userFleets || []}
              optionLabel="companyName"
              value={userFleets?.filter(({ id }) => selectedFleets?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
              onChange={handleChangeFleetFilter}
              label={t("groups")}
            />
          </FormControl>
        </Grid>
        )}
        <Grid item xs={2}>
          <CustomSelectField
            options={isPendingItemsPage ? itemsColumns.slice(0, 4) : itemsColumns.slice(2)}
            optionLabel="label"
            value={itemsColumns?.find(({ label }) => label === workqueueitemFilter.order?.label)}
            onChange={(e) => handleRequestSort(
              e.target.value
            )}
            variant="standard"
            label={t("Sort By")}
            customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
            isCustom
          />
        </Grid>
        {showAll && (
        <DateFilter
          t={t}
          onFromDateChange={onFromDateChange}
          fromDate={isPendingItemsPage ? workqueueitemFilter.fromDate : workqueueitemFilter.completedItemsFromDate}
          onToDateChange={onToDateChange}
          toDate={isPendingItemsPage ? workqueueitemFilter.toDate : workqueueitemFilter.completedItemsToDate}
          dateFormat={formatDatePickerByLanguage()}
          classes={classes}
          selectedDuration={workqueueitemFilter.completedItemsSelectedDurationValue}
          onSelectDurationValue={onSelectDurationValue}
          showCustomDate={showCustomDate}
          type="completedItems"
          isAdmin={isAdmin}
          isCustom
          invalidEndDate={invalidEndDate}
          invalidStartDate={invalidStartDate}
          futureEndDate={futureEndDate}
          futureStartDate={futureStartDate}
          xs={2}
        />)}
        <Grid item container xs={2} justify="flex-end" className={clsx(classes.autoMarginLeft, classes.topPadding)}>
          <Grid item>
            <ShowMoreFilters handleShowMore={handleShowMore} showAll={showAll} />
          </Grid>
          <Grid item>
            <ClearFilter
              clearFilter={clearFilter}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Filter;
