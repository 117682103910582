import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import FormControl from "@material-ui/core/FormControl";
import {
  ChevronRightOutlined as ShowAllIcon,
  Add,
} from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetProcessesState,
} from "../../../redux/slices/processesSlice";
import TableRPA from "../../../components/TableRPA";
import ConfirmMessage from "../../../components/ConfirmMessage";
import { initFilter, updateFilter } from "../../../redux/slices/rpaQualSlice";
import {
  setIncludeArchive,
  setOpportunitiesStatus,
} from "../../../WrapperContainer/appSlice";
import { HasPermission } from "../../../components/HasPermission";
import {
  fetchFleetsForFilter,
  fetchAvailableRpaQualStatus,
} from "../../../redux/actions/services";
import { resetQualification } from "../../../redux/slices/stepperSlice";
import ClearFilter from "../../../components/ClearFilter";
import { toast } from "react-toastify";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER, FETCH_OPPORTUNITIES } from "redux/constants";
import CircularLoader from "components/Loaders/CircularLoader"; import {
  archiveProcess, deleteProcess, loadOpportunities, qualifyProcess
} from "redux/actions/services/opportunities";
import NoDataMenu from "../../../components/NoData/NoDataMenu";
import { isFleetAdministrator } from "util";
import { OPPORTUNITY_ASSESSMENT_BASE_URL } from "util/helpers/route.helper";
import CustomAutoComplete from "../../../components/FormFields/CustomAutoComplete";
import VisibilityIconFilter from "components/VisibilityIconFilter";
import CustomButton from "../../../components/CustomButton";
import PageHeader from "../../../components/PageHeader";
import useStyle from "./style";

const ProcessAssessments = () => {
  const classes = useStyle();

  const dispatch = useDispatch();

  const theme = useTheme();
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [processWithStatus, setProcessWithStatus] = useState(null);
  const [msgConfirm, setMsgConfirm] = useState("");
  const [buttonConfirm, setButtonConfirm] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const isMobileXs = useMediaQuery(theme.breakpoints.down("xs"));
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const opportunitiesStatus = useSelector(
    ({ appState }) => appState?.rpaQual?.opportunitiesStatus,
  );

  const opportunitiesFleets = useSelector(
    ({ rpaQual }) => rpaQual?.fleets,
  );

  const includeArchive = useSelector(
    ({ rpaQual }) => rpaQual?.showArchived,
  );
  const {
    searchKeyword,
  } = useSelector((state) => state.processes);
  const handleClear = () => {
    dispatch(initFilter());
    dispatch(setOpportunitiesStatus([]));
  };
  const rpaFilter = useSelector(({ rpaQual: i }) => i);
  const role = "admin";

  const history = useHistory();
  const { t } = useTranslation();

  const [showContent, setShowContent] = useState(false);

  const [showAllRpa, setShowAllRpa] = useState(false);

  const [status, setStatus] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [showWithAchived, setShowWithAchived] = useState(false);
  const [userFleets, setUserFleets] = useState();
  const { data: opportunities = [], loading } = useQuery({ type: FETCH_OPPORTUNITIES });

  const joinStatus = React.useCallback(
    (st) => st.map((val) => status[val]).join(),
    [status],
  );

  useEffect(() => {
    setShowWithAchived(includeArchive);
    dispatch(fetchAvailableRpaQualStatus()).then((res) => setStatus(res.data));
  }, []);

  useEffect(() => {
    setShowWithAchived(includeArchive);
  }, [includeArchive]);

  useEffect(() => {
    if (showContent) {
      dispatch(loadOpportunities(
        {
          ...rpaFilter,
          showArchived: includeArchive,
          status: joinStatus(opportunitiesStatus),
          fleets: opportunitiesFleets
        }
      ))
    }
  }, [
    dispatch,
    rpaFilter,
    showContent,
    role,
    searchKeyword,
    includeArchive,
    joinStatus,
    opportunitiesStatus,
    opportunitiesFleets
  ]);

  const refreshOpportunitiesData = () => {
    dispatch(
      loadOpportunities({
        ...rpaFilter,
        showArchived: includeArchive,
        status: joinStatus(opportunitiesStatus), // get status from the store
        fleets: opportunitiesFleets,
      }),
    );
    dispatch(fetchAvailableRpaQualStatus());
  };

  useEffect(() => {
    dispatch(resetProcessesState());
    setShowContent(true);
  }, [dispatch]);

  const onShowAllRpa = () => {
    setShowAllRpa(!showAllRpa);
  };

  const cancelConfirm = () => {
    setProcessWithStatus(null);
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const confirmChange = () => {
    setIsLoading(true);
    if (processWithStatus.status === "deleted") {
      dispatch(deleteProcess(processWithStatus.process)).then((res) => {
        if (res.status === 200) handleSuccess("delete");
        refreshOpportunitiesData();
      });
    } else if (processWithStatus.status === "archived") {
      dispatch(archiveProcess(processWithStatus.process)).then(() => {
        refreshOpportunitiesData();
        handleSuccess("archive");
      });
    } else {
      const processClone = { ...processWithStatus.process };
      processClone.status = processWithStatus.status === "reconsider"
        ? "reconsider"
        : processWithStatus.status;
      handleQualifyProcess(processClone, processClone.status);
    }
  };
  const handleSuccess = (action) => {
    if (processWithStatus.process.status === "accepted") {
      toast.success(t("Opportunity deleted successfully"))
    } else if (processWithStatus.process.status === "archived") {
      toast.success(t("rpaQual.process.archived.success"))
    } else {
      toast.success(action === "delete" ? t("Case deleted successfully") : t("Case archived successfully"))
    }
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };
  const successSubmit = () => {
    switch (processWithStatus.status) {
      case "submitted":
        toast.success(t("Case saved and submitted for approval"))
        break;
      case "accepted":
        toast.success(t("Case accepted as an opportunity"))
        break;
      case "rejected":
        toast.success(t("Case rejected"))
        break;

      default:
        break;
    }
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };
  const handleQualifyProcess = (process, status) => {
    const isView = false;
    const processClone = { ...process };
    // status = accepted | rejected
    processClone.status = status;
    //  processClone.qualifiedBy = { id: 1 };
    dispatch(qualifyProcess(processClone)).then((res) => {
      if (res.status === 200) successSubmit();
      if (isView) {
        history.push("/");
        history.replace(`${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/view/${process.id}`);
      } else {
        refreshOpportunitiesData();
      }
    });

    // .then(
    //   successSubmit()
    // );
  };

  const changeOportunity = (process, status, msg, isDelete, buttonConfirm) => {
    setProcessWithStatus({ process, status });
    setOpenMsgConfirm(true);
    setIsLoading(false);
    setMsgConfirm(msg);
    setButtonConfirm(buttonConfirm);
    setIsDelete(isDelete);
  };
  const changeProcess = (process, status) => {
    let msgConfirm = "";
    let isDelete = false;
    let buttonConfirm = "";
    switch (status) {
      case "submitted":
        isDelete = false;
        buttonConfirm = t("assessment.submit");
        msgConfirm = t("Are you sure you want to save and submit this case ?");
        break;
      case "deleted":
        isDelete = true;
        buttonConfirm = t("Delete");
        if (process.status === "draft" || process.status === "not submitted") {
          msgConfirm = t(
            "This case is not yet submitted. Are you sure you want to delete it ?",
          );
        } else if (process.status === "submitted") {
          msgConfirm = t(
            "This case is submitted and awaiting approval. Are you sure you want to delete it ?",
          );
        } else if (process.status === "accepted") {
          msgConfirm = t(
            "This case is an accepted opportunity for automation. Are you sure you want to delete it ?",
          );
        } else {
          msgConfirm = t("Are you sure you want to delete this case ?");
        }
        break;
      case "accepted":
        buttonConfirm = t("Accept");
        isDelete = false;
        msgConfirm = t("rpaQual.transition.accepted");
        break;
      case "rejected":
        buttonConfirm = t("Reject");
        isDelete = false;
        msgConfirm = t("Are you sure you want to reject this case ?");
        break;
      case "archived":
        buttonConfirm = t("rpaQual.transition.archive");
        isDelete = false;
        msgConfirm = t("rpaQual.transition.archiveMsg");
        break;
      case "roadMap":
        buttonConfirm = t("rpaQual.transition.addToRoadMap");
        isDelete = false;
        msgConfirm = t("rpaQual.transition.addToRoadMapMsg");
        break;
      case "reconsider":
        buttonConfirm = t("rpaQual.transition.reconsider");
        isDelete = false;
        msgConfirm = t("rpaQual.transition.reconsiderMsg");
        break;
      default:
        break;
    }
    changeOportunity(process, status, msgConfirm, isDelete, buttonConfirm);
  };

  const handleChangeArchiveOption = () => {
    dispatch(
      updateFilter({ ...rpaFilter, showArchived: !showWithAchived }),
    );
    dispatch(setIncludeArchive());
  };

  const handleChangeFleets = (value) => {
    dispatch(updateFilter({ ...rpaFilter, fleets: value.map(({ id }) => id) }));
  };

  const handleChangeStatus = (event) => {
    dispatch(setOpportunitiesStatus(event));
    const status = joinStatus(event);
    dispatch(updateFilter({ ...rpaFilter, status }));
  };

  useEffect(() => {
    if (currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser)) {
      dispatch(fetchFleetsForFilter()).then((res) => {
        setUserFleets(res.data);
      });
    }
  }, [])

  return (
    <Grid container direction="column" spacing={1} className={classes.mainContainer}>
      <PageHeader title={t("Opportunity assessment")} />
      <Grid item container alignItems="center" spacing={2}>
        {currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser) && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <CustomAutoComplete
                  multiple
                  options={userFleets || []}
                  optionLabel="companyName"
                  value={userFleets?.filter(({ id }) => opportunitiesFleets.includes(id))}
                  noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                  onChange={handleChangeFleets}
                  label={t("groups")}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
                multiple
                options={status ? Object.keys(status) : []}
                optionUuid="code"
                optionLabel={(option) => t(`rpaQual.status.${status[option]}`)}
                value={opportunitiesStatus}
                onChange={handleChangeStatus}
                label={t("Status")}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={3} className={classes.showArchivedSwitch}>
          <Grid item>
            <VisibilityIconFilter
                showAll={showWithAchived}
                handleShowAll={handleChangeArchiveOption}
                showLabel={t("rpaQual.filter.show")}
                hideLabel={t("rpaQual.filter.hide")}
            />
          </Grid>
          <Grid item>
            <ClearFilter
                clearFilter={handleClear}
            />
          </Grid>
        </Grid>
        <Box component={Grid} item xs container justify="flex-end" pt={2}>
          <Grid item>
            <HasPermission name="Add opportunity">
              <CustomButton
              view="primary"
            style={{ marginLeft: "10px" }}
            fullWidth={isMobileXs}
            className={clsx(
              !isMobileXs && classes.marginRight,
              isMobileXs && classes.marginBottom,
            )}
            startIcon={<Add />}
            onClick={() => {
              dispatch(resetQualification());
              history.push(`${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/create`);
            }}
          >
                {t("create.opportunity")}
              </CustomButton>
            </HasPermission>
          </Grid>
          <Grid item>
            <CustomButton
          className={clsx(isMobileXs && classes.marginBottom)}
          fullWidth={isMobileXs}
          onClick={onShowAllRpa}
          color="primary"
          variant="outlined"
          endIcon={showAllRpa ? <ChevronLeftIcon /> : <ShowAllIcon />}
        >
              {showAllRpa ? t("Show less") : t("Show all")}
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        { !loading && opportunities ? <TableRPA
          changeProcess={changeProcess}
          processes={showAllRpa ? opportunities : opportunities.slice(0, 9)}
          adminTable
          brief
        /> : <CircularLoader />}
      </Grid>
      <ConfirmMessage
        message={msgConfirm}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmChange}
        buttonCancel={t("Cancel")}
        buttonConfirm={buttonConfirm}
        isDelete={isDelete}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default ProcessAssessments;
