import React, {
 useState, useEffect, useMemo, useRef
} from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns-tz";
import DataNotFound from "components/DataNotFound";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchExceptions,
  fetchExceptionsReason,
  fetchExceptionsType,
  deleteException,
  deleteExceptions,
  showExceptions,
  exportExceptionXLSX,
  fetchExceptionByAssigner,
  fetchExportedFiles,
  fetchExceptionsCount,
  fetchTagsForFilters,
  fetchProcessesForFilters,
  fetchItemExceptionsAnalyticsKpiByProcess,
  fetchItemExceptionsAnalyticsKpiByReason,
  fetchItemExceptionsAnalyticsKpiByType,
  fetchItemExceptionsAnalyticsKpiByTrend,
  assignException,
  fetchExceptionWorkflowHistory,
  markExceptionAsDone,
  releaseException,
  takeException,
  reopenException,
  assignNotify,
  bulkAssignException,
  bulkMarkExceptionAsDone,
  bulkReleaseException,
  bulkTakeException,
  bulkReopenException,
} from "../../../redux/actions/services";
import ConfirmMessage from "../../../components/ConfirmMessage";
import Filter from "../Filter";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import Details from "../../../components/DataDetail";
import ExceptionsContent from "../ExceptionsContent";
import Analytics from "../Analytics";
import { useQuery } from "../../../components/Filter";
import RetryDialog from "./RetryDialog";
import { updateFilter } from "../../../redux/slices/exceptionFilter";
import WorkflowDrawer from "./Workflow/WorkflowDrawer";
import WorkflowBulkDrawer from "./Workflow/WorkflowBulkDrawer";
import {
 exceptionCurrentModule, selectDurationValue, itemsPathLabel
} from "util";
import { MAX_GRAPH_PROCESSES } from "util/constants";

import ExportConfirmation from "components/Filter/ExportConfirmation/ExportConfirmation";
import { getQuery } from "@redux-requests/core";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import CustomPagination from "pages/Services/components/CustomPagination";
import { FETCH_WORK_QUEUE_ITEM_EXECPTIONS_COUNT } from "../../../redux/constants";
import useStyles from "../style";

const sortColumns = [
  {
    id: "executionStartTime",
    label: "Creation date (Descending)",
    order: "desc",
  },
  {
    id: "executionStartTime",
    label: "Creation date (Ascending)",
    order: "asc",
  },
  {
    id: "exceptionTime",
    label: "Exception Time (Descending)",
    order: "desc",
  },
  {
    id: "exceptionTime",
    label: "Exception Time (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];

const dateFormat = "yyyy/MM/dd HH:mm";
function ItemExceptions(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const processExecIdsParam = Number(
    history?.location?.state?.processExecutionId
      || query.get("processExecution"),
  );
  const processExecIds = processExecIdsParam ? [processExecIdsParam] : [];
  const [currentUserIsSet, setCurrentUserIsSet] = useState(props.currentUserSet);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [processes, setProcesses] = useState([]);
  const [exceptions, setExceptions] = useState([]);
  const [openMsgDetail, setOpenMsgDetail] = useState(false);
  const [selectedException, setSelectedException] = useState(null);
  const [showHidden, setShowHidden] = useState(
    Boolean(processExecIds.length),
  );
  const [process, setProcess] = useState([]);
  const [deleteButtonContent, setDeleteButtonContent] = useState("");
  const [processExecutionIds, setProcessExecutionIds] = useState([]);
  const [retryDialogOpen, setRetryDialogOpen] = useState(false);
  const [retryDialogItems, setRetryDialogItems] = useState([]);
  const [workflowDrawerOpen, setWorkflowDrawerOpen] = useState(false);
  const [workflowBulkDrawerOpen, setWorkflowBulkDrawerOpen] = useState(false);
  const [workflowDrawerItem, setWorkflowDrawerItem] = useState(null);
  const exceptionWorkFlowStatus = ["READY", "TAKEN", "DONE"]
  const [assigners, setAssigners] = useState([]);
  const [exceptionsType, setExceptionsType] = useState([]);
  const [selectedFleetIds, setSelectedFleetIds] = useState([]);
  const [selectedProcessIds, setSelectedProcessIds] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [loadedDatetime, setLoadedDatetime] = useState(null);
  const [completedDatetime, setCompletedDatetime] = useState(null);
  const [selectedExceptionsType, setSelectedExceptionsType] = useState(
    [],
  );
  const [kpiDataValues, setKpiDataValues] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [originalItemId, setOriginalItemId] = useState("");
  const [exportConfirmationOpen, setExportConfirmationOpen] = useState(false);
  const [separateDetails, setSeparateDetails] = useState(false);
  const containerRef = useRef();
  const [
    selectedExceptionWorkFlowStatusFilter,
    setSelectedExceptionWorkFlowStatusFilter,
  ] = useState([]);
  const [tags, setTags] = useState([]);
  const exceptionFilters = useSelector(
    ({ filterListException }) => filterListException[exceptionCurrentModule.ITEMS],
  );

  const exceptionProcessesAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_PROCESS?.data,
  );
  const exceptionReasonsAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_REASON?.data,
  );
  const exceptionTypeAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TYPE?.data,
  );
  const exceptionTrendsAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TREND?.data,
  );
  const kpiData = useMemo(() => ({
    exceptionProcessesAnalytics,
    exceptionReasonsAnalytics,
    exceptionTypeAnalytics,
    exceptionTrendsAnalytics
  }), [exceptionProcessesAnalytics, exceptionReasonsAnalytics, exceptionTypeAnalytics, exceptionTrendsAnalytics])

  /* eslint-disable object-shorthand */
  const apiDispatch = {
    fetchHistory: fetchExceptionWorkflowHistory,
    assign: assignException,
    markAsDone: markExceptionAsDone,
    release: releaseException,
    take: takeException,
    reopen: reopenException,
    assignNotify: assignNotify,
  }

  const bulkApiDispatch = {
    bulkAssign: bulkAssignException,
    bulkMarkAsDone: bulkMarkExceptionAsDone,
    bulkRelease: bulkReleaseException,
    bulkTake: bulkTakeException,
    bulkReopen: bulkReopenException,
    assignNotify: assignNotify
  }

  useEffect(() => {
    setKpiDataValues(exceptionProcessesAnalytics?.values?.length
        || exceptionReasonsAnalytics?.values?.length
        || exceptionTypeAnalytics?.values?.some((elm) => elm)
        || exceptionTrendsAnalytics?.values?.some((obj) => Object.keys(obj).length));
  }, [kpiData]);

  const filterExceptionList = useSelector(
    ({ filterListException }) => filterListException,
  );
  const processFilter = useSelector((state) => getQuery(state, { type: "FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION" }).data);
  const wqitmCount = useSelector((state) => getQuery(state, { type: FETCH_WORK_QUEUE_ITEM_EXECPTIONS_COUNT }).data);
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);

  useEffect(() => {
    const fdash = query.get("fdash");
    if (fdash) {
      handleChangeDates(filterDashboard.fromDate, filterDashboard.toDate, filterDashboard.selectedDurationValue);
      handleChangeTag(filterDashboard.tags);
      handleChangeFleet(filterDashboard.fleets);
      handleChangeProcess(filterDashboard.process);
      selectDurationValue(
          filterDashboard.selectedDurationValue,
          setCompletedDatetime,
          null,
          setLoadedDatetime,
          handleChangeDates,
          dateFormat,
      );
    }
  }, []);
  const fetchExceptionsType = () => {
    props
      .fetchExceptionsType(
        selectedProcessIds,
        loadedDatetime,
        completedDatetime,
        selectedFleetIds,
        selectedExceptionWorkFlowStatusFilter,
        selectedTagIds,
      )
      .then((res) => {
        setExceptionsType(res.data);
      });
  };
  const fetchProcessesNameException = () => {
    props.fetchProcessesForFilters(exceptionFilters.tags, selectedFleetIds).then((res) => {
      setProcesses(res.data);
    });
  };
  const fetchExceptionsReason = () => {
    props
      .fetchExceptionsReason(
        selectedExceptionsType,
        selectedProcessIds,
        loadedDatetime,
        completedDatetime,
        selectedFleetIds,
        selectedTagIds,
      )
      .then((res) => {
        setExceptions(res.data);
      });
  };
  const fetchTagsFilter = () => {
    props.fetchTagsForFilters(selectedFleetIds, selectedProcessIds).then((res) => {
      setTags(res.data);
    })
  }
  useEffect(() => {
    fetchProcessesNameException(selectedFleetIds);
    fetchProcessesNameException(selectedFleetIds);
    fetchExceptionsType();
    fetchExceptionsReason();
    updateAssigners();
    fetchTagsFilter();
  }, [selectedFleetIds.join()]);

  useEffect(() => {
    handleChangeExceptionType([]);
    handleChangeException([]);
    fetchExceptionsType();
    fetchExceptionsReason();
    fetchTagsFilter();
  }, [selectedProcessIds.join()]);
  useEffect(() => {
    props.fetchProcessesForFilters(exceptionFilters.tags, exceptionFilters.fleet).then((result) => {
      setProcesses(result.data);
    });
  }, [exceptionFilters.tags]);

  useEffect(() => {
    handleChangeException([]);
    fetchExceptionsReason();
  }, [selectedExceptionsType.join()]);

  useEffect(() => {
    handleChangeExceptionType([]);
    handleChangeException([]);
    fetchExceptionsType();
    fetchExceptionsReason();
  }, [selectedTagIds.join()]);

  useEffect(() => {
    fetchExceptionsType();
  }, [selectedExceptionWorkFlowStatusFilter.join()])

  useEffect(() => {
    let itemsId = data?.filter((i) => props.selected.includes(i.id) && !checkedItems.includes(i)) ?? [];
    itemsId = [...itemsId, ...checkedItems].filter((i) => props.selected.includes(i.id))
      .filter((itm, index, self) => index === self.findIndex((t) => t.id === itm.id));
    setCheckedItems(itemsId);
  }, [props.selected.join()]);

  const fetchData = () => {
    const filterExceptionTypes = exceptionFilters.exceptionType?.map(
      (e) => e.desc,
    );
    const peId = processExecIds || exceptionFilters.processExecutions;
    const assignerIds = !currentUserIsSet ? exceptionFilters.usersInCharge.map((a) => a.userId) : new Array(props.currentUser.id);
    getExceptions(
      exceptionFilters.pageNo,
      exceptionFilters.pageSize,
      exceptionFilters.order.id,
      exceptionFilters.order.order,
      exceptionFilters.process,
      exceptionFilters.exception,
      filterExceptionTypes,
      exceptionFilters.fleet,
      exceptionFilters.exceptionsFromDate,
      exceptionFilters.exceptionsToDate,
      showHidden,
      exceptionFilters.searchText,
      peId,
      exceptionFilters.workflowStatus,
      assignerIds,
      originalItemId,
      exceptionFilters.tags
    );
  };

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(filterExceptionList[exceptionCurrentModule.ITEMS]), originalItemId]);

  const handleChangeShowHidden = (event) => {
    setShowHidden(event.target.checked);
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          showHidden: event.target.checked,
        },
      }),
    );
  };
  useEffect(() => {
    props.setSelected([]);
  }, [filterExceptionList])

  const handleChangeException = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            exception: values
          },
        }),
      );
    }
  };

  const handleChangeExceptionWorkflowStatus = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            workflowStatus: values,
          },
        }),
      );
    }
  };
  const handleChangeExceptionAssigners = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            usersInCharge: values,
          },
        }),
      );
    }
  };

  const handleChangeExceptionType = (values) => {
    const exceptionTypesDesc = values?.map((e) => e.desc);
    if (values) {
      setSelectedExceptionsType(exceptionTypesDesc);
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            exceptionType: values,
          },
        }),
      );
    }
  };

  const handleChangeProcess = (values) => {
    if (values) {
      setSelectedProcessIds(values);
      dispatch(
        updateFilter({
          [exceptionCurrentModule.ITEMS]: {
            process: values,
          },
        }),
      );
    }
    if (processExecIds?.length && processExecIds.length !== 0) {
      history.replace({
        pathname: location.pathname,
      });
    }
  };

  const handleChangeFleet = (values) => {
    if (values) {
      setSelectedFleetIds(values);
      dispatch(
          updateFilter({
            [exceptionCurrentModule.ITEMS]: {
              fleet: values,
            },
          }),
      );
    }
    if (processExecIds?.length && processExecIds.length !== 0) {
      history.replace({
        pathname: location.pathname,
      });
    }
  };

  const handleChangeDates = (from, to, value) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setLoadedDatetime(from);
    setCompletedDatetime(to);
    dispatch(
      updateFilter({
        [exceptionCurrentModule.ITEMS]: {
          exceptionsSelectedDurationValue: value,
          exceptionsFromDate: from,
          exceptionsToDate: to,
        },
      }),
    );
  };

  const handleRequestSort = (property) => {
    dispatch(
      updateFilter({
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          order: property,
        },
      }),
    );
  };

  const handleSelectAllClick = (event) => {
    const newSelecteds = data.map((n) => n.id);
    if (event.target.checked) {
      const selectedToAdd = newSelecteds.filter((s) => !props.selected.includes(s));
      props.setSelected((prvSelected) => [...prvSelected, ...selectedToAdd]);
      const exceptionsToShow = data
        .filter((item) => item.isDeleted)
        .map((n) => n.id);
      props.setExceptionsToShow((prvExceptionsToShow) => Array.from(new Set([...prvExceptionsToShow, ...exceptionsToShow])));
      const exceptionsToHide = data
        .filter((item) => !item.isDeleted)
        .map((n) => n.id);
      props.setExceptionsToHide((prvExceptionsToHide) => Array.from(new Set([...prvExceptionsToHide, ...exceptionsToHide])));
      return;
    }
    props.deleteDuplicationSelection(newSelecteds);
  };
  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: newPage,
        },
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: page + 1,
        },
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          [exceptionCurrentModule.ITEMS]: {
            pageNo: page - 1,
          },
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          pageSize: event.target.value,
        },
      }),
    );
  };

  const getProcessId = (process) => {
    if (typeof process === "number") {
      return process;
    }
    return process?.id;
  };

  const getExceptions = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    hidden,
    searchContent,
    processExecutionIds,
    workFlowStatus,
    assignersIds,
    originalItemId,
    tags = []
  ) => {
    if (!isEmpty(exceptionWorkFlowStatus)) {
      props.setIsLoading(true);
      const mappedProcesses = processes
        ? processes.map(getProcessId).join()
        : "";
      props
          .fetchExceptions(
              page,
              size,
              sortField,
              sortOrder,
              mappedProcesses,
              exceptions,
              exceptionsType,
              fleetIds,
              loadedDatetime,
              completedDatetime,
              hidden,
              searchContent,
              processExecutionIds,
              workFlowStatus,
              assignersIds,
              originalItemId,
              tags,
          )
          .then((result) => {
            setData(result?.data?.content);
            props.setIsLoading(false);
          });
      props
          .fetchExceptionsCount(
              mappedProcesses,
              exceptions,
              exceptionsType,
              fleetIds,
              loadedDatetime,
              completedDatetime,
              hidden,
              searchContent,
              processExecutionIds,
              workFlowStatus,
              assignersIds,
              originalItemId,
              tags,
          ).then((result) => setCount(result?.data));
    }
  };

  const onExportConfirm = () => {
    const ids = filterExceptionList[exceptionCurrentModule.ITEMS].process;
    exportItemExceptionXLSX(
      ids,
      filterExceptionList[exceptionCurrentModule.ITEMS]?.exceptionType,
      filterExceptionList[exceptionCurrentModule.ITEMS]?.exception,
      separateDetails
)
  }

  useEffect(() => {
    const typesIds = exceptionFilters.exceptionType.map((t) => t.desc);
    const processIds = exceptionFilters.process.map((p) => p.id);
    const from = exceptionFilters.exceptionsFromDate;
    const to = exceptionFilters.exceptionsToDate;
    props.fetchProcessesForFilters(exceptionFilters.tags, exceptionFilters.fleet).then((result) => {
      setProcesses(result.data);
    });
    props
      .fetchExceptionsType(processIds, from, to, exceptionFilters.fleet, selectedExceptionWorkFlowStatusFilter)
      .then((resultT) => {
        setExceptionsType(resultT.data);
      });
    props
      .fetchExceptionsReason(typesIds, processIds, from, to, exceptionFilters.fleet)
      .then((resultE) => {
        setExceptions(resultE.data);
      });
    props.fetchExceptionByAssigner().then((res) => {
      if (res.data) {
        setAssigners(res.data);
      }
    });
    if (
      !processExecutionIds.length
      && processExecIds
      && processExecIds.length !== 0
    ) {
      setProcessExecutionIds(processExecIds);
      history.replace({
        pathname: location.pathname,
        search: `?processExecution=${processExecIds}`,
      });
    }
  }, []);
  const updateAssigners = () => {
    props.fetchExceptionByAssigner(
        [],
        [],
        null,
        null,
        selectedFleetIds
    ).then((res) => {
      if (res.data) {
        setAssigners(res.data);
      }
    });
  };
  useEffect(() => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          order: exceptionFilters.order,
        },
      }),
    );
  }, [exceptionFilters[exceptionCurrentModule.ITEMS]?.order]);

  const deleteException = (e, row) => {
    setSelectedException(row);
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      row?.isDeleted
        ? t("Are you sure you want to show this exception ?")
        : t("Are you sure you want to delete this exception ?"),
    );
    setDeleteButtonContent(
      row?.isDeleted ? t("tooltip.action.show") : t("tooltip.action.hide"),
    );
  };
  const deleteExceptions = () => {
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      `${t("Are you sure you want to delete")
      } ${
        props.exceptionsToHide.length
      } ${
        t("exceptions")
      } ?`,
    );
    setDeleteButtonContent(t("tooltip.action.hide"));
  };

  const showExceptions = () => {
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      t("Are you sure you want to show X exceptions?", {
        count: props.exceptionsToShow.length,
      }),
    );

    setDeleteButtonContent(t("tooltip.action.show"));
  };

  const cancelConfirm = () => {
    props.setOpenMsgConfirm(false);
    setSelectedException(null);
  };
  const confirmDelete = () => {
    props.setIsLoadingConfirm(true);
    if (selectedException?.id) {
      props.deleteException(selectedException.id).then(() => {
        toast.success(selectedException?.isDeleted ? t("Exception Restored Successfully") : t("Exception Deleted Successfully"))
        props.setOpenMsgConfirm(false);
        props.setIsLoadingConfirm(false);
        props.setSelected([]);
        props.setExceptionsToShow([]);
        props.setExceptionsToHide([]);
        setSelectedException(null);
        props.fetchProcessesForFilters().then((result) => {
          setProcesses(result.data);
          props
            .fetchExceptionsReason([])
            .then((resultE) => {
              setExceptions(resultE.data);
              fetchData();
            });
        });
      });
    } else if (deleteButtonContent === t("tooltip.action.hide")) {
      props.deleteExceptions(props.selected).then(() => {
        toast.success(`${props.exceptionsToHide.length} ${t("exceptions successfully deleted")}`)
        props.setOpenMsgConfirm(false);
        props.setIsLoadingConfirm(false);
        props.setSelected([]);
        props.setExceptionsToShow([]);
        props.setExceptionsToHide([]);
        setSelectedException(null);
        fetchData();
      });
    } else if (deleteButtonContent === t("tooltip.action.show")) {
      props.showExceptions(props.selected).then(() => {
        toast.success(t("X exceptions successfully restored", { count: props.exceptionsToShow.length, }))
        props.setOpenMsgConfirm(false);
        props.setIsLoadingConfirm(false);
        props.setSelected([]);
        props.setExceptionsToShow([]);
        props.setExceptionsToHide([]);
        setSelectedException(null);
        fetchData();
      });
    }
  };

  const handleShowExceptionDetails = (row) => {
    setOpenMsgDetail(true);
    props.setDetail(row?.parsedData);
    props.setLogDetail(row?.logDetail);
    props.setValidate(row?.logDetail && row?.logSummary);
  };

  const onClickRow = (row) => {
    handleShowExceptionDetails(row);
  };
  const closeDialog = () => {
    setOpenMsgDetail(false);
  };

  const exportItemExceptionXLSX = (ids, exceptionTypeName, exceptionName, separateDetails = false) => {
    const exceptionNames = exceptionName.map((e) => e.exceptionReason);
    const exceptionTypes = exceptionTypeName.map((e) => e.desc);
    const peId = processExecIds || exceptionFilters.processExecutions;
    const handlersIds = exceptionFilters?.usersInCharge.map((u) => u.userId);
    const tagsID = exceptionFilters.tags?.map((e) => e?.id ?? e);
    dispatch(
      exportExceptionXLSX(
        ids,
        exceptionTypes,
        exceptionNames,
        exceptionFilters.fleet,
        exceptionFilters.exceptionsFromDate,
        exceptionFilters.exceptionsToDate,
        props.selected,
        showHidden,
        exceptionFilters.searchText,
        peId,
        exceptionFilters.workflowStatus,
        handlersIds,
        exceptionFilters.order.id,
        exceptionFilters.order.order,
        separateDetails,
        tagsID
      ),
    ).then(() => {
      props.setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  };

  const deletePermission = "Delete item exceptions";

  const [analyticsLoading, setAnalyticsLoading] = useState({
    exceptionProcessesAnalyticsLoading: false,
    exceptionReasonsAnalyticsLoading: false,
    exceptionTypeAnalyticsLoading: false,
    exceptionTrendsAnalyticsLoading: false,
  });

  useEffect(() => {
    if (props.showAnalyticsContent) {
      setAnalyticsLoading({
        exceptionProcessesAnalyticsLoading: true,
        exceptionReasonsAnalyticsLoading: true,
        exceptionTypeAnalyticsLoading: true,
        exceptionTrendsAnalyticsLoading: true,
      });

      const requestData = {
        processes: filterExceptionList[exceptionCurrentModule.ITEMS].process,
        exceptions: exceptionFilters.exception.map(({ exceptionReason }) => exceptionReason).join(),
        exceptionsType: exceptionFilters.exceptionType.map((t) => t.desc).join(),
        fleetIds: exceptionFilters.fleet.map((c) => c.id),
        tagsIds: exceptionFilters.tags.join(),
        loadedDatetime,
        completedDatetime,
        processExecutionIds,
        showHidden,
      }
      dispatch(
          fetchItemExceptionsAnalyticsKpiByProcess(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              MAX_GRAPH_PROCESSES,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionProcessesAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionProcessesAnalyticsLoading: false })),
          ),
      );
      dispatch(
          fetchItemExceptionsAnalyticsKpiByReason(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionReasonsAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionReasonsAnalyticsLoading: false })),
          ),
      );
      dispatch(
          fetchItemExceptionsAnalyticsKpiByType(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTypeAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTypeAnalyticsLoading: false })),
          ),
      );
      dispatch(
          fetchItemExceptionsAnalyticsKpiByTrend(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTrendsAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTrendsAnalyticsLoading: false })),
          ),
      );
    }
  }, [props.showAnalyticsContent, filterExceptionList]);

  const handleChangeSearchText = (value) => {
    dispatch(
      updateFilter({
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          searchText: value,
        },
      }),
    );
  };

  const handleSetRetryItems = (items) => {
    setRetryDialogItems(items);
    setRetryDialogOpen(true);
  };

  const handleSetWorkflowItem = (item) => {
    setWorkflowDrawerItem(item);
    setWorkflowDrawerOpen(true);
    props.setDetail(item?.parsedData)
  };

  const resetCurrentUserFilter = () => {
    setCurrentUserIsSet(false);
    window.history.pushState("", "", `/${itemsPathLabel}/exception`);
  };
  const handleChangeTag = (value) => {
    dispatch(
        updateFilter({
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            tags: value
          },
        }),
    );
    setSelectedTagIds(value);
  }

  const renderContent = () => {
    if (props.isLoading || !data) {
      return <CircularLoader />;
    }
    if (data && data.length && !props.isLoading) {
      return (
        <ExceptionsContent
          data={data}
          rowCount={data?.length}
          onSelectAllClick={handleSelectAllClick}
          numSelected={props.selected.length}
          numSelectedRetry={data?.filter((i) => props.selected.includes(i.id) && i?.processDto?.isRetryable)?.length}
          indeterminateCheck={
              data?.length > 0
              && data.some((d) => props.selected.includes(d.id))
          }
          checkedAll={
              data?.length > 0
              && data.every((d) => props.selected.includes(d.id))
          }
          openWorkflowBulkDrawer={() => setWorkflowBulkDrawerOpen(true)}
          setRetryItemsHeader={() => handleSetRetryItems(
              data?.filter((i) => props.selected.includes(i.id) && i?.processDto?.isRetryable),
          )}
          getDate={props.getDate}
          getDateDifference={props.getDateDifference}
          handleShowExceptionDetails={handleShowExceptionDetails}
          deleteException={deleteException}
          isSelected={props.isSelected}
          selected={props.selected}
          setSelected={props.setSelected}
          handleClick={props.handleClick}
          onClickRow={onClickRow}
          deletePermission={deletePermission}
          isItemList
          endDateLabel={t("Exception date")}
          setRetryItems={handleSetRetryItems}
          setWorkflowItem={handleSetWorkflowItem}
          setOriginalItemId={setOriginalItemId}
        />
      );
    }
    return (
      <DataNotFound
        message={t("no.items.exception.message")}
      />
    );
  };

  const resetSelected = () => {
    props.setSelected([]);
  };

  return (
    <Grid
      container
      ref={containerRef}
    >
      <Filter
        setOriginalItemId={setOriginalItemId}
        selected={props.selected}
        classes={classes}
        processes={processes}
        exceptionsType={exceptionsType}
        exceptions={exceptions}
        handleChangeException={handleChangeException}
        handleChangeTag={handleChangeTag}
        handleChangeExceptionType={handleChangeExceptionType}
        handleChangeProcess={handleChangeProcess}
        handleRequestSort={handleRequestSort}
        handleChangeFleet={handleChangeFleet}
        handleChangeDates={handleChangeDates}
        onDelete={deleteExceptions}
        onShow={showExceptions}
        rowCount={data?.length}
        exceptionTypeName={[]}
        exceptionName={[]}
        exportExceptionXLSX={exportItemExceptionXLSX}
        deletePermission={deletePermission}
        exportLoading={props.exportLoading}
        setExportLoading={props.setExportLoading}
        showAnalyticsContent={props.showAnalyticsContent}
        setShowAnalyticsContent={props.setShowAnalyticsContent}
        executionStartTime={loadedDatetime}
        executionEndTime={completedDatetime}
        exceptionWorkFlowStatus={exceptionWorkFlowStatus}
        handleChangeExceptionWorkflowStatus={
          handleChangeExceptionWorkflowStatus
        }
        sortColumns={sortColumns}
        handleChangeShowHidden={handleChangeShowHidden}
        showHidden={showHidden}
        handleChangeSearchText={handleChangeSearchText}
        setProcess={setProcess}
        process={process}
        exceptionsToShow={props.exceptionsToShow}
        exceptionsToHide={props.exceptionsToHide}
        durations={props.durations}
        isItemList
        currModule={exceptionCurrentModule.ITEMS}
        assigners={assigners}
        handleChangeExceptionAssigners={handleChangeExceptionAssigners}
        currentUserSet={currentUserIsSet}
        resetCurrentUserFilter={resetCurrentUserFilter}
        setExportConfirmationOpen={setExportConfirmationOpen}
        totalCount={wqitmCount}
        selectedExceptionWorkFlowStatusFilter={selectedExceptionWorkFlowStatusFilter}
        setSelectedExceptionWorkFlowStatusFilter={setSelectedExceptionWorkFlowStatusFilter}
        tags={tags}
        pageTitle="exceptions.item.tab.title"
        filteredItemsCount={wqitmCount}
      />

      {props.showAnalyticsContent && kpiDataValues && (
      <Grid item container xs={12}>
        <Analytics
              kpiData={kpiData}
              containerRef={containerRef}
              analyticsLoading={analyticsLoading}
            />
      </Grid>
        )}
      { renderContent() }
      <Grid container xs={12} direction="row" justify="flex-end">
        { data?.length > 0 && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count ?? 0}
          rowsPerPage={exceptionFilters.pageSize}
          page={exceptionFilters.pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />}
      </Grid>
      {openMsgDetail && (
        <Details
          handleClose={closeDialog}
          logDetail={props.logDetail}
          data={props.detail}
          validate={props.validate}
          openStart={openMsgDetail}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      {props.openMsgConfirm && (
        <ConfirmMessage
          message={props.messageConfirm}
          openStart={props.openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          buttonConfirm={deleteButtonContent}
          buttonCancel={t("Cancel")}
          isDelete="true"
          isLoading={props.isLoadingConfirm}
        />
      )}
      <RetryDialog
        open={retryDialogOpen}
        onClose={() => setRetryDialogOpen(false)}
        items={retryDialogItems}
        // The key ensures dialog state is cleared
        key={`retry-items-${retryDialogItems.reduce((k, i) => k + i.id, 0)}`}
        fetchData={fetchData}
        resetSelected={resetSelected}
      />
      <WorkflowDrawer
        open={workflowDrawerOpen}
        onClose={() => setWorkflowDrawerOpen(false)}
        item={workflowDrawerItem}
        setItem={setWorkflowDrawerItem}
        data={data}
        fetchData={fetchData}
        handleShowExceptionDetails={handleShowExceptionDetails}
        updateAssigners={updateAssigners}
        key={`workflow-drawer-${workflowDrawerItem?.id}`}
        setSelected={props.setSelected}
        itemData={props.detail}
        apiDispatch={apiDispatch}
        isAutomation={false}
      />
      <WorkflowBulkDrawer
        open={workflowBulkDrawerOpen}
        onClose={() => setWorkflowBulkDrawerOpen(false)}
        items={checkedItems}
        fetchData={fetchData}
        updateAssigners={updateAssigners}
        // Multiplication by 2 prevents having the same key for RetryDialog, WorkflowDrawer and WorkflowBulkDrawer
        // when only one item is selected
        key={props.selected.reduce((k, i) => 2 * (k + i), 0)}
        resetSelected={resetSelected}
        bulkApiDispatch={bulkApiDispatch}
      />
      <ExportConfirmation
        setExportLoading={props.setExportLoading}
        exportConfirmationOpen={exportConfirmationOpen}
        setExportConfirmationOpen={setExportConfirmationOpen}
        setSeparateDetails={setSeparateDetails}
        onExportConfirm={onExportConfirm}
        selectedProcess={exceptionFilters.process.length}
        fetchedProcess={processFilter?.length} />
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchExceptions,
  fetchProcessesForFilters,
  fetchExceptionsReason,
  fetchExceptionsType,
  deleteException,
  deleteExceptions,
  showExceptions,
  fetchExceptionByAssigner,
  fetchExceptionsCount,
  fetchTagsForFilters
};
export default connect(null, mapDispatchToProps)(ItemExceptions);
